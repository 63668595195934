
import {
    GET_WORKSPACE_SCAN_SUCCESS, POST_WORKSPACE_SCAN_SUCCESS, POST_WORKSPACE_SCAN_REQUEST,
    GET_INTEGRATIONS_SCAN_SUCCESS, GET_INTEGRATIONS_SCAN_FAILURE, CLEAR_WORKSPCAE_DATA,
    GET_WORKSPACE_SCAN_REQUEST, POST_WORKSPACE_SCAN_FAILURE,
    CHECK_INTEGRATIONS_SCAN_REQUEST, CHECK_INTEGRATIONS_SCAN_SUCCESS, CHECK_INTEGRATIONS_SCAN_FAILURE,
    DELETE_PROJECT_RESULT_FAILURE, DELETE_PROJECT_RESULT_SUCCESS, DELETE_PROJECT_RESULT_REQUEST,
    POST_INVITE_USER_REQUEST, POST_INVITE_USER_SUCCESS, POST_INVITE_USER_FAILURE,
    DELETE_WORKSPACE_REQUEST,
    DELETE_WORKSPACE_SUCCESS,
    DELETE_WORKSPACE_FAILURE,
    WORKSPACE_USER_LIST_REQUEST,
    WORKSPACE_USER_LIST_SUCCESS,
    WORKSPACE_USER_LIST_FAILURE,
    PUT_USER_ROLE_REQUEST,
    PUT_USER_ROLE_SUCCESS,
    PUT_USER_ROLE_FAILURE,
    GET_SELECTED_PROJECT_DETAIL_REPORT
} from "./constants";

const initialState = {
    vulnerabilities_count: 0,
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
    project_count: 0,
    list: [],
    scanID: "",
    isAuthenticated: localStorage.getItem('token') ? true : false,
    token: '',
    workspace_name: '',
    project_list: '',
    workspace_error: false,
    workspace_error_message: '',
    exist_workspace_error: false,
    exist_workspace_message: '',
    workspace_name_error: '',
    workspace_list: [],
    invite_user_error: false,
    invite_user_message: '',
    role_change_error_message: '',
    role_change_error: false,
    delete_user_error_message: '',
    delete_user_error: false,
    delete_error:true,
    delete_message: ''
};
export default function getScoreReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SELECTED_PROJECT_DETAIL_REPORT:
            return Object.assign({}, state, {
                selected_project_uid: action.body
            });
        case WORKSPACE_USER_LIST_REQUEST:
            return Object.assign({}, state, {
                workspace_list: []
            });
        case WORKSPACE_USER_LIST_SUCCESS:
            return Object.assign({}, state, {
                workspace_list: action.response.data.list
            });
        case WORKSPACE_USER_LIST_FAILURE:
            return Object.assign({}, state, {
                workspace_list: []
            });
        case GET_WORKSPACE_SCAN_SUCCESS:
            return Object.assign({}, state, {
                list: action.response.data.list
            });
        case GET_WORKSPACE_SCAN_REQUEST:
            return Object.assign({}, state, {
                list: []
            });
        case POST_INVITE_USER_REQUEST:
            return Object.assign({}, state, {
            });
        case POST_INVITE_USER_SUCCESS:
            return Object.assign({}, state, {
                invite_user_error: action.response.data.error,
                invite_user_message: action.response.data.message
            });
        case POST_INVITE_USER_FAILURE:
            return Object.assign({}, state, {
                invite_user_error: action.response.data.error
            });
        case DELETE_WORKSPACE_REQUEST:
            return Object.assign({}, state, {
            });
        case DELETE_WORKSPACE_SUCCESS:
            return Object.assign({}, state, {
                delete_user_error_message: action.response.data.message,
                delete_user_error: action.response.data.error
            });
        case DELETE_WORKSPACE_FAILURE:
            return Object.assign({}, state, {
                delete_user_error_message: action.response.data.message,
                delete_user_error: action.response.data.error
            });
        case PUT_USER_ROLE_REQUEST:
            return Object.assign({}, state, {
                role_change_error_message: '',
            });
        case PUT_USER_ROLE_SUCCESS:
            return Object.assign({}, state, {
                role_change_error_message: action.response.data.message,
                role_change_error: action.response.data.error
            });
        case PUT_USER_ROLE_FAILURE:
            return Object.assign({}, state, {
                role_change_error_message: action.response.data.message,
                role_change_error: action.response.data.error
            });

        case POST_WORKSPACE_SCAN_SUCCESS:
            return Object.assign({}, state, {
                workspace_name: action.response.data.name,
                workspace_error: false,
                workspace_error_message: action.response.data.message
            });
        case POST_WORKSPACE_SCAN_REQUEST:
            return Object.assign({}, state, {
                // workspace_name: action.response.data.name,
                workspace_error: false,
                workspace_error_message: ''
            });
        case POST_WORKSPACE_SCAN_FAILURE:
            return Object.assign({}, state, {
                workspace_name: action.response.data.name,
                workspace_error: action.response.data.error,
                workspace_error_message: action.response.data.message
            });
        case DELETE_PROJECT_RESULT_REQUEST:
            return Object.assign({}, state, {
                // delete_error: action.response.error
            })
        case DELETE_PROJECT_RESULT_SUCCESS:
            return Object.assign({}, state, {
                delete_error: false,
                delete_message: 'Project deleted successfully'
            })
        case DELETE_PROJECT_RESULT_FAILURE:
            return Object.assign({}, state, {
                // delete_error: action.response.error
            })
        case GET_INTEGRATIONS_SCAN_FAILURE:
            return Object.assign({}, state, {
            });
        case GET_INTEGRATIONS_SCAN_SUCCESS:
            return Object.assign({}, state, {
                //workspace_error: action.response.data.error,

                vulnerabilities_count: action.response.data.total_vulnerabilities,
                critical: action.response.data.critical,
                medium: action.response.data.medium,
                high: action.response.data.high,
                low: action.response.data.low,
                project_count: action.response.data.total_projects,
                project_list: action.response.data.list,
            });

        case CLEAR_WORKSPCAE_DATA:
            return Object.assign({}, state, {
                workspace_name: '',
                workspace_error_message: ''
            })
        case CHECK_INTEGRATIONS_SCAN_REQUEST:
            return Object.assign({}, state, {

                exist_workspace_message: ''
            });
        case CHECK_INTEGRATIONS_SCAN_SUCCESS:
            return Object.assign({}, state, {
                exist_workspace_error: action.response.data.error,
                exist_workspace_message: action.response.data.message
            });
        case CHECK_INTEGRATIONS_SCAN_FAILURE:
            return Object.assign({}, state, {
                exist_workspace_error: action.response.data.error,
                exist_workspace_message: action.response.data.message,
                workspace_name_error: ''
            });
        default:
            return state;
    }
}