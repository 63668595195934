import {
    check_GITHUB_SCAN_SUCCESS, check_GITHUB_SCAN_REQUEST, check_GITHUB_SCAN_FAILURE,
    GET_GITHUB_SCAN_SUCCESS,
    GET_GITHUB_SCAN_REQUEST,
    GET_GITHUB_SCAN_FAILURE,
    GIT_CLEAR_STATE
} from './constant';

const initialState = {
    git_repo_error: '',
    repo_count: 0,
    repo_data: [],
    isAuthenticated: localStorage.getItem('token') ? true : false,
    check_error: '',
    github_user: '' 
};

export default function gitScoreReducer(state = initialState, action) {
    switch (action.type) {
        case GET_GITHUB_SCAN_SUCCESS:
            return Object.assign({}, state, {
                git_repo_error: false,
                // repo_count : action.response.repositories_count,
                // repo_data : action.response.repositories_data
            })
        case GET_GITHUB_SCAN_REQUEST:
            return Object.assign({}, state, {
                repo_count: action.response.data.repositories_count,
                repo_data: action.response.data.repositories_data,
                github_user: action.response.data.username
            })
        case GET_GITHUB_SCAN_FAILURE:
            return Object.assign({}, state, {
                git_repo_error: true,
                repo_count: 0,
                repo_data: ""
            });
        case check_GITHUB_SCAN_SUCCESS:
            return Object.assign({}, state, {
               // check_error : action.response.data.error
            });
        case check_GITHUB_SCAN_REQUEST:
            return Object.assign({}, state, {
                check_error : action.response.data.error
            });
        case check_GITHUB_SCAN_FAILURE:
            return Object.assign({}, state, {
                check_error : true
            });
            case GIT_CLEAR_STATE:
                return Object.assign({}, state, {
                    git_repo_error: '',
                })
        default:
            return state;
    }
}
