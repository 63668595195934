import React, { Component } from 'react';
import { Switch, Route,Redirect } from 'react-router-dom';
import Dashboard from './dashboardLayout';
import Integration from './integration';
import SideNav from './sidebarDrawer';
import CheckScore from './checkScore';
import Projects from './projects';
import WorkspaceSetting from './workspaceSetting';
import ProjectsDetails from './project-details';
import NoProject from './noProject';
import 
CheckScoreResult from './checkScoreResult';

export class DashboardIndex extends Component {
    render() {
        return (
            <div>
            <SideNav />
                <Switch>
                <Route exact path="/layout">
                        <Redirect to={'/layout/integration'} />
                    </Route>
                    <Route exact path="/layout/dashboard" component={Dashboard} />
                    <Route exact path="/layout/integration" component={Integration} />
                    <Route exact path="/layout/project" component={Projects} />
                    <Route exact path="/layout/checkScore" component={CheckScore} />
                    <Route exact path="/layout/projectDetails" component={ProjectsDetails} />
                    <Route exact path="/layout/workspace" component={WorkspaceSetting} />
                    <Route exact path="/layout/noProject" component={NoProject} />
                    <Route exact path="/layout/checkScoreResult" component={CheckScoreResult} />
                </Switch>
            </div>
        );
    }
}

export default DashboardIndex;
