import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { websiteURL } from '../../../common/ApiAction/index'
import './dist/style.css';
import '../../checkFreeScore/header/dist/header-dropdown.css';
import './dist/background.css'
import HamBurger from './dist/hamburger.svg';
import cloud_native from './dist/platform-dropdown-one.svg';
import platform_drop_down_vulnerability_icon from './dist/platform-dropdown-second.svg';
import platform_drop_down_compliance_icon from './dist/platform-dropdown-third.svg';

import Containersecurity from './dist/container-security-1.svg';
import Kubernetessecurity from './dist/kubernetes.svg';
import Applicationsecurity from './dist/application-security.svg';
import Serverlesssecurity from './dist/serverless-security.svg';
import Codesecurity from './dist/code-security.svg';
import Infrastructure from './dist/iac-security.svg';

import DownArrow from './dist/down_arrow.png';

import { clearUrl } from '../../checkFreeScore/checkFreeScore/actions';

const WEBSITE_URL = websiteURL();
var showDropdownCount = 0;
class Header extends Component {

  openInviteModal() {
    this.props.dispatch(clearUrl());
    document.querySelector('#invite').style.display = 'grid';
  }
  showMenu() {
    const els = document.querySelectorAll('.nav-li');
    els.forEach((el, i) => {
      if (i > 0) {

        if (el.style.display === '' || el.style.display === 'none') {
          el.style.display = 'flex';
          document.querySelector('.header').style.display = "block";
          let element = document.getElementById("headerblock");
          element.classList.add("headershadow");
        } else {
          el.style.display = '';
          document.querySelector('.header').style.display = "";
          let element = document.getElementById("headerblock");
          element.classList.remove("headershadow");
        }
      }
    });
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      var els = document.querySelectorAll('.nav-li');
      if (window.innerWidth < 1023) {
        els.forEach((el, i) => i > 0 && (el.style.display = 'none'));
      } else {
        els.forEach((el) => (el.style.display = 'flex'));
      }
    });

    window.addEventListener('scroll', (event) => {
      this.addRemoveClass();
    })
  }

  // // header dropdown accordian 

  showDropdown(DropdownElement) {
    if (showDropdownCount === 0) {
      let arrowElement = document.querySelectorAll(`#${DropdownElement} a`);
      let arrowImage = arrowElement[0].firstElementChild;
      arrowImage.classList.add('open-dropdown');

      var element = document.getElementById(DropdownElement).children[1];
      element.classList.add("nav-megamenuShow");
      showDropdownCount = 1;
    }
    else {
      let arrowElement = document.querySelectorAll(`#${DropdownElement} a`);
      let arrowImage = arrowElement[0].firstElementChild;
      arrowImage.classList.remove('open-dropdown');

      var element1 = document.getElementById(DropdownElement).children[1];
      element1.classList.remove("nav-megamenuShow");
      showDropdownCount = 0
    }
  }
  showDropdownShadow() {
    let element = document.getElementById("headerblock");
    element.classList.add("headershadow-dropdown");
  }
  hideDropdown() {
    let element = document.getElementById("headerblock");
    element.classList.remove("headershadow-dropdown");
  }

  // // header dropdown accordian 


  // // Header Dropdown and mobile view=======================================================

  // // ===================background color and position fixed for header=======================
  addRemoveClass() {
    let element = document.getElementById("headerblock");
    if (window.pageYOffset >= 10) {
      if (element !== null) {
        element.classList.add("headershadow");
      }
    } else {
      if (element !== null) {
        element.classList.remove("headershadow");
      }
    }
  }

  render() {
    return (
      <header class="auth header-wrapper" id="headerblock">
        <div class="container header-container">
          <div class="mobile-header-block">
            <div class="mobile-header-inner">
              <a href="https://www.metasecure.ai/">
              <img src="https://f.hubspotusercontent-eu1.net/hubfs/24902366/Home%20Page%20Rough%20Design_004_051%20logo%20for%20Darker%20Purple%20Surface.svg" alt="Akirastack Logo" id="colorLogo"  width="200" height="50" />
              </a>
              <div class="mobile-right">
                <div class="mobile-search"></div>
                <div class="menu-icon"> <img src={HamBurger} class="xenonstack-hamburger"
                  alt="Xenonstack Hamburger" onClick={this.showMenu.bind(this)} /> </div>
              </div>
            </div>
          </div>
          <nav class="header">
            <ul class="nav-ul">
              <li class="nav-li logo-li">
                <a href="https://www.metasecure.ai/">
                  <img src="https://f.hubspotusercontent-eu1.net/hubfs/24902366/Home%20Page%20Rough%20Design_004_051%20logo%20for%20Darker%20Purple%20Surface.svg" alt="Akirastack Logo" id="colorLogo"  width="200" height="50" />
                  <img src="https://f.hubspotusercontent-eu1.net/hubfs/24902366/Home%20Page%20Rough%20Design_004_047%20Logo%20for%20ligheter%20Surface.svg" alt="" id="uncolorLogo" width="200" height="50" />
                </a>
                <div class="menu-icon">
                  <i class="fa fa-bars" onClick={this.showMenu.bind(this)}></i>
                </div>
              </li>
              <li class="nav-li platform-li item" onClick={() => this.showDropdown('nav-platform')} id="nav-platform" onMouseEnter={this.showDropdownShadow.bind(this)} onMouseLeave={this.hideDropdown.bind(this)}>
                <a href="#" class="arrow">Platform
                  <img src={DownArrow} alt="" />
                </a>
                <div class="nav-megamenu" id="nav-megamenu">
                  <div class="nav-megamenu-inner">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="nav-megamenu-right">
                          <div class="container">
                            <div class="row grid3">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-12 padd-30">
                                <div class="service-block">
                                  <a href={WEBSITE_URL + '/cloud-native-security/'}>
                                    <img
                                      src={cloud_native} alt="" />
                                    <h4>Cloud Native Security</h4>
                                    <p>Respond to cyber threats, and protect your
                                      Cloud-Native Applications with
                                      zero-trust and defence-in-depth.
                                    </p>
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-sm-4 col-12 padd-30">
                                <div class="service-block">
                                  <a href={WEBSITE_URL + '/vulnerability-management/'}>
                                    <img
                                      src={platform_drop_down_vulnerability_icon} alt="" />
                                    <h4>Vulnerability Management</h4>
                                    <p>Assess your enterprise applications whether they are
                                      deployed in the cloud or
                                      on-premises or vulnerabilities.</p>
                                  </a>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-sm-4 col-12 padd-30">
                                <div class="service-block">
                                  <a href={WEBSITE_URL + '/compliance-management/'}>
                                    <img
                                      src={platform_drop_down_compliance_icon} alt="" />
                                    <h4>Compliance Management</h4>
                                    <p>Continuous compliance by enforcing and auditing
                                      infrastructure and applications
                                      against compliance requirements.</p>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mobile-nav">
                    <a href={WEBSITE_URL + '/cloud-native-security/'}>Cloud Native Security</a>
                    <a href={WEBSITE_URL + '/vulnerability-management/'}>Vulnerability Management</a>
                    <a href={WEBSITE_URL + '/compliance-management/'}>Compliance Management</a>
                  </div>
                </div>
              </li>
              <li class="nav-li item" onClick={() => this.showDropdown('nav-solutions')} onMouseEnter={this.showDropdownShadow.bind(this)} onMouseLeave={this.hideDropdown.bind(this)}
                id="nav-solutions">
                <a href="#" class="arrow">Solutions
                  <img src={DownArrow} />
                </a>
                <div class="nav-megamenu" id="nav-megamenu">
                  <div class="nav-megamenu-inner">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="nav-megamenu-right">
                          <div class="container">
                            <div class="row grid3">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                                <div class="solution-dropdown-textsideImage">
                                  <img src={cloud_native} alt="" />
                                  <h3>Explore Metasecure Solutions</h3>
                                  <p>metasecure.ai can help you stay on top of security, by</p>
                                </div>
                              </div>
                              <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                                <div class="row no-gutters solutions-dropdown-listside-row">
                                  <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                    <a href={WEBSITE_URL + "/container-security"}>
                                      <div class="solutions-dropdown-listside">
                                        <img
                                          src={Containersecurity}
                                          loading="lazy" class="" alt="XenonStack GitOps with Continuous Delivery Image" />
                                        <div class="xenonstack-heading-inner-details-text">
                                          <h4>Container Security</h4>
                                          <p>Get Complete Container Protection with Metasecure</p>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                    <a href={WEBSITE_URL + "/kubernetes-security"}>
                                      <div class="solutions-dropdown-listside">
                                        <img
                                          src={Kubernetessecurity}
                                          loading="lazy" class="" alt="XenonStack GitOps with Continuous Delivery Image" />
                                        <div class="xenonstack-heading-inner-details-text">
                                          <h4>Kubernetes Security</h4>
                                          <p>Secure your clusters with MetaSecure's End-to-End Kubernetes Security</p>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                    <a href={WEBSITE_URL + "/application-security"}>
                                      <div class="solutions-dropdown-listside">
                                        <img
                                          src={Applicationsecurity}
                                          loading="lazy" class="" alt="XenonStack GitOps with Continuous Delivery Image" />
                                        <div class="xenonstack-heading-inner-details-text">
                                          <h4>Application Security</h4>
                                          <p>Secure your applications with Metasecure's security solutions and enforced security procedures</p>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                    <a href={WEBSITE_URL + "/serverless-security"}>
                                      <div class="solutions-dropdown-listside">
                                        <img
                                          src={Serverlesssecurity}
                                          loading="lazy" class="" alt="XenonStack GitOps with Continuous Delivery Image" />


                                        <div class="xenonstack-heading-inner-details-text">
                                          <h4>Serverless Security</h4>
                                          <p>Secure your serverless functions by MetaSecure's runtime protection</p>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                    <a href={WEBSITE_URL + "/coming-soon"}>
                                      <div class="solutions-dropdown-listside">
                                        <img
                                          src={Codesecurity}
                                          loading="lazy" class="" alt="XenonStack GitOps with Continuous Delivery Image" />
                                        <div class="xenonstack-heading-inner-details-text">

                                          <h4>Code Security</h4>
                                          <p>Secure your code at every stage with automatic scanning and remediate vulnerabilities</p>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                    <a href={WEBSITE_URL + "/coming-soon"}>
                                      <div class="solutions-dropdown-listside">
                                        <img
                                          src={Infrastructure}
                                          loading="lazy" class="" alt="XenonStack GitOps with Continuous Delivery Image" />
                                        <div class="xenonstack-heading-inner-details-text">
                                          <h4>IAC Security</h4>
                                          <p>Write secure configurations, indentify misconfigurations and security issues with AkiraStack IAC</p>

                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mobile-nav">
                    <a href={WEBSITE_URL + "/container-security"}>Container Security</a>
                    <a href={WEBSITE_URL + "/kubernetes-security"}>Kubernetes Security</a>
                    <a href={WEBSITE_URL + "/application-security"}>Application Security</a>
                    <a href={WEBSITE_URL + "/serverless-security"}>Serverless Security</a>
                    <a href={WEBSITE_URL + '/coming-soon'}>Code Security</a>
                    <a href={WEBSITE_URL + '/coming-soon'}>IAC Security</a>
                  </div>
                </div>
              </li>
              <li class="nav-li item">
                <a href={WEBSITE_URL + '/coming-soon'}>Use Cases</a>
              </li>
              <li class="nav-li item">
                <a href={WEBSITE_URL + '/coming-soon'}>Managed SOC</a>
              </li>
              {/* <li class="nav-li button-item">
                                <button class="nav-button">
                                    <a href={WEBSITE_URL + '/coming-soon'}
                                    >Login/SignUp</a>

                                </button>
                            </li> */}
              <li class="nav-li button-item">
                <button
                  class="nav-button"
                  type="button"
                  onClick={this.openInviteModal.bind(this)}
                >
                  Get Assessment
                </button>
              </li>

            </ul>
          </nav>
        </div>
      </header>

    );
  }
}
function mapStateToProps(state) {
  const {
    scanData,
  } = state.checkScoreReducer;

  return {
    scanData,
  };
}
export default withRouter(connect(mapStateToProps)(Header));
