import { baseApi } from '../../../common/ApiAction/index';
import { POST_API } from '../../../middleware/symbols';
import {
    POST_SIGNUP_REQUEST, POST_SIGNUP_SUCCESS, POST_SIGNUP_FAILURE, UPDATE_SCAN,REMOVE_SCAN_DATA
} from './constants';

const BASE_API = baseApi();

export function postSignUp(data) {
    return {
        [POST_API]: {
            endpoint: BASE_API + '/v1/signup',
            types: [POST_SIGNUP_REQUEST, POST_SIGNUP_SUCCESS, POST_SIGNUP_FAILURE],
            body: data,
            authenticated: true
        },
    };
}

export function updateScan(data) {
    return {
        type: UPDATE_SCAN,
        data,
    };
}

export function removeScan(){
    return {
        type: REMOVE_SCAN_DATA
    }
}