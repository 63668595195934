import {
    POST_SIGNUP_REQUEST, POST_SIGNUP_SUCCESS, POST_SIGNUP_FAILURE, UPDATE_SCAN, REMOVE_SCAN_DATA
} from './constants';

const initialState = {

    scanData: {
        password: "",
        email: "",
        firstname: "",
        lastname: "",
        confirmpassword: ""
    },
    data: [],
    postSignUpMessage: "",
    postSignUpError: false,
    postSignUpLoading: false,
    isAuthenticated: localStorage.getItem('token') ? true : false,
}

export default function signUpReducer(state = initialState, action) {
    switch (action.type) {
        case POST_SIGNUP_REQUEST:
            return Object.assign({}, state, {
                postSignUpMessage: "",
                postSignUpLoading: true,
            });
        case POST_SIGNUP_SUCCESS:
            return Object.assign({}, state, {
                postSignUpError: false,
                postSignUpLoading: false,
                isAuthenticated: true,
                postSignUpMessage: action.response.data.message,
            });
        case POST_SIGNUP_FAILURE:
            return Object.assign({}, state, {
                postSignUpMessage: action.response.data.message,
                postSignUpError: action.response.data.error,
                postSignUpLoading: false,
                isAuthenticated: false
            });

        case UPDATE_SCAN:
            return Object.assign({}, state, {
                scanData: action.data,
            });
        case REMOVE_SCAN_DATA:
            return Object.assign({}, state, {
                scanData: {
                    password: "",
                    email: "",
                    firstname: "",
                    lastname: "",
                    confirmpassword: ""
                },
                postSignUpMessage: "",
                postSignUpError: false,
                postSignUpLoading: false,
            });
        default:
            return state;
    }
}