import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import '../header/dist/workspace-dropdown.css';
import 'react-notifications/lib/notifications.css';
import {  NotificationManager } from 'react-notifications';
import {
    clearWorkspaceData,
    getScan,
    postScan,
    getIntegration,
    checkIntegration,
    getWorkspaceUserList
} from '../../dashboard/action';
class headerDashboard extends Component {
    constructor(props) {
        super(props);
        this.show1 = this.show1.bind(this)
        this.state = {
            message: '',
            workspace_id: ''

        }
        this.createNotification = this.createNotification.bind(this)

    }



    componentDidMount() {
        document.addEventListener('click', this.handleOutsideClick)
        //needed for further bugs resolutin
        // if(link != '') {
        //    localStorage.setItem('token', link)
        // }
        this.props.dispatch(getScan(localStorage.getItem('token')));
        if (localStorage.getItem('message')) {
            this.setState({ message: localStorage.getItem('message') })
            this.props.dispatch(getIntegration(localStorage.getItem('workspace_id')));
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.exist_workspace_error === true) {
            document.querySelector("#workspace-button").disabled = true;
        }
        if (nextProps.exist_workspace_error === false) {
            document.querySelector("#workspace-button").disabled = false;
        }
        if (nextProps.list.length > 0 && this.state.message === '' && localStorage.getItem('token') != null) {
            this.setState({ message: nextProps.list[0].name });
            if (!localStorage.getItem('workspace_id')) {
                localStorage.setItem('workspace_id', nextProps.list[0].id);
            }

            this.props.dispatch(getIntegration(localStorage.getItem('workspace_id')));
        }


    }
    createNotification = (type) => {
        return () => {
            if (type === 'warning') {
                NotificationManager.warning('Warning message', `${this.props.workspace_error_message}`, 3000);
            }
        };
    };
    async handleFormSubmit(e) {
        e.preventDefault();
        let body = {
            name: this.state.workspace_name
        }
        let frm = document.getElementsByName('form-data')[0];
        this.props.dispatch(clearWorkspaceData(body));
        await this.props.dispatch(postScan(body));

        if (this.props.workspace_error === false) {
            frm.reset();
            document.querySelector(".new-workspace-wrapper").classList.remove('new-workspace-popup-modal-show');
            document.querySelector('.exist_workspace_message').innerText = "";
            document.querySelector('.layout-wrapper').classList.remove('filter-blur');
            NotificationManager.success(`${this.props.workspace_error_message}`);
        }
        else {
            frm.reset();
            NotificationManager.error( `${this.props.workspace_error_message}`);
        }
    }

    handleDataChange(e) {
        this.setState({ workspace_name: e.target.value });
        let body = e.target.value

        this.props.dispatch(checkIntegration(body));
    }
    show1(value) {
        this.setState({ message: value.name });
        localStorage.setItem('workspace_id', value.id)        
        this.props.dispatch(getIntegration(localStorage.getItem('workspace_id')));
        localStorage.setItem('message', value.name)
        if(window.location.toString().includes("workspace")){
            this.props.dispatch(getWorkspaceUserList(localStorage.getItem('workspace_id')))
        }
    }
    logout() {
        localStorage.clear();
        window.location.href = '/auth';
    }
    dropdownActive() {
        this.props.dispatch(getScan())
        let dropdown = document.querySelector(".dropdown");
        dropdown.classList.toggle("active");
        if (document.querySelector(".profile-side-wrapper").classList.contains('profile-avatar-popup-modal-show')) {
            document.querySelector(".profile-side-wrapper").classList.remove('profile-avatar-popup-modal-show');
            document.querySelector('.layout-wrapper').classList.remove('filter-blur');
        }
        if (document.querySelector(".new-workspace-wrapper").classList.contains('new-workspace-popup-modal-show')) {
            document.querySelector(".new-workspace-wrapper").classList.remove('new-workspace-popup-modal-show');
            document.querySelector('.layout-wrapper').classList.remove('filter-blur');
        }
        document.querySelector('.layout-wrapper').classList.toggle('filter-blur');
    }
    workspaceModal() {
        let modal = document.querySelector(".new-workspace-wrapper");
        modal.classList.toggle("new-workspace-popup-modal-show");
        if (this.props.workspace_error === false) {
            document.getElementsByName('form-data')[0].reset();
            document.querySelector('.exist_workspace_message').innerText = "";
        }
        if (document.querySelector(".profile-side-wrapper").classList.contains('profile-avatar-popup-modal-show')) {
            document.querySelector('.exist_workspace_message').innerText = "";
            document.querySelector(".profile-side-wrapper").classList.remove('profile-avatar-popup-modal-show');
            document.querySelector('.layout-wrapper').classList.remove('filter-blur');
        }
        if (document.querySelector(".dropdown").classList.contains('active')) {
            document.querySelector(".dropdown").classList.remove('active');
            document.querySelector('.layout-wrapper').classList.remove('filter-blur');
        }
        document.querySelector('.layout-wrapper').classList.toggle('filter-blur');
    }
    showAvtarModal() {
        let modal = document.querySelector(".profile-side-wrapper");
        modal.classList.toggle("profile-avatar-popup-modal-show");
        if (document.querySelector(".dropdown").classList.contains('active')) {
            document.querySelector(".dropdown").classList.remove('active');
            document.querySelector('.layout-wrapper').classList.remove('filter-blur');
        }
        if (document.querySelector(".new-workspace-wrapper").classList.contains('new-workspace-popup-modal-show')) {
            document.querySelector(".new-workspace-wrapper").classList.remove('new-workspace-popup-modal-show');
            document.querySelector('.layout-wrapper').classList.remove('filter-blur');
        }
        document.querySelector('.layout-wrapper').classList.toggle('filter-blur');
    }


    handleOutsideClick = (event) => {
        if (document.getElementsByClassName('dashboard-header-top')) {
            let dropdown = document.querySelector(".dropdown");
            let profileModal = document.querySelector(".profile-side-wrapper");
            let workspaceModal = document.querySelector(".new-workspace-wrapper");

            let isClickInside = dropdown?.contains(event.target);
            let isClickInsideProfile = profileModal?.contains(event.target);
            let isClickInsideWorkspace = workspaceModal?.contains(event.target);


            if (!isClickInside && document.querySelector(".dropdown")?.classList.contains('active')) {
                dropdown.classList.remove("active");
                document.querySelector('.layout-wrapper').classList.remove('filter-blur');
            }
            if (!isClickInsideProfile && document.querySelector(".profile-side-wrapper")?.classList.contains('profile-avatar-popup-modal-show')) {
                profileModal.classList.remove("profile-avatar-popup-modal-show");
                document.querySelector('.layout-wrapper').classList.remove('filter-blur');
            }
            if (!isClickInsideWorkspace && document.querySelector(".new-workspace-wrapper")?.classList.contains('new-workspace-popup-modal-show')) {
                workspaceModal.classList.remove("new-workspace-popup-modal-show");
                document.querySelector('.layout-wrapper').classList.remove('filter-blur');
            }
        }
    };

  
    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick)
    }

    render() {
 
        const {
            list,
            exist_workspace_message,
        } = this.props;

        return (
            <>
                <div className='dashboard-header-top'>
                    <div className='container extra-margin'>
                        <div className='dashboard-header-wrapper'>
                            <div className='workspace-list-side' >
                                <div className="dropdown" onClick={this.dropdownActive.bind(this)}>

                                    <div className="text-box" id="text-box-id">{this.state.message}</div>

                                    <div className="options">
                                        {list.length > 0 &&
                                            list.map((info, index) => (
                                                <div  key={index} onClick={() =>
                                                    this.show1(info)}>{info.name}</div>))}

                                    </div>
                                </div>
                            </div>
                            <div className='new-workspace-side'>
                                <div className='new-workspace-wrapper'>
                                    <div className='new-workspace-inside' onClick={this.workspaceModal.bind(this)}>
                                        Create Workspace
                                        <img src={require('./dist/plus-icon.svg')} />
                                    </div>
                                    <div className='new-workspace-popup-modal'>
                                        <form name="form-data" onSubmit={this.handleFormSubmit.bind(this)}>
                                            <img src={require('../../auth/dist/noun_password_2730497.svg')} />
                                            <input required type="text" autoComplete='off' value={this.name} placeholder='Enter Project Name' onChange={this.handleDataChange.bind(this)} id="workspace-name-id" />
                                            
                                            <p className='exist_workspace_message'>{exist_workspace_message}</p>
                                               
                                            
                                            <input id="workspace-button"  type="submit" value="Create Workspace" />


                                        </form>

                                    </div>
                                </div>
                                <div className='profile-side-wrapper'>
                                    <div className='profile-avatar-wrapper' onClick={this.showAvtarModal.bind(this)}>
                                        {localStorage.getItem('loginName') ? (<p>{localStorage.getItem('loginName')[0]}</p>) :
                                            (
                                                <p>X</p>
                                            )
                                        }

                                    </div>
                                    <div className='profile-avatar-popup-modal'>
                                        <div className='profile-avatar-modal-inside'>
                                            <div className='profie-avatar-heading-side'>
                                                <div className='profile-avatar-wrapper'>
                                                    {localStorage.getItem('loginName') ? (<p>{localStorage.getItem('loginName')[0]}</p>) :
                                                        (
                                                            <p>X</p>
                                                        )
                                                    }
                                                </div>
                                                <div>
                                                    {localStorage.getItem('loginName') ? (<p>{localStorage.getItem('loginName')}</p>) :
                                                        (
                                                            <p>Please login again</p>
                                                        )
                                                    }
                                                    {localStorage.getItem('loginEmail') ? (<p>{localStorage.getItem('loginEmail')}</p>) :
                                                        (
                                                            <p> </p>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className='profile-avatar-description'>
                                                {/* <p>Invite User</p> */}
                                                <div className='avtar-logout-side' >
                                                    <p onClick={this.logout.bind(this)}>Log Out</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
    const {
        //getscanscore,
        scanId,
        vulnerabilities_count,
        critical,
        high,
        workspace_error_message,
        workspace_error,
        medium,
        low,
        project_count,
        list,
        token,
        scanID,
        exist_workspace_message,
        exist_workspace_error
    } = state.getScoreReducer;
    return {
        //   getscanscore,
        scanId,
        vulnerabilities_count,
        critical,
        token,
        workspace_error,
        workspace_error_message,
        high,
        medium,
        low,
        project_count,
        list,
        scanID,
        exist_workspace_message,
        exist_workspace_error
    };
}

export default withRouter(connect(mapStateToProps)(headerDashboard));
