import { securityApi, workspaceURL } from "../../common/ApiAction";
import { DELETE_API, GET_API, POST_API, PUT_API } from "../../middleware/symbols";
import {
    POST_WORKSPACE_SCAN_FAILURE,
    POST_WORKSPACE_SCAN_REQUEST,
    POST_WORKSPACE_SCAN_SUCCESS,
    GET_WORKSPACE_SCAN_SUCCESS,
    GET_WORKSPACE_SCAN_REQUEST,
    GET_WORKSPACE_SCAN_FAILURE,
    GET_INTEGRATIONS_SCAN_REQUEST,
    GET_INTEGRATIONS_SCAN_SUCCESS,
    GET_INTEGRATIONS_SCAN_FAILURE,
    CLEAR_WORKSPCAE_DATA,
    CHECK_INTEGRATIONS_SCAN_REQUEST,
    CHECK_INTEGRATIONS_SCAN_SUCCESS, 
    CHECK_INTEGRATIONS_SCAN_FAILURE,
    DELETE_PROJECT_RESULT_FAILURE,
    DELETE_PROJECT_RESULT_REQUEST,
    DELETE_PROJECT_RESULT_SUCCESS,
    POST_INVITE_USER_REQUEST,
    POST_INVITE_USER_SUCCESS,
    POST_INVITE_USER_FAILURE,
    DELETE_WORKSPACE_REQUEST,
    DELETE_WORKSPACE_SUCCESS,
    DELETE_WORKSPACE_FAILURE,
    WORKSPACE_USER_LIST_REQUEST,
    WORKSPACE_USER_LIST_SUCCESS,
    WORKSPACE_USER_LIST_FAILURE,
    PUT_USER_ROLE_REQUEST, 
    PUT_USER_ROLE_SUCCESS, 
    PUT_USER_ROLE_FAILURE,
    GET_SELECTED_PROJECT_DETAIL_REPORT

} from './constants';

const SECURITY_API = securityApi();
const WORKSPACE_URL = workspaceURL();

export function postScan(body) {
    return {
        [POST_API]: {
            endpoint: WORKSPACE_URL + '/v1/workspaces',
            types: [POST_WORKSPACE_SCAN_REQUEST, POST_WORKSPACE_SCAN_SUCCESS, POST_WORKSPACE_SCAN_FAILURE],
            authenticated: true,
            body,

        },
    }
}
export function getScan(body) {
    return {
        [GET_API]: {
            endpoint: WORKSPACE_URL + '/v1/workspaces',
            types: [GET_WORKSPACE_SCAN_REQUEST, GET_WORKSPACE_SCAN_SUCCESS, GET_WORKSPACE_SCAN_FAILURE],
            authenticated: true,
            body,
        }
    }
}
export function selectProject(body) {
    return {
            type: GET_SELECTED_PROJECT_DETAIL_REPORT, 
            body,         
    }
}


export function getWorkspaceUserList(id) {
    return {
        [GET_API]: {
            endpoint: WORKSPACE_URL + `/v1/workspaces/${id}/member`,
            types: [WORKSPACE_USER_LIST_REQUEST, WORKSPACE_USER_LIST_SUCCESS, WORKSPACE_USER_LIST_FAILURE],
            authenticated: true,
           
        }
    }
}
export function getUserInvite(body,id) {
    return {
        [POST_API]: {
            endpoint: WORKSPACE_URL + `v1/workspaces/${id}/member` ,
            types: [POST_INVITE_USER_REQUEST, POST_INVITE_USER_SUCCESS, POST_INVITE_USER_FAILURE],
            authenticated: true,
            body
        }
    }
}

export function userRoleManagement(body,id,email) {
    return {
        [PUT_API]: {
            endpoint: WORKSPACE_URL + `/v1/workspaces/${id}/member/${email}` ,
            types: [PUT_USER_ROLE_REQUEST, PUT_USER_ROLE_SUCCESS, PUT_USER_ROLE_FAILURE],
            authenticated: true,
            body,
        }
    }
}


export function getIntegration(body) {
    return {
        [GET_API]: {
            endpoint: SECURITY_API + `/v1/integrations?workspace=${body}`,
            types: [GET_INTEGRATIONS_SCAN_REQUEST, GET_INTEGRATIONS_SCAN_SUCCESS, GET_INTEGRATIONS_SCAN_FAILURE],
            authenticated: true,

        }
    }
}
export function checkIntegration(body) {
    return {
        [GET_API]: {
            endpoint: WORKSPACE_URL + `/v1/workspaces/${body}/check`,
            types: [CHECK_INTEGRATIONS_SCAN_REQUEST, CHECK_INTEGRATIONS_SCAN_SUCCESS, CHECK_INTEGRATIONS_SCAN_FAILURE],
            authenticated: true,
            body
        }
    }
}

export function workspaceDelete(id,email) {
    return {
        [DELETE_API]: {
            endpoint: WORKSPACE_URL + `/v1/workspaces/${id}/member/${email}` ,
            types: [DELETE_WORKSPACE_REQUEST,DELETE_WORKSPACE_SUCCESS,DELETE_WORKSPACE_FAILURE],
            authenticated: true,
        }
    }
}

export function getScanScore(body) {
    return {
        endpoint: WORKSPACE_URL + '/v1/workspaces',
        type: [GET_WORKSPACE_SCAN_SUCCESS, GET_WORKSPACE_SCAN_REQUEST,
            GET_WORKSPACE_SCAN_FAILURE,],
        authenticated: true,
        body,
    }
}


export function clearWorkspaceData() {
    return { type: CLEAR_WORKSPCAE_DATA, }
}
export function deleteProjectList(id,body) {
    return {
        [DELETE_API]: {
            endpoint: SECURITY_API + `/v1/project/${id}?workspace=${body}`,
            types: [
                DELETE_PROJECT_RESULT_REQUEST,DELETE_PROJECT_RESULT_SUCCESS,DELETE_PROJECT_RESULT_FAILURE],
            authenticated: true,
        }
    }
}