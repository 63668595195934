import React, { Component } from "react";
import HeaderDashboard from "../header";
import { withRouter } from 'react-router-dom';
import '../checkScore/dist/style.css'
import '../checkScore/dist/loaderStyle.css'
import { connect } from 'react-redux';

import { DotWaveLoader } from 'react-inline-loaders';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { securityApi, websiteURL } from "../../../common/ApiAction";

let sse;
const WEBSITE_URL = websiteURL();
const SECURITY_API = securityApi();
let nameTarget;
class checkScoreResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            urlLink: '',
            selectedDetailedReport: []
        }
    }
    //Toggle div
    handleImpactClick(e) {
        let parent = e.target.closest('.cards-wrapper');
        let div = parent.querySelector('.toggle_div');
        div.classList.toggle('show');
        parent.querySelector('.down-arrow-image').classList.toggle('up-arrow');

    }
    componentDidMount() {
        if (this.props.getUrl === '') {
            this.props.history.push('/layout')
        }
       
        let test1 = this.props.project_list
    }
    componentWillReceiveProps(nextProps) {
        let test1 = nextProps.project_list
        const selectedProject = nextProps.project_list.filter(projects => projects.request_info.uid == nextProps.selected_project_uid)
        this.setState({selectedDetailedReport: selectedProject })
        if(nextProps.selected_project_uid == "" ){
            nextProps.history.push(`/layout/project`)
        }
    }
    handleScanAccordion(e) {
        let parent = e.target.closest('.scan-details-cards-accordion');
        let div = parent.querySelector('.scan-details-accordion');
        div.classList.toggle('scan-details-cards-accordion-show');
    }
    render() {

        return (
            <>
                <HeaderDashboard />
                <div className="layout-wrapper">
                    {this.props.project_list?.length > 0 &&
                        this.state.selectedDetailedReport?.map((info, index) => (
                            <div className="container extra-margin" key={index}>
                                <div className="cfs-card-wrapper">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                                            <div className="cfs-akirastack-rating-heading">
                                                <div class="mini-card-title">
                                                    <div class="card-heading">
                                                        <p>
                                                            MetaSecure Rating{' '}
                                                            {info.request_info.url !== ''
                                                                ? 'for '
                                                                : ''}{' '}
                                                            {info.request_info.url !== '' ? (
                                                                <div
                                                                    style={{
                                                                        marginTop: '10px',
                                                                    }}
                                                                >
                                                                    <a
                                                                        href={
                                                                            info.request_info.url
                                                                        }
                                                                        target="_blank"
                                                                    >
                                                                        {info.request_info.url}
                                                                    </a>
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div class="emoji">
                                                        <img
                                                            src={
                                                                        (info.scan_result.result.score > 85)
                                                                            ? require('../checkScore/dist/images/smile-emoji.svg')
                                                                            : (info.scan_result.result.score > 55 &&
                                                                                info.scan_result.result.score < 85)
                                                                                ? require('../checkScore/dist/images/normal-emoji.svg')
                                                                                : (info.scan_result.result.score < 55 &&
                                                                                    info.scan_result.result.score > 35)
                                                                                    ? require('../checkScore/dist/images/sad-emoji.svg')
                                                                                    : (info.scan_result.result.score < 35 &&
                                                                                        info.scan_result.result.score > 1)
                                                                                        ? require('../checkScore/dist/images/very-sad-emoji.svg')
                                                                                        : ''
                                                                    }
                                                            alt=""
                                                            class="smile"
                                                        />                                            </div>
                                                    <div class="comment">
                                                        <p
                                                            className={
                                                                info.scan_result.result.score > 85
                                                                    ? 'comment-para-verylow'
                                                                    : info.scan_result.result.score > 55 &&
                                                                        info.scan_result.result.score < 85
                                                                        ? 'comment-para-low'
                                                                        : info.scan_result.result.score > 35 &&
                                                                            info.scan_result.result.score < 55
                                                                            ? 'comment-para-moderate'
                                                                            : info.scan_result.result.score > 1 &&
                                                                                info.scan_result.result.score < 35
                                                                                ? 'comment-para-medium'
                                                                                : info.scan_result.result.score < 1
                                                                                    ? 'comment-para-high'
                                                                                    : 'comment-para-critical'
                                                            }
                                                        >
                                                            {
                                                                    (info.scan_result.result.score > 85) ?

                                                                        'Low Risk'
                                                                        : (info.scan_result.result.score < 85 &&
                                                                            info.scan_result.result.score > 55)
                                                                            ? 'Medium Risk'
                                                                            : (info.scan_result.result.score < 55 &&
                                                                                info.scan_result.result.score > 35)
                                                                                ? 'High Risk'
                                                                                : (info.scan_result.result.score < 35 &&
                                                                                    info.scan_result.result.score > 1)
                                                                                    ? 'Critical'
                                                                                    : ' '
                                                                 }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="grade-inner">
                                                    <div>
                                                        <div class="grade-side">
                                                            <div
                                                                class={ (info.scan_result.result.score > 85)
                                                                            ? 'grade verylow'
                                                                            : (info.scan_result.result.score > 55 &&
                                                                                info.scan_result.result.score < 85)
                                                                                ? 'grade low'
                                                                                : (info.scan_result.result.score > 35 &&
                                                                                    info.scan_result.result.score < 55)
                                                                                    ? 'grade moderate'
                                                                                    : (info.scan_result.result.score > 1 &&
                                                                                        info.scan_result.result.score < 35)
                                                                                        ? 'grade medium'
                                                                                        : (info.scan_result.result.score < 1)
                                                                                            ? 'grade high'
                                                                                            : 'grade critical'
                                                                }
                                                            >
                                                                <span
                                                                    class={
                                                                            (info.scan_result.result.score > 85)
                                                                                ? 'grade-list verylow'
                                                                                : (info.scan_result.result.score > 55 &&
                                                                                    info.scan_result.result.score < 85)
                                                                                    ? 'grade-list low'
                                                                                    : (info.scan_result.result.score > 35 &&
                                                                                        info.scan_result.result.score < 55)
                                                                                        ? 'grade-list moderate'
                                                                                        : (info.scan_result.result.score > 1 &&
                                                                                            info.scan_result.result.score < 35)
                                                                                            ? 'grade-list medium'
                                                                                            : (info.scan_result.result.score < 1)
                                                                                                ? 'grade-list high'
                                                                                                : 'grade-list critical'
                                                                        }
                                                                >
                                                                    {
                                                                            (info.scan_result.result.score > 85)
                                                                                ? 'A'
                                                                                : (info.scan_result.result.score > 55 &&
                                                                                    info.scan_result.result.score < 85)
                                                                                    ? 'B'
                                                                                    : (info.scan_result.result.score > 35 &&
                                                                                        info.scan_result.result.score < 55)
                                                                                        ? 'C'
                                                                                        : (info.scan_result.result.score > 1 &&
                                                                                            info.scan_result.result.score < 35)
                                                                                            ? 'D'
                                                                                            : 'C'}
                                                                </span>
                                                            </div>
                                                            <span
                                                                class={
                                                                        (info.scan_result.result.score > 85)
                                                                            ? 'grade-number verylow'
                                                                            : (info.scan_result.result.score > 55 &&
                                                                                info.scan_result.result.score < 85)
                                                                                ? 'grade-number low'
                                                                                : (info.scan_result.result.score > 35 &&
                                                                                    info.scan_result.result.score < 55)
                                                                                    ? 'grade-number moderate'
                                                                                    : (info.scan_result.result.score > 1 &&
                                                                                        info.scan_result.result.score < 35)
                                                                                        ? 'grade-number medium'
                                                                                        : (info.scan_result.result.score < 1)
                                                                                            ? 'grade-number high'
                                                                                            : 'grade-number critical'
                                                                    }

                                                            >
                                                                {info.scan_result.result.score }
                                                                /100
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                            <div className="scanned-stats-cfs-card-wrapper">
                                                <div className="scanned-cfs-heading">
                                                    <p>Scanned stats</p>
                                                </div><div className="scanned-stats-cfs-list">
                                                    <div>
                                                        <p>High</p>
                                                        <span className="color-high">{ 
                                                                info.high
                                                        }</span>
                                                    </div>
                                                    <div>
                                                        <p>Medium</p>
                                                        <span className="color-medium">{
                                                            info.medium
                                                        }</span>
                                                    </div>
                                                    <div>
                                                        <p>Low</p>
                                                        <span className="color-low">{ info.low
                                                        }</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="scan-deatils-cards-accordion-wrapper">
                                    <div className="scan-deatils-card-heading">
                                        <p>Scan Details</p>
                                    </div>
                                    <div className="scan-details-cards-accordion">
                                        <div className="scan-details-accordion scan-details-cards-accordion-show">
                                            <div className="scan-details-list-wrapper">
                                                <div className="scan-details-list-heading">
                                                    <h2>Website Security</h2>
                                                    <div className="scan-list-impact-icon"
                                                        onClick={this.handleScanAccordion.bind(
                                                            this
                                                        )}
                                                    >
                                                        <p>Impact</p>
                                                        <img src={require('../checkScore/dist/images/down_arrow.png')} width="30" alt="dropdown arrow" />
                                                    </div>
                                                </div>
                                                <div class="scan-deatils-list-description">
                                                    {info.scan_result.result.website_security.length > 0 &&
                                                        info.scan_result.result.website_security.map(
                                                            (info, index) => (
                                                                <div class="cards-inner-item">
                                                                    <div class="cards-inner-left-inner"
                                                                        key={index}
                                                                    >
                                                                        <a href={`${WEBSITE_URL}/vulnerability-details?id=${JSON.parse(info.result).id}`} target="_blank">
                                                                            <h4>
                                                                                {JSON.parse(
                                                                                    info.result
                                                                                ).heading
                                                                                    ? JSON.parse(
                                                                                        info.result
                                                                                    ).heading
                                                                                    : JSON.parse(
                                                                                        info.result
                                                                                    ).header}
                                                                            </h4>
                                                                        </a>
                                                                        <p>
                                                                            {JSON.parse(
                                                                                info.result
                                                                            ).description
                                                                                ? JSON.parse(
                                                                                    info.result
                                                                                ).description
                                                                                : JSON.parse(
                                                                                    info.result
                                                                                ).message}
                                                                        </p>
                                                                    </div>
                                                                    <div class="cards-inner-right-inner"><button
                                                                        class={
                                                                            JSON.parse(
                                                                                info.result
                                                                            ).impact ===
                                                                                'HIGH'
                                                                                ? 'high-button'
                                                                                : JSON.parse(
                                                                                    info.result
                                                                                )
                                                                                    .impact ===
                                                                                    'PASS'
                                                                                    ? 'pass-button'
                                                                                    : JSON.parse(
                                                                                        info.result
                                                                                    )
                                                                                        .impact ===
                                                                                        'MEDIUM'
                                                                                        ? 'medium-button'
                                                                                        : JSON.parse(
                                                                                            info.result
                                                                                        )
                                                                                            .impact ===
                                                                                            'INFORMATIONAL'
                                                                                            ? 'info-button'
                                                                                            : 'low-button'
                                                                        }
                                                                        type="button"
                                                                    >
                                                                        {
                                                                            JSON.parse(
                                                                                info.result
                                                                            ).impact
                                                                        }
                                                                    </button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="scan-details-cards-accordion">
                                        <div className="scan-details-accordion scan-details-cards-accordion-show">
                                            <div className="scan-details-list-wrapper">
                                                <div className="scan-details-list-heading">
                                                    <h2>Email Security</h2>
                                                    <div className="scan-list-impact-icon"
                                                        onClick={this.handleScanAccordion.bind(
                                                            this
                                                        )}
                                                    >
                                                        <p>Impact</p>
                                                        <img src={require('../checkScore/dist/images/down_arrow.png')} width="30" alt="dropdown arrow" />
                                                    </div>
                                                </div>
                                                <div class="scan-deatils-list-description">
                                                    {info.scan_result.result.email_security.length > 0 &&
                                                        info.scan_result.result.email_security.map((info, index) => (
                                                            <div class="cards-inner-item"
                                                                key={index}
                                                            >
                                                                <div class="cards-inner-left-inner">
                                                                    <a href={`${WEBSITE_URL}/vulnerability-details?id=${JSON.parse(info.result).id}`} target="_blank">
                                                                        <h4>
                                                                            {JSON.parse(info.result)
                                                                                .heading
                                                                                ? JSON.parse(
                                                                                    info.result
                                                                                ).heading
                                                                                : JSON.parse(
                                                                                    info.result
                                                                                ).header}
                                                                        </h4>
                                                                    </a>
                                                                    <p>
                                                                        {JSON.parse(info.result)
                                                                            .description
                                                                            ? JSON.parse(
                                                                                info.result
                                                                            ).description
                                                                            : JSON.parse(
                                                                                info.result
                                                                            ).message}
                                                                    </p>
                                                                </div>
                                                                <div class="cards-inner-right-inner">  <button
                                                                    class={
                                                                        JSON.parse(
                                                                            info.result
                                                                        ).impact === 'HIGH'
                                                                            ? 'high-button'
                                                                            : JSON.parse(
                                                                                info.result
                                                                            ).impact ===
                                                                                'PASS'
                                                                                ? 'pass-button'
                                                                                : JSON.parse(
                                                                                    info.result
                                                                                ).impact ===
                                                                                    'MEDIUM'
                                                                                    ? 'medium-button'
                                                                                    : JSON.parse(
                                                                                        info.result
                                                                                    ).impact ===
                                                                                        'INFORMATIONAL'
                                                                                        ? 'info-button'
                                                                                        : 'low-button'
                                                                    }
                                                                    type="button"
                                                                >
                                                                    {
                                                                        JSON.parse(
                                                                            info.result
                                                                        ).impact
                                                                    }
                                                                </button></div>
                                                            </div>
                                                        )
                                                        )}

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="scan-details-cards-accordion">
                                        <div className="scan-details-accordion scan-details-cards-accordion-show">
                                            <div className="scan-details-list-wrapper">
                                                <div className="scan-details-list-heading">
                                                    <h2>Network Security</h2>
                                                    <div className="scan-list-impact-icon"
                                                        onClick={this.handleScanAccordion.bind(
                                                            this
                                                        )}
                                                    >
                                                        <p>Impact</p>
                                                        <img src={require('../checkScore/dist/images/down_arrow.png')} width="30" alt="dropdown arrow" />
                                                    </div>
                                                </div>
                                                <div class="scan-deatils-list-description">
                                                    {info.scan_result.result.network_security.length > 0 &&
                                                        info.scan_result.result.network_security.map(
                                                            (info, index) => (
                                                                <div class="cards-inner-item"
                                                                    key={index}
                                                                >
                                                                    <div class="cards-inner-left-inner">
                                                                        <a href={`${WEBSITE_URL}/vulnerability-details?id=${JSON.parse(info.result).id}`} target="_blank">
                                                                            <h4>
                                                                                {JSON.parse(
                                                                                    info.result
                                                                                ).heading
                                                                                    ? JSON.parse(
                                                                                        info.result
                                                                                    ).heading
                                                                                    : JSON.parse(
                                                                                        info.result
                                                                                    ).header}
                                                                            </h4>
                                                                        </a>
                                                                        <p>
                                                                            {JSON.parse(
                                                                                info.result
                                                                            ).description
                                                                                ? JSON.parse(
                                                                                    info.result
                                                                                ).description
                                                                                : JSON.parse(
                                                                                    info.result
                                                                                ).message}
                                                                        </p>
                                                                    </div>
                                                                    <div class="cards-inner-right-inner"><button
                                                                        class={
                                                                            JSON.parse(
                                                                                info.result
                                                                            ).impact ===
                                                                                'HIGH'
                                                                                ? 'high-button'
                                                                                : JSON.parse(
                                                                                    info.result
                                                                                )
                                                                                    .impact ===
                                                                                    'PASS'
                                                                                    ? 'pass-button'
                                                                                    : JSON.parse(
                                                                                        info.result
                                                                                    )
                                                                                        .impact ===
                                                                                        'MEDIUM'
                                                                                        ? 'medium-button'
                                                                                        : JSON.parse(
                                                                                            info.result
                                                                                        )
                                                                                            .impact ===
                                                                                            'INFORMATIONAL'
                                                                                            ? 'info-button'
                                                                                            : 'low-button'
                                                                        }
                                                                        type="button"
                                                                    >
                                                                        {
                                                                            JSON.parse(
                                                                                info.result
                                                                            ).impact
                                                                        }

                                                                    </button></div>
                                                                </div>
                                                            )
                                                        )}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="scan-details-cards-accordion">
                                        <div className="scan-details-accordion scan-details-cards-accordion-show">
                                            <div className="scan-details-list-wrapper">
                                                <div className="scan-details-list-heading">
                                                    <h2>HTTP Security Headers</h2>
                                                    <div className="scan-list-impact-icon"
                                                        onClick={this.handleScanAccordion.bind(
                                                            this
                                                        )}
                                                    >
                                                        <p>Impact</p>
                                                        <img src={require('../checkScore/dist/images/down_arrow.png')} width="30" alt="dropdown arrow" />
                                                    </div>
                                                </div>
                                                <div class="scan-deatils-list-description">
                                                    {info.scan_result.result.http_security.length > 0 &&
                                                        info.scan_result.result.http_security.map((info, index) => (
                                                            <div class="cards-inner-item"
                                                                key={index}
                                                            >
                                                                <div class="cards-inner-left-inner" >
                                                                    <a href={`${WEBSITE_URL}/vulnerability-details?id=${JSON.parse(info.result).id}`} target="_blank">
                                                                        <h4>

                                                                            {JSON.parse(info.result)
                                                                                .heading
                                                                                ? JSON.parse(
                                                                                    info.result
                                                                                ).heading
                                                                                : JSON.parse(
                                                                                    info.result
                                                                                ).header}

                                                                        </h4>
                                                                    </a>
                                                                    <p>
                                                                        {JSON.parse(info.result)
                                                                            .description
                                                                            ? JSON.parse(
                                                                                info.result
                                                                            ).description
                                                                            : JSON.parse(
                                                                                info.result
                                                                            ).message}
                                                                    </p>
                                                                </div>
                                                                <div class="cards-inner-right-inner"> <button
                                                                    class={
                                                                        JSON.parse(
                                                                            info.result
                                                                        ).impact === 'HIGH'
                                                                            ? 'high-button'
                                                                            : JSON.parse(
                                                                                info.result
                                                                            ).impact ===
                                                                                'PASS'
                                                                                ? 'pass-button'
                                                                                : JSON.parse(
                                                                                    info.result
                                                                                ).impact ===
                                                                                    'MEDIUM'
                                                                                    ? 'medium-button'
                                                                                    : JSON.parse(
                                                                                        info.result
                                                                                    ).impact ===
                                                                                        'INFORMATIONAL'
                                                                                        ? 'info-button'
                                                                                        : 'low-button'
                                                                    }
                                                                    type="button"
                                                                >
                                                                    {
                                                                        JSON.parse(
                                                                            info.result
                                                                        ).impact
                                                                    }
                                                                </button></div>
                                                            </div>
                                                        )
                                                        )}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        ))}
                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
    const { project_list, postScanLoading ,selected_project_uid} = state.getScoreReducer;
    return { project_list, postScanLoading,selected_project_uid };
}
export default withRouter(connect(mapStateToProps)(checkScoreResult));