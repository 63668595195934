import { securityApi } from '../../../common/ApiAction/index';
import { POST_API } from '../../../middleware/symbols';
import {
    CLEAR_SITE_MESSAGE,
    CLEAR_SITE_SCAN,
    POST_SITE_SCAN_FAILURE,
    POST_SITE_SCAN_REQUEST,
    POST_SITE_SCAN_SUCCESS,
    UPDATE_SITE_SCAN,
    UPDATE_SITE_SCAN_LOADING,
    CLEAR_SITE_URL,
    GET_SITE_FREE_SCORE_SUCCESS,
    CLEAR_SITE_FINAL_MESSAGE,
    CLEAR_URL_VALIDATION_ERROR
 
} from './constant';

const SECURITY_API = securityApi();

export function postScan(body,workspaceId) {    
    return {

        [POST_API]: {
            endpoint: SECURITY_API + `/v1/scan?workspace=${workspaceId}`,
            types: [POST_SITE_SCAN_REQUEST, POST_SITE_SCAN_SUCCESS, POST_SITE_SCAN_FAILURE],
            body,
            authenticated: true,
        },
    };
} 
export function clearUrlError() {
    return { type: CLEAR_URL_VALIDATION_ERROR }
}
export function clearScan() {
    return { type: CLEAR_SITE_SCAN };
}

export function clearUrl() {
    return { type: CLEAR_SITE_URL };
}

export function clearMessage() {
    return { type: CLEAR_SITE_MESSAGE };
}

export function updateUrl(data) {
    return {
        type: UPDATE_SITE_SCAN,
        data,
    };
}
export function updateLoading(data) {
    return {
        type: UPDATE_SITE_SCAN_LOADING,
        data,
    };
}
export function clearFinalMessage() {
    return {
       type: CLEAR_SITE_FINAL_MESSAGE }
}
export function getFreeScore (data) {
  return {
    type:     
        GET_SITE_FREE_SCORE_SUCCESS,
    data,
  }
}

