import {
    POST_SCAN_REQUEST, POST_SCAN_SUCCESS, POST_SCAN_FAILURE, UPDATE_SCAN, GET_FREE_SCORE_SUCCESS
} from './constants';

const initialState = {

    scanWebsiteData: {
        url: "",
    },
    postScanMessage: "",
    postScanError: false,
    postScanLoading: false,
}

export default function websiteScanReducer(state = initialState, action) {
    switch (action.type) {
        case POST_SCAN_REQUEST:
            return Object.assign({}, state, {
                postScanMessage: "",
                postScanError: false,
                postScanLoading: true,
            });
        case POST_SCAN_SUCCESS:
            return Object.assign({}, state, {
                postScanMessage: 'The information has been submitted. Please wait for the result.',
                postScanError: false,
                postScanLoading: false,
            });
        case POST_SCAN_FAILURE:
            return Object.assign({}, state, {
                postScanMessage: 'Failed to submit the information.Please Recheck.',
                postScanError: true,
                postScanLoading: false,
            });
        //scan url
        case UPDATE_SCAN:
            return Object.assign({}, state, {
                scanWebsiteData: action.data,
            });
          //Main score loading 
          case GET_FREE_SCORE_SUCCESS:
            return Object.assign({}, state, {
              highData: action.data.high,
              lowData: action.data.low,
              mediumData: action.data.medium,
              resultFinalMessage: action.data.message,
              emailSecurityLoader: action.data.email_security_loader,
              httpSecurityLoader: action.data.http_security_loader,
              networkSecurityLoader: action.data.network_security_loader,
              websiteSecurityLoader: action.data.website_security_loader,
              getWebsiteSecurity: action.data.website_security,
              getEmailSecurity: action.data.email_security,
              getHttpSecurity: action.data.http_security,
              getNetworkSecurity: action.data.network_security,
              postScanScore: action.data.score,
              id: action.data.id,
            })
        default:
            return state;

    }
}