import { combineReducers } from 'redux';

import signUpReducer from '../../pages/auth/signUp/reducer';
import otpReducer from '../../pages/auth/otp/reducer';
import loginReducer from '../../pages/auth/loginForm/reducer';
import forgotPassReducer from '../../pages/auth/forgotPassword/reducer';
import resetPassReducer from '../../pages/auth/resetPassword/reducer';
import websiteScanReducer from '../../pages/dashboard/integration/reducer';
import checkScoreReducer from '../../pages/checkFreeScore/checkFreeScore/reducer';
import getScoreReducer from '../../pages/dashboard/reducer';
import gitScoreReducer from '../../pages/dashboard/githubChoose/reducer';
import scoreReducer from '../../pages/dashboard/checkScore/reducer';

// import reducers from the reducers export all, making this as root reducer

export default combineReducers({
     signUpReducer, otpReducer, loginReducer, forgotPassReducer, resetPassReducer, websiteScanReducer,
      checkScoreReducer, getScoreReducer , gitScoreReducer, scoreReducer
});