const local = {
  // app: {
  //   REACT_APP_REFRESH_TOKEN : 116000,
  //  WEBSITE_LINK: "https://www.metasecure.ai", 
  //  HUBSPOT_BASE_URL: "https://api.hsforms.com/submissions/v3/integration/submit",
  //  REACT_APP_SECURITY_URL:"https://metasecure-dev.secops.neuralcompany.team/api/security",
  //  REACT_APP_BASE_URL:"https://metasecure-dev.secops.neuralcompany.team/api/auth",
  //  REACT_APP_WORKSPACE_URL:"https://metasecure-dev.secops.neuralcompany.team/api/workspace"
  // }
  app: {
     REACT_APP_REFRESH_TOKEN : 116000,
    WEBSITE_LINK: "https://www.metasecure.ai",
    HUBSPOT_BASE_URL: "https://api.hsforms.com/submissions/v3/integration/submit",
    REACT_APP_BASE_URL:"https://secure.metasecure.ai/api/auth",
    REACT_APP_SECURITY_URL:"https://secure.metasecure.ai/api/security",
    REACT_APP_WORKSPACE_URL:"https://secure.metasecure.ai/api/workspace"
  },
};


const develop = {
  app: {
    REACT_APP_REFRESH_TOKEN : 116000,
    WEBSITE_LINK: "https://www.metasecure.ai",
    HUBSPOT_BASE_URL: "https://api.hsforms.com/submissions/v3/integration/submit",
    REACT_APP_BASE_URL:"https://metasecure-dev.secops.neuralcompany.team/api/auth",
    REACT_APP_SECURITY_URL:"https://metasecure-dev.secops.neuralcompany.team/api/security",
    REACT_APP_WORKSPACE_URL:"https://metasecure-dev.secops.neuralcompany.team/api/workspace"
  },
};

const uat = {
  app: {
    REACT_APP_REFRESH_TOKEN : 116000,
    WEBSITE_LINK: "https://www.metasecure.ai",
    HUBSPOT_BASE_URL: "https://api.hsforms.com/submissions/v3/integration/submit",
    REACT_APP_BASE_URL:"https://stg-scan-metasecure-ai.qa.metasecure.ai/api/auth",
    REACT_APP_SECURITY_URL:"https://stg-scan-metasecure-ai.qa.metasecure.ai/api/security",
    REACT_APP_WORKSPACE_URL:"https://stg-scan-metasecure-ai.qa.metasecure.ai/api/workspace"
  },
};

const prod = {
  app: {
     REACT_APP_REFRESH_TOKEN : 116000,
    WEBSITE_LINK: "https://www.metasecure.ai",
    HUBSPOT_BASE_URL: "https://api.hsforms.com/submissions/v3/integration/submit",
    REACT_APP_BASE_URL:"https://secure.metasecure.ai/api/auth",
    REACT_APP_SECURITY_URL:"https://secure.metasecure.ai/api/security",
    REACT_APP_WORKSPACE_URL:"https://secure.metasecure.ai/api/workspace"
  },
};

console.log(process.env.REACT_APP_STAGE,    'REACT_APP_STAGE')
const config = process.env.REACT_APP_STAGE === 'production'  ? prod
             : process.env.REACT_APP_STAGE === 'development' ? develop
             : process.env.REACT_APP_STAGE === 'uat' ? uat
             : local;

export default {
  // Add common config values here
  ...config
};
