import React from 'react';
import './dist/sidebar.css';
import MetaSecure from './dist/metasecure-1.svg';
import 'react-notifications/lib/notifications.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import HamBurger from './dist/hamburger.svg';
import dashboard from './dist/dashboard.svg';
import database from './dist/database.svg';
import documents from './dist/documents.svg';
import Integeration from './dist/join.svg';
import setting from './dist/setting.svg';
import { NavLink } from 'react-router-dom';
import '../../checkFreeScore/header';
import {
    clearWorkspaceData,
    getScan,
    postScan,
    getIntegration,
    checkIntegration
} from '../../dashboard/action';
class SideNav extends React.Component {
    constructor(props) {
        super(props);
        this.show1 = this.show1.bind(this)
        this.state = {
            message: '',
            workspace_id: ''

        }
        this.createNotification = this.createNotification.bind(this)

    }
    componentDidMount() {
        this.props.dispatch(getScan(localStorage.getItem('token')));
        if (localStorage.getItem('message')) {
            this.setState({ message: localStorage.getItem('message') })
            this.props.dispatch(getIntegration(localStorage.getItem('workspace_id')));
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.exist_workspace_error === true) {
            document.querySelector("#workspace-button").disabled = true;
        }
        if (nextProps.exist_workspace_error === false) {
            document.querySelector("#workspace-button").disabled = false;
        }
        if (nextProps.list.length > 0 && this.state.message === '') {
            this.setState({ message: nextProps.list[0].name });
            if (!localStorage.getItem('workspace_id')) {
                localStorage.setItem('workspace_id', nextProps.list[0].id);
            }

            this.props.dispatch(getIntegration(localStorage.getItem('workspace_id')));
        }


    }
    createNotification = (type) => {
        return () => {
            if (type === 'warning') {
                NotificationManager.warning('Warning message', `${this.props.workspace_error_message}`, 3000);
            }
        };
    };
    async handleFormSubmit(e) {
        e.preventDefault();
        let body = {
            name: this.state.workspace_name
        }
        let frm = document.getElementsByName('form-data')[0];
        this.props.dispatch(clearWorkspaceData(body));
        await this.props.dispatch(postScan(body));
        if (this.props.workspace_error_message === "Token is expired") {
            localStorage.clear();
            window.location.href = '/auth';
        }


        if (this.props.workspace_error === false) {
            frm.reset();
            document.querySelector(".new-workspace-wrapper").classList.remove('new-workspace-popup-modal-show');

            document.querySelector('.layout-wrapper').classList.remove('filter-blur');
            NotificationManager.success('Successfully added', `${this.props.workspace_error_message}`, 3000);
        }
        else {
            frm.reset();
            NotificationManager.error('Warning message', `${this.props.workspace_error_message}`, 3000);
        }
    }
    handleDataChange(e) {
        this.setState({ workspace_name: e.target.value });
        let body = e.target.value

        this.props.dispatch(checkIntegration(body));
    }
    show1(value) {
        this.setState({ message: value.name });
        localStorage.setItem('workspace_id', value.id)
        this.props.dispatch(getIntegration(localStorage.getItem('workspace_id')));

        localStorage.setItem('message', value.name)
        if (this.props.workspace_error_message === "Token is expired") {
            localStorage.clear();
            window.location.href = '/auth';
        }
    }
    logout() {
        localStorage.clear();
        window.location.href = '/auth';
    }
    showMenu() {
        const els = document.querySelectorAll('.nav-li');
        els.forEach((el, i) => {
            if (i > 0) {
                // onClick={this.openInviteModal.bind(
                //     this
                // )}
                if (el.style.display === '' || el.style.display === 'none') {
                    el.style.display = 'flex';
                    document.querySelector('.header').style.display = 'block';
                    let element = document.getElementById('headerblock');
                    element.classList.add('headershadow');
                } else {
                    el.style.display = 'none';
                    document.querySelector('.header').style.display = 'none';
                    let element = document.getElementById('headerblock');
                    element.classList.remove('headershadow');
                }
            }
        });
    }

    // phn view header drop-down
    openInviteModal() {
        ['header'].forEach((item) => {
            return (document.querySelector(item).style.filter = 'blur(5px)');
        });
        document.getElementById('work').classList.toggle('filter-arrow-show');
    }
    // ===================background color and position fixed for header=======================
    addRemoveClass() {
        if (window.pageYOffset >= 400) {
            let element = document.getElementById("headerblock");
            element.classList.add("headershadow");
        } else {
            let element = document.getElementById("headerblock");
            element.classList.remove("headershadow");
        }
    }
    componentDidMount() {
        let width = window.innerWidth;
        if (width < 1023) {
            window.addEventListener('scroll', (event) => {
                this.addRemoveClass();
            })
        }
    }

    render() {
        function dropdownActive() {
            let dropdown = document.querySelector(".dropdown");
            dropdown.classList.toggle("active");
            if (document.querySelector(".profile-side-wrapper").classList.contains('profile-avatar-popup-modal-show')) {
                document.querySelector(".profile-side-wrapper").classList.remove('profile-avatar-popup-modal-show');
                document.querySelector('.layout-wrapper').classList.remove('filter-blur');
            }
            if (document.querySelector(".new-workspace-wrapper").classList.contains('new-workspace-popup-modal-show')) {
                document.querySelector(".new-workspace-wrapper").classList.remove('new-workspace-popup-modal-show');
                document.querySelector('.layout-wrapper').classList.remove('filter-blur');
            }
            document.querySelector('.layout-wrapper').classList.toggle('filter-blur');
        }

        function workspaceModal() {
            let modal = document.querySelector(".new-workspace-wrapper")
            modal.classList.toggle("new-workspace-popup-modal-show");
            if (document.querySelector(".profile-side-wrapper").classList.contains('profile-avatar-popup-modal-show')) {
                document.querySelector(".profile-side-wrapper").classList.remove('profile-avatar-popup-modal-show');
                document.querySelector('.layout-wrapper').classList.remove('filter-blur');
            }
            if (document.querySelector(".dropdown").classList.contains('active')) {
                document.querySelector(".dropdown").classList.remove('active');
                document.querySelector('.layout-wrapper').classList.remove('filter-blur');
            }
            document.querySelector('.layout-wrapper').classList.toggle('filter-blur');
        }

        const handleNavItemClick = (e) => {
            document
                .querySelector('.navitems.actived')
                .classList.remove('actived');
            document.getElementById(e).classList.add('actived');
        };


        let width = window.innerWidth;
        const {
            list,
            exist_workspace_message,
        } = this.props;
        if (width > 1023) {
            return (
                <section className="Sidebar">
                    <div className="navItemsWrapper">
                        <div className="navLogo">
                            <img src={MetaSecure} alt="MetaSecure Logo" />
                        </div>
                        <NavLink to="/layout/dashboard">
                            <div
                                id="dashboard"
                                className="navitems"
                                onClick={() => handleNavItemClick('dashboard')}
                            >
                                <img src={dashboard} alt="dashboard" />
                                <p>Dashboard</p>
                            </div>
                        </NavLink>
                        <NavLink to="/layout/project">
                            <div
                                id="project"
                                className="navitems"
                                onClick={() => handleNavItemClick('project')}
                            >
                                <img src={documents} alt="Projects" />
                                <p>Project</p>
                            </div>
                        </NavLink>
                        <NavLink to="/layout/integration">
                            <div
                                id="intregation"
                                className="navitems actived"
                                onClick={() =>
                                    handleNavItemClick('intregation')
                                }
                            >
                                <img src={Integeration} alt="Integeration" />
                                <p>Integration</p>
                            </div>
                        </NavLink>
                        <div className="navitems">
                            <img src={database} alt="databse" />
                            <p>Vulnerability DB</p>
                        </div>
                        <NavLink to="/layout/workspace">
                            <div
                                id="workspaceSetting"
                                className="navitems"
                                onClick={() => handleNavItemClick('workspaceSetting')}
                            >
                                <img src={setting} alt="setting" />
                                <p>Workspace Settings</p>
                            </div>
                        </NavLink>
                    </div>
                </section>
            );
        } else {
            return (
                <>
                    <header class="header-wrapper backgroundWhite"
                        id="headerblock">
                        <div class="container header-container">
                            <div class="mobile-header-block">
                                <div class="mobile-header-inner header-blur">
                                    <a href="https://metasecure.ai/">
                                        <img src="https://f.hubspotusercontent-eu1.net/hubfs/24902366/Home%20Page%20Rough%20Design_004_047%20Logo%20for%20ligheter%20Surface.svg" alt="" width="200" height="50" />
                                    </a>
                                    <div class="mobile-right">
                                        <div class="menu-icon">
                                            {' '}
                                            <img
                                                src={HamBurger}
                                                class="xenonstack-hamburger"
                                                alt="Xenonstack Hamburger"
                                                onClick={this.showMenu.bind(
                                                    this
                                                )}
                                            />{' '}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <nav class="header">
                                <ul class="nav-ul ">
                                    <li className='nav-li item header-blur no-border'>
                                        <div className='profie-avatar-heading-side'>
                                            <div className='profile-avatar-wrapper'>
                                                <p>{localStorage.getItem('loginName')[0]}</p>
                                            </div>
                                            <div>
                                                <p className='avatr-username'>{localStorage.getItem('loginName')}</p>
                                                <p className='avatr-email'>{localStorage.getItem('loginEmail')}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="nav-li item  header-blur"
                                        onClick={this.showMenu.bind(this)}>
                                        <NavLink to="/layout/dashboard">
                                            <p>Dashboard</p>
                                            <div
                                                id="dashboard"
                                                className=""
                                                onClick={() =>
                                                    handleNavItemClick(
                                                        'dashboard'
                                                    )
                                                }
                                            ></div>
                                        </NavLink>
                                    </li>
                                    <li class="nav-li item header-blur"
                                        onClick={this.showMenu.bind(this)}>
                                        <NavLink to="/layout/project">
                                            <p>Projects</p>
                                            <div
                                                id="project"
                                                className="navitems"
                                                onClick={() => handleNavItemClick('project')}
                                            >
                                            </div>
                                        </NavLink>
                                    </li>
                                    <li class="nav-li item header-blur"
                                        onClick={this.showMenu.bind(this)}>
                                        <NavLink to="/layout/integration">
                                            <p>Integration</p>
                                            <div
                                                id="intregation"
                                                className=""
                                                onClick={() =>
                                                    handleNavItemClick(
                                                        'intregation'
                                                    )
                                                }
                                            ></div>
                                        </NavLink>
                                    </li>
                                    <li class="nav-li item header-blur">
                                        <a href="#">Vulnerability DB</a>
                                    </li>
                                    <li class="nav-li item header-blur">
                                        <a href="#">Workspace Settings</a>
                                    </li>
                                    <li className='nav-li item header-blur'>
                                        <div class="dropdown" onClick={dropdownActive}>
                                            <div class="text-box" id="text-box-id"> {this.state.message} </div>
                                            <div class="options">
                                                {list.length > 0 &&
                                                    list.map((info, index) => (
                                                        <div onClick={() =>
                                                            this.show1(info)}>{info.name}</div>))}
                                            </div>
                                        </div>
                                    </li>
                                    <li className='nav-li item header-blur'>
                                        <div className='new-workspace-wrapper'>
                                            <div className='new-workspace-inside' onClick={workspaceModal}>
                                                Create Workspace
                                                <img src={require('../header/dist/plus-icon.svg')} />
                                            </div>
                                            <div className='new-workspace-popup-modal'>
                                                <form name="form-data" onSubmit={this.handleFormSubmit.bind(this)}>
                                                    <img src={require('../../auth/dist/noun_password_2730497.svg')} />
                                                    <input type="text" autocomplete='off' value={this.name} placeholder='Enter Project Name' onChange={this.handleDataChange.bind(this)} id="workspace-name-id" />
                                                    <p className='exist_workspace_message'>{exist_workspace_message}</p>
                                                    <input id="workspace-button" type="submit" value="Create Workspace" />
                                                </form>

                                            </div>
                                        </div>
                                    </li>
                                    <li class="nav-li item header-blur">
                                        <a href="#">Invite User</a>
                                    </li>
                                    <li class="nav-li item header-blur color-orange">
                                        <a onClick={this.logout.bind(this)}>Log Out</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </header>

                </>
            );
        }
    }
}
function mapStateToProps(state) {
    const {
        //getscanscore,
        scanId,
        vulnerabilities_count,
        critical,
        high,
        workspace_error_message,
        workspace_error,
        medium,
        low,
        project_count,
        list,
        token,
        scanID,
        exist_workspace_message,
        exist_workspace_error
    } = state.getScoreReducer;
    return {
        //   getscanscore,
        scanId,
        vulnerabilities_count,
        critical,
        token,
        workspace_error,
        workspace_error_message,
        high,
        medium,
        low,
        project_count,
        list,
        scanID,
        exist_workspace_message,
        exist_workspace_error
    };
}
export default withRouter(connect(mapStateToProps)(SideNav));