import React, {Component} from 'react';
import {BrowserRouter, Route, Switch, Redirect,withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {NotificationContainer} from 'react-notifications';
import HomePage from "../pages/checkFreeScore/checkFreeScore/index";
import loginForm from '../pages/auth/loginForm';
import forgotPassword from '../pages/auth/forgotPassword';
import resetPassword from '../pages/auth/resetPassword';
import signUp from "../pages/auth/signUp/index";
import Otp from "../pages/auth/otp/index";
import PrivateRoute from '../routes/PrivateRoute';
import DashboardIndex from '../pages/dashboard';
import Integration from '../pages/dashboard';
import Githubchoose from '../pages/dashboard';
import CheckScore from '../pages/dashboard';
import Projects from '../pages/dashboard';
import WorkspaceSetting from '../pages/dashboard';
import ProjectsDetails from '../pages/dashboard';
import noProject from '../pages/dashboard';
import config from "../config";
import {refreshToken} from '../pages/auth/loginForm/actions'
import CheckScoreResult from '../pages/dashboard';


let rt = 15000
class RouteComponents extends Component {
    componentWillMount() {
        let timeInterval=config.app.REACT_APP_REFRESH_TOKEN;
       let authenticated = this.props.isAuthenticated;
       let tokenValue = localStorage.getItem('token');
       if(tokenValue && tokenValue !== "null"){
        setInterval(() => {

            if (authenticated) {
                rt = setInterval(() => {
                    clearInterval(rt);
                    if (authenticated) {
                        this.props.dispatch(refreshToken())
                    }
                }, 100)
            } else {

                clearInterval(rt);
            }
        }, timeInterval)
       }      
    }


    render() {
        return (
            <div>
                {/* <HeaderDashboard/> */}

            <BrowserRouter>
               
                    <Switch>
                    <Route exact path="/">
                    <Redirect to={'/checkscore'} /> 
                    </Route>
                    <Route exact path="/auth">
                    <Redirect to={'/auth/login'} /> 
                    </Route>
                    <PrivateRoute exact path="/layout">
                    <Redirect to={'/layout/integration'} />
                    </PrivateRoute>
                            <Route exact path={'/checkscore'} component={HomePage} />    
                        <Route exact path={'/auth/login'} component={loginForm}></Route>
                        <Route exact path={'/auth/forgotpassword'} component={forgotPassword}></Route>
                        <Route exact path={'/auth/resetPassword'} component={resetPassword}></Route>
                        <Route exact path={'/auth/signUp'} component={signUp}></Route>
                        <Route exact path={'/auth/otp'} component={Otp}></Route>
                        <PrivateRoute exact path={'/layout/dashboard'} component={DashboardIndex}></PrivateRoute>
                        <PrivateRoute exact path={'/layout/githubChoose'} component={Githubchoose}></PrivateRoute>
                        <PrivateRoute exact path={'/layout/integration'} component={Integration}></PrivateRoute>
                        <PrivateRoute exact path={'/layout/checkScore'} component={CheckScore}></PrivateRoute>
                        <PrivateRoute exact path={'/layout/project'} component={Projects}></PrivateRoute>
                        <PrivateRoute exact path={'/layout/workspace'} component={WorkspaceSetting}></PrivateRoute>
                        <PrivateRoute exact path={'/layout/projectDetails'} component={ProjectsDetails}></PrivateRoute>
                        <PrivateRoute exact path={'/layout/checkScoreResult'} component={CheckScoreResult}></PrivateRoute>
                        <PrivateRoute exact path={'/layout/noProject'} component={noProject}></PrivateRoute>
                    </Switch>
                
            </BrowserRouter>
            <NotificationContainer/>
              </div>
        );
    }
}
function mapStateToProps(state) {
    const {
       isAuthenticated
    } = state.loginReducer;
    return {
        isAuthenticated
    }

}

export default withRouter(connect(mapStateToProps)(RouteComponents));