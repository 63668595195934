import {RESET_PASSWORD_INVITE_REQUEST,
     RESET_PASSWORD_INVITE_SUCCESS, 
     RESET_ERROR_MESSAGE,
     RESET_PASSWORD_INVITE_FAILURE,
      UPDATE_FORGOT_PASSWORD_DATA,
      RESET_FORGOTPASSWORD_DATA} from './constants'
import { baseApi } from "../../../common/ApiAction/index";
import { POST_API } from '../../../middleware/symbols';

const BASE_API = baseApi();

export function updateForgotPasswordForm(data) {
    return { 
        type: UPDATE_FORGOT_PASSWORD_DATA, 
        data 
    }
}
export function resetPasswordData() {
    return { 
        type: RESET_FORGOTPASSWORD_DATA,  
    }
}
export function resetErrorMessage() {
    return { 
        type: RESET_ERROR_MESSAGE, 
         
    }
}
export function resetPasswordInvite(body){
    return{
        [POST_API]:{
            endpoint: BASE_API +'/v1/forgotpass',
            types:[RESET_PASSWORD_INVITE_REQUEST, RESET_PASSWORD_INVITE_SUCCESS, RESET_PASSWORD_INVITE_FAILURE],
            body
        }
    }
}



