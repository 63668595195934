
export  const POST_SCAN_REQUEST = "POST_SCAN_REQUEST";
export  const POST_SCAN_SUCCESS = "POST_SCAN_SUCCESS";
export  const POST_SCAN_FAILURE = "POST_SCAN_FAILURE";

export  const POST_CONTACT_REQUEST = "POST_CONTACT_REQUEST";
export  const POST_CONTACT_SUCCESS = "POST_CONTACT_SUCCESS";
export  const POST_CONTACT_FAILURE = "POST_CONTACT_FAILURE";

export  const GET_SCAN_REQUEST = "GET_SCAN_REQUEST";
export  const GET_SCAN_SUCCESS = "GET_SCAN_SUCCESS";
export  const GET_SCAN_FAILURE = "GET_SCAN_FAILURE";

export  const CLEAR_SCAN = "CLEAR_SCAN";
export  const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export  const CLEAR_URL = "CLEAR_URL";
export const CLEAR_FINAL_MESSAGE = "CLEAR_FINAL_MESSAGE";

export const UPDATE_SCAN="UPDATE_SCAN";
export const UPDATE_SCAN_LOADING= "UPDATE_SCAN_LOADING";

export const GET_FREE_SCORE_REQUEST = 'GET_FREE_SCORE_REQUEST';
export const GET_FREE_SCORE_SUCCESS = 'GET_FREE_SCORE_SUCCESS';
export const GET_FREE_SCORE_FAILURE = 'GET_FREE_SCORE_FAILURE';

export const CLEAR_POSTSCAN_ERROR = 'CLEAR_POSTSCAN_ERROR'



