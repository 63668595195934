import { RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE, UPDATE_RESET_PASSWORD_DATA ,RESET_DATA} from './constants';
import { baseApi } from "../../../common/ApiAction/index";
import { POST_API } from '../../../middleware/symbols';

const BASE_API = baseApi();

export function updateResetPasswordForm() {
    return { type: UPDATE_RESET_PASSWORD_DATA, resetData }
}

export function resetPassword(body) {
    return {
        [POST_API]:{
            endpoint: BASE_API + '/v1/forgotpass',
            types: [ RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE ],
            body,
            authenticated: true
        }
    }
}
export function resetData() {
    return {
       
            type: [ RESET_DATA ],
            
        
    }
}
