import { RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE, UPDATE_RESET_PASSWORD_DATA ,RESET_DATA} from './constants'

const initialState = {

    resetPasswordData: {
        state: 'reset',
        token: "",
        password: "",
        confirmpassword: ""
    },
    isPageLoading: '',
    forgotPasswordSuccessMsg: '',
    forgotPasswordError: false,
    isAuthenticated: localStorage.getItem('token') ? true : false,
}

export default function resetPassReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_RESET_PASSWORD_DATA:
            return Object.assign({}, state, {
                resetPasswordData: action.resetData,
            });
        case RESET_PASSWORD_REQUEST:
            return Object.assign({}, state, {
                isPageLoading: true,
                forgotPasswordSuccessMsg: '',
                forgotPasswordError: false
            });
        case RESET_PASSWORD_SUCCESS:
            return Object.assign({}, state, {
                isPageLoading: false,
                forgotPasswordSuccessMsg: action.response.data.message,
                forgotPasswordError: false
            });
        case RESET_PASSWORD_FAILURE:
            return Object.assign({}, state, {
                isPageLoading: false,
                forgotPasswordSuccessMsg: action.response.data.message,
                forgotPasswordError: action.response.data.error
            });
            case RESET_DATA:
            return Object.assign({}, state, {
                resetPasswordData: {
                    password: "",
                    confirmpassword: ""
                },
                isPageLoading: '',
                forgotPasswordSuccessMsg: '',
            });

        default:
            return state;
    }
}