import './dist/style.css';
import './dist/filter-modal-codebase.css';
import './dist/header-card.css'
import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import HeaderDashboard from "../header";


class ProjectsDetails extends React.Component {

    render() {
        function showFilterpopup() {
            document.querySelector('.scan-details-filter-icon').classList.toggle('scan-details-filter-icon-popup-modal-show');
        }
        return (
            <>
                <HeaderDashboard />
                <div className="layout-wrapper">
                    <div className="container extra-margin">
                        <div className='project-details-wrapper'>
                            <section class="github-scan-result">
                                <h2>Project details</h2>
                                <div class="github-scan-result-cards-wrapper">
                                    <div class="github-scan-result-cards-inside">
                                        <div class="github-scan-project-heading">
                                            <p>Node jS Project</p>
                                            <div class="risk-side">
                                                <img src={require('./dist/smile-emoji.svg')} alt="" />
                                                <p class="low-risk-text">LOW RISK</p>
                                            </div>
                                        </div>
                                        <div class="github-scan-quick-option">
                                            <div class="quick-option-list">
                                                <img src={require('./dist/project-details2.svg')} alt="" />
                                                <h3>Project Url</h3>
                                                <p>www. loremipsum.com /lorem</p>
                                            </div>
                                            <div class="quick-option-list">
                                                <img src={require('./dist/project-details1.svg')} alt="" />
                                                <h3>Branch</h3>
                                                <p>Denial of service in axios</p>
                                            </div>
                                            <div class="quick-option-list">
                                                <img src={require('./dist/project-2.svg')} alt="" />
                                                <h3>Project name</h3>
                                                <p>Akirastack repository</p>
                                            </div>
                                        </div>
                                        <div class="github-scan-score-result">
                                            <div class="github-scan-score-result-list">
                                                <h4 class="critical-risk">50</h4>
                                                <p>Critical</p>
                                            </div>
                                            <div class="github-scan-score-result-list">
                                                <h4 class="high-risk">12</h4>
                                                <p>High</p>
                                            </div>
                                            <div class="github-scan-score-result-list">
                                                <h4 class="medium-risk">12</h4>
                                                <p>Medium</p>
                                            </div>
                                            <div class="github-scan-score-result-list">
                                                <h4 class="low-risk">12</h4>
                                                <p>Low</p>
                                            </div>
                                            <div class="github-scan-score-result-list">
                                                <h4 class="unknow-risk">0</h4>
                                                <p>Unknow</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section class="codebase-scan-result-cards">
                                <div className='codebase-scan-result-heading-wrapper'>
                                    <h2>Scan Details</h2>
                                    <div className='scan-details-filter-icon' onClick={showFilterpopup}>
                                        <p>Filters</p>
                                        <img src={require('./dist/down_arrow.png')} alt="donw-arrow" width="40" />
                                        <div className='scan-details-filter-icon-popup-modal'>
                                            <div className='scan-details-filter-icon-popup-form'>
                                                <form action="#">
                                                    <div>
                                                        <input type="radio" id="1" name="radio-group" />
                                                            <label for="1">Sort Severity High to Low</label>
                                                    </div>
                                                    <div>
                                                        <input type="radio" id="2" name="radio-group" />
                                                            <label for="2">Sort Severity Low to High</label>
                                                    </div>
                                                    
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="github-scan-result-cards-wrapper-overflow">
                                    <div class="codebase-scan-result-cards-heading">
                                        <div class="library-name">
                                            <div class="library-name-textside">
                                                <p>Library</p>
                                            </div>
                                            <div class="library-name-button-side">
                                                <div class="tooltip" data-text="Click for sort the data in ascending order">
                                                    <img src={require('./dist/Polygon.svg')} alt="Triangle" />
                                                </div>
                                                <div class="tooltip reverse" data-text="Click for sort the data in Descending order">
                                                    <img src={require('./dist/Polygon.svg')} alt="Triangle" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="library-name">
                                            <div class="library-name-textside">
                                                <p>Vulnerability ID</p>
                                            </div>
                                            <div class="library-name-button-side">
                                                <div class="tooltip" data-text="Click for sort the data in ascending order">
                                                    <img src={require('./dist/Polygon.svg')} alt="Triangle" />
                                                </div>
                                                <div class="tooltip reverse" data-text="Click for sort the data in Descending order">
                                                    <img src={require('./dist/Polygon.svg')} alt="Triangle" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="library-name">
                                            <div class="library-name-textside">
                                                <p>Severity</p>
                                            </div>
                                            <div class="library-name-button-side">
                                                <div class="tooltip" data-text="Click for sort the data in ascending order">
                                                    <img src={require('./dist/Polygon.svg')} alt="Triangle" />
                                                </div>
                                                <div class="tooltip reverse" data-text="Click for sort the data in Descending order">
                                                    <img src={require('./dist/Polygon.svg')} alt="Triangle" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="library-name">
                                            <div class="library-name-textside">
                                                <p>Installed <br />Version</p>
                                            </div>
                                        </div>
                                        <div class="library-name">
                                            <div class="library-name-textside">
                                                <p>Fixed <br />Version</p>
                                            </div>
                                        </div>
                                        <div class="library-name">
                                            <div class="library-name-textside">
                                                <p>Title</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="codebase-scan-result-cards-display">
                                        <div class="codebase-scan-result-library-name bold-600">
                                            <p>Library</p>
                                            <p>Axios</p>
                                        </div>
                                        <div class="codebase-scan-result-id bold-600">
                                            <p>Vulnerability ID</p>
                                            <p>CVE - 2019 - 1042</p>
                                        </div>
                                        <div class="codebase-scan-risk-type bold-600">
                                            <p>Severity</p>
                                            <p class="medium-risk">Medium</p>
                                        </div>
                                        <div class="codebase-scan-installed-version bold-600">
                                            <p>Installed Version</p>
                                            <p>0.16.2</p>
                                        </div>
                                        <div class="codebase-scan-fixed-version bold-600">
                                            <p>Fixed Version</p>
                                            <p>0.18.1</p>
                                        </div>
                                        <div class="codebase-scan-title bold-600">
                                            <p>Title</p>
                                            <p>Denial of service in axios</p>
                                            <p>avd . aquasec.com/ nvd / cve -2019</p>
                                        </div>
                                    </div>
                                    <div class="codebase-scan-result-cards-display">
                                        <div class="codebase-scan-result-library-name bold-600">
                                            <p>Library</p>
                                            <p>Axios</p>
                                        </div>
                                        <div class="codebase-scan-result-id bold-600">
                                            <p>Vulnerability ID</p>
                                            <p>CVE - 2019 - 1042</p>
                                        </div>
                                        <div class="codebase-scan-risk-type bold-600">
                                            <p>Severity</p>
                                            <p class="high-risk">High</p>
                                        </div>
                                        <div class="codebase-scan-installed-version bold-600">
                                            <p>Installed Version</p>
                                            <p>0.16.2</p>
                                        </div>
                                        <div class="codebase-scan-fixed-version bold-600">
                                            <p>Fixed Version</p>
                                            <p>0.18.1</p>
                                        </div>
                                        <div class="codebase-scan-title bold-600">
                                            <p>Title</p>
                                            <p>Denial of service in axios</p>
                                            <p>avd . aquasec.com/ nvd / cve -2019</p>
                                        </div>
                                    </div>
                                    <div class="codebase-scan-result-cards-display">
                                        <div class="codebase-scan-result-library-name bold-600">
                                            <p>Library</p>
                                            <p>Axios</p>
                                        </div>
                                        <div class="codebase-scan-result-id bold-600">
                                            <p>Vulnerability ID</p>
                                            <p>CVE - 2019 - 1042</p>
                                        </div>
                                        <div class="codebase-scan-risk-type bold-600">
                                            <p>Severity</p>
                                            <p class="medium-risk">Medium</p>
                                        </div>
                                        <div class="codebase-scan-installed-version bold-600">
                                            <p>Installed Version</p>
                                            <p>0.16.2</p>
                                        </div>
                                        <div class="codebase-scan-fixed-version bold-600">
                                            <p>Fixed Version</p>
                                            <p>0.18.1</p>
                                        </div>
                                        <div class="codebase-scan-title bold-600">
                                            <p>Title</p>
                                            <p>Denial of service in axios</p>
                                            <p>avd . aquasec.com/ nvd / cve -2019</p>
                                        </div>
                                    </div>
                                    <div class="codebase-scan-result-cards-display">
                                        <div class="codebase-scan-result-library-name bold-600">
                                            <p>Library</p>
                                            <p>Axios</p>
                                        </div>
                                        <div class="codebase-scan-result-id bold-600">
                                            <p>Vulnerability ID</p>
                                            <p>CVE - 2019 - 1042</p>
                                        </div>
                                        <div class="codebase-scan-risk-type bold-600">
                                            <p>Severity</p>
                                            <p class="low-risk">Low</p>
                                        </div>
                                        <div class="codebase-scan-installed-version bold-600">
                                            <p>Installed Version</p>
                                            <p>0.16.2</p>
                                        </div>
                                        <div class="codebase-scan-fixed-version bold-600">
                                            <p>Fixed Version</p>
                                            <p>0.18.1</p>
                                        </div>
                                        <div class="codebase-scan-title bold-600">
                                            <p>Title</p>
                                            <p>Denial of service in axios</p>
                                            <p>avd . aquasec.com/ nvd / cve -2019</p>
                                        </div>
                                    </div>
                                    <div class="codebase-scan-result-cards-display">
                                        <div class="codebase-scan-result-library-name bold-600">
                                            <p>Library</p>
                                            <p>Axios</p>
                                        </div>
                                        <div class="codebase-scan-result-id bold-600">
                                            <p>Vulnerability ID</p>
                                            <p>CVE - 2019 - 1042</p>
                                        </div>
                                        <div class="codebase-scan-risk-type bold-600">
                                            <p>Severity</p>
                                            <p class="high-risk">High</p>
                                        </div>
                                        <div class="codebase-scan-installed-version bold-600">
                                            <p>Installed Version</p>
                                            <p>0.16.2</p>
                                        </div>
                                        <div class="codebase-scan-fixed-version bold-600">
                                            <p>Fixed Version</p>
                                            <p>0.18.1</p>
                                        </div>
                                        <div class="codebase-scan-title bold-600">
                                            <p>Title</p>
                                            <p>Denial of service in axios</p>
                                            <p>avd . aquasec.com/ nvd / cve -2019</p>
                                        </div>
                                    </div>
                                    <div class="codebase-scan-result-cards-display">
                                        <div class="codebase-scan-result-library-name bold-600">
                                            <p>Library</p>
                                            <p>Axios</p>
                                        </div>
                                        <div class="codebase-scan-result-id bold-600">
                                            <p>Vulnerability ID</p>
                                            <p>CVE - 2019 - 1042</p>
                                        </div>
                                        <div class="codebase-scan-risk-type bold-600">
                                            <p>Severity</p>
                                            <p class="medium-risk">Medium</p>
                                        </div>
                                        <div class="codebase-scan-installed-version bold-600">
                                            <p>Installed Version</p>
                                            <p>0.16.2</p>
                                        </div>
                                        <div class="codebase-scan-fixed-version bold-600">
                                            <p>Fixed Version</p>
                                            <p>0.18.1</p>
                                        </div>
                                        <div class="codebase-scan-title bold-600">
                                            <p>Title</p>
                                            <p>Denial of service in axios</p>
                                            <p>avd . aquasec.com/ nvd / cve -2019</p>
                                        </div>
                                    </div>
                                    <div class="codebase-scan-result-cards-display">
                                        <div class="codebase-scan-result-library-name bold-600">
                                            <p>Library</p>
                                            <p>Axios</p>
                                        </div>
                                        <div class="codebase-scan-result-id bold-600">
                                            <p>Vulnerability ID</p>
                                            <p>CVE - 2019 - 1042</p>
                                        </div>
                                        <div class="codebase-scan-risk-type bold-600">
                                            <p>Severity</p>
                                            <p class="medium-risk">Medium</p>
                                        </div>
                                        <div class="codebase-scan-installed-version bold-600">
                                            <p>Installed Version</p>
                                            <p>0.16.2</p>
                                        </div>
                                        <div class="codebase-scan-fixed-version bold-600">
                                            <p>Fixed Version</p>
                                            <p>0.18.1</p>
                                        </div>
                                        <div class="codebase-scan-title bold-600">
                                            <p>Title</p>
                                            <p>Denial of service in axios</p>
                                            <p>avd . aquasec.com/ nvd / cve -2019</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    const { scanWebsiteData } = state.websiteScanReducer;
    return { scanWebsiteData };
}
export default withRouter(connect(mapStateToProps)(ProjectsDetails));