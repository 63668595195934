import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { hubspotURL, websiteURL, securityApi } from '../../../common/ApiAction/index';
import Footer from '../Footer/index';
import Header from '../header/index';
import './dist/CSS/style.css';
import './dist/CSS/modal.css';
import './dist/CSS/formdetail.css';
import './dist/CSS/checkscoremodal.css';
import './dist/CSS/discuss-need-form-check-score.css';


import {
    updateUrl,
    updateLoading,
    postScan,
    clearScan,
    getFreeScore,
    clearFinalMessage,
    clearPostScanError
} from './actions';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { DotWaveLoader } from 'react-inline-loaders';
let sse;
const WEBSITE_URL = websiteURL();
const SECURITY_API = securityApi();
const HUBSPOT_URL = hubspotURL();
let nameTarget;

const validEmailRegexCFS = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const validPhoneRegexCFS = RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
const validNameRefexCFS = RegExp(/^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{1,}|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{3,}\s{1}[a-zA-Z0-9]{1,})$/);

const validateFormCFS = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

function checkEmptyFieldCFS() {
    let username = document.getElementById('fullNamecfs');
    let email = document.getElementById('emailcfs');

    if (username.value == '') {
        return false
    }
    else if (email.value == '') {
        return false
    }
    else {
        return true;
    }
}
class demoComponent extends Component {
    state = {
        urlLink: '',
        curUserUrl: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            fullNamecfs: null,
            emailcfs: null,
            lastnamecfs: null,
            companynamecfs: null,
            jobtitlecfs: null,
            numbercfs: null,
            messagecfs: null,
            errors: {
                fullNamecfs: '',
                emailcfs: '',
                lastnamecfs: '',
                companynamecfs: '',
                jobtitlecfs: '',
                numbercfs: '',
                messagecfs: '',
            }
        };
        this.handleDataChange = this.handleDataChange.bind(this)
    }

    handleChangeCFS = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            case 'fullNamecfs':
                errors.fullNamecfs =
                validNameRefexCFS.test(value)
                ? ''
                : 'First Name is not valid!';
                break;
            case 'lastnamecfs':
                errors.lastnamecfs =
                validNameRefexCFS.test(value)
                ? ''
                : 'Last Name is not valid!';
                break;
            case 'emailcfs':
                errors.emailcfs =
                    validEmailRegexCFS.test(value)
                        ? ''
                        : 'Email is not valid!';
                break;
            case 'companynamecfs':
                errors.companynamecfs =
                validNameRefexCFS.test(value)
                ? ''
                : 'Company Name is not valid!';
                break;
            case 'jobtitlecfs':
                errors.jobtitlecfs =
                validNameRefexCFS.test(value)
                ? ''
                : 'Job Title is not valid!';
                break;
            case 'numbercfs':
                errors.numbercfs =
                validPhoneRegexCFS.test(value)
                        ? ''
                        : 'Number is not valid!';
                break;
            case 'messagecfs':
                errors.messagecfs =
                validNameRefexCFS.test(value)
                ? ''
                : 'Message is not valid!';
                break;
            default:
                break;
        }

        this.setState({ errors, [name]: value });
    }

    sendAsessmentDataToHubspot() {
        let checkedUrl = this.props.scanData.url;
        let getAssessmentformFields = [
            {
                "name": "firstname",
                "value": `${this.props.scanData.first_name}`

            },
            {
                "name": "lastname",
                "value": `${this.props.scanData.last_name}`
            },
            {
                "name": "email",
                "value": `${this.props.scanData.email}`
            },
            {
                "name": "website",
                "value": `${checkedUrl}`
            }
        ]
        var xhr = new XMLHttpRequest();
        //Change the portal id and form Id for final integration.
        var url = HUBSPOT_URL + '/24998068/2dea8742-88bc-4202-a867-0af199158b30'

        var data = {
            "fields": getAssessmentformFields,
        }
        var final_data = JSON.stringify(data);
        xhr.open('POST', url);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(final_data);
    }

    handleSubmitCFS = (event) => {
        event.preventDefault();
        if (validateFormCFS(this.state.errors) && checkEmptyFieldCFS()) {
            //hubspot API calling
            let username = document.getElementById('fullNamecfs').value;
            let lastname = document.getElementById('lastnamecfs').value;
            let email = document.getElementById('emailcfs').value;
            let message = document.getElementById('messagecfs').value;
            let phoneno = document.getElementById('numbercfs').value;
            let companyname = document.getElementById('companynamecfs').value;
            let jobtitle = document.getElementById('jobtitlecfs').value; 
    
            let formFields = [
                {
                    "name": "firstname",
                    "value": `${username}`

                },
                {
                    "name": "lastname",
                    "value": `${lastname}`
                },
                {
                    "name": "email",
                    "value": `${email}`
                },
                {
                    "name": "message",
                    "value": `${message}`
                },
                {
                    "name": "mobilephone",
                    "value": `${phoneno}`
                },
                {
                    "name": "company",
                    "value": `${companyname}`
                },
                {
                    "name": "jobtitle",
                    "value": `${jobtitle}`
                }   
            ]
            var xhr = new XMLHttpRequest();
            //Change the portal id and form Id for final integration.
            var url = HUBSPOT_URL + '/8161231/82e0eb1a-2402-4c6a-8531-cd77e4fb6b1f';


            var data = {
                "fields": formFields,
                "legalConsentOptions": {
                    "consent": {
                        "consentToProcess": true,
                        "text": "I agree to allow Example Company to store and process my personal data.",
                        "communications": [
                            {
                                "value": true,
                                "subscriptionTypeId": 999,
                                "text": "I agree to receive marketing communications from Example Company."
                            }
                        ]
                    }
                },
                "context": {
                    "pageUri": "https://www.xenonstack.com/",
                    "pageName": "Xenonstack"
                },

            }
            var final_data = JSON.stringify(data);



            xhr.open('POST', url);
            xhr.setRequestHeader('Content-Type', 'application/json');

            //Need to remove or comment when integrated finally.
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4 && xhr.status == 200) {
                    document.querySelector('.modal_containercfs').style.display = "none";
                    document.querySelector('.thank-you-message-cfs').classList.add('thank-you-message-show');
                    document.querySelector('.dicuss-invite-modal-checkscore').style.width = "unset";
                    // Returns a 200 response if the submission is successful.
                } else if (xhr.readyState == 4 && xhr.status == 400) {
                    alert(xhr.responseText); // Returns a 400 error the submission is rejected.          
                } else if (xhr.readyState == 4 && xhr.status == 403) {
                    alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.           
                } else if (xhr.readyState == 4 && xhr.status == 404) {
                    alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found     
                }
            }

            xhr.send(final_data);
        }
    }
    //
    //Getting the website score through the sse events.

    checkFreeScoreSSE(id) {
        let parseData;
        sse = new EventSource(`${SECURITY_API}/v1/result/${id}`);
        sse.onmessage = (e) => {
            parseData = JSON.parse(e.data);
            this.props.dispatch(getFreeScore(parseData));
        };
        sse.onerror = () => {
            sse.close();
        };
        return () => {
            sse.close();
        };
    }

    //If url has information
    componentDidMount() {
        const search = this.props.location.search;
        const checkscore = new URLSearchParams(search).get('p');
        var link = new URLSearchParams(search).get('url');
        if (checkscore === 'true') {
            document.querySelector('#invite').style.display = 'grid';
        }
        if (link) {
            let body = {
                url: link,

            };

            this.props.dispatch(postScan(body));
            this.setState({ urlLink: link });
            let checkedUrl = this.props.scanData;
            if (!/^(?:f|ht)tps?\:\/\//.test(this.props.scanData)) {
                checkedUrl = 'https://' + this.props.scanData;
            }
            const newState = Object.assign(this.props.scanData, {
                ['url']: checkedUrl,
            });
            this.props.dispatch(updateUrl(newState));
            this.props.dispatch(clearScan());
            if (localStorage.getItem('scanId')) {
                this.checkFreeScoreSSE(localStorage.getItem('scanId'));
            }
            this.props.dispatch(updateLoading(false));
        }
    }

    //Close modal
    closeInviteModal() {
        let elementone = document.querySelectorAll('.has-error');
        [...elementone].forEach((i) => i.classList.remove('has-error'))
        if (nameTarget && nameTarget.classList) {
            nameTarget.classList.remove('has-error');
            nameTarget.nextElementSibling.classList.remove('has-error');
        }
        this.props.dispatch(clearScan());
        document.querySelector('#invite').style.display = 'none';
    }


    //Open modal
    openInviteModal() {
        document.querySelector('#invite').style.display = 'grid';
    }



    //Modal data change
    handleDataChange(e,text) {
        this.setState({ [e.target.name]: e.target.value });
        let name = e.target.name;
        nameTarget = e.target;
        let value = e.target.value;
       
        const newState = Object.assign(this.props.scanData, {
            [name]: value,
        });
        this.props.dispatch(clearPostScanError())
        this.props.dispatch(updateUrl(newState));
        this.setState({curUserUrl: e.target.value})
        if (e.target.checkValidity()) {
            e.target.classList.remove('has-error');
            e.target.nextElementSibling.classList.remove('has-error');
        } else {
            if(e.target.value === ''){
                e.target.classList.add('has-error');
                e.target.nextElementSibling.classList.add('has-error');
                e.target.nextElementSibling.innerHTML='Please enter '+text;
                
            }
            else{
                e.target.classList.add('has-error');
                e.target.nextElementSibling.innerHTML='Please enter valid '+text;
                e.target.nextElementSibling.classList.add('has-error');
            }
        }
    }

    //Submit data
    async handleFormSubmit(event) {

        event.preventDefault();

        let checkedUrl = this.props.scanData.url;
        if (event.target.checkValidity()) {

            //check if http or https present or not
            if (!/^(?:f|ht)tps?\:\/\//.test(this.props.scanData.url)) {
                checkedUrl = 'https://' + this.props.scanData.url;
            }
            let body = {
                url: checkedUrl,
                email: this.props.scanData.email,
                first_name: this.props.scanData.first_name,
                last_name: this.props.scanData.last_name,
            };
            this.sendAsessmentDataToHubspot();
            await this.props.dispatch(postScan(body));
            if (localStorage.getItem('scanId') && sse && (this.props.postScanError === false)) {
                if (this.props.postScanError === false) {
                    sse.close();
                }
            }
            if (this.props.scanId) {
                localStorage.setItem('scanId', this.props.scanId);
            }

            if (this.props.postScanError === false) {
                this.closeInviteModal();
                this.props.dispatch(clearFinalMessage());
                this.props.dispatch(updateLoading(true));
            }
            document.querySelector('.header').style.display = '';
            let element = document.getElementById('headerblock');
            element.classList.remove('headershadow');
        } else {
            const invalidInputs = document.querySelectorAll(
                '.form-control:invalid'
            );
            if (invalidInputs.length > 0) {
                for (let i = 0; i < invalidInputs.length; i++) {
                    invalidInputs[i].classList.add('has-error');
                    invalidInputs[i].nextElementSibling.classList.add(
                        'has-error'
                    );
                }
            }
        }
    }

    //Toggle div
    handleImpactClick(e) {
        let parent = e.target.closest('.cards-wrapper');
        let div = parent.querySelector('.toggle_div');
        div.classList.toggle('show');
        parent.querySelector('.down-arrow-image').classList.toggle('up-arrow');

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.postScanMessage !== '') {
            if (nextProps.postScanError === true) {
                this.openInviteModal();
            } else {
                this.setState({ urlLink: nextProps.getUrl });
                this.props.dispatch(clearScan());
                if (nextProps.scanId) {
                    if (this.props.postScanError === false && sse) {
                        sse.close();
                    }

                    this.checkFreeScoreSSE(nextProps.scanId);
                }
            }
        }
    }
    render() {
        const {
            getWebsiteSecurity,
            getEmailSecurity,
            getHttpSecurity,
            getNetworkSecurity,
            postScanScore,
            id,
            loading,
            resultFinalMessage,
            postScanLoading,
            emailSecurityLoader,
            httpSecurityLoader,
            networkSecurityLoader,
            websiteSecurityLoader,
            scanData,
        } = this.props;
        const { errors } = this.state;


        function openCheckScoreDiscussModal() {
            document.querySelector(".discuss-modal-wrapper-checkscore").style.display = "grid";
        }
        function closeCheckScoreDiscussModal() {
            document.querySelector(".discuss-modal-wrapper-checkscore").style.display = "none";
            document.querySelector('.formparentcfs').reset();
            document.querySelector('.modal_containercfs').style.display = "flex";
            document.querySelector('.thank-you-message-cfs').classList.remove('thank-you-message-show');
            document.querySelector('.dicuss-invite-modal-checkscore').style.width = "90%";
        }
        return (
            <>
                <Header />
                <div id='checkscore-body-sectioon'>
                <main>
                    <section class="check-free-banner">
                        <div class="container">
                            <div class="check-free-banner-inner">
                                <div class="check-free-banner-text">
                                    <h1>Website Security Assessment</h1>
                                    <p>
                                        To get a deeper insight about your
                                        website's security configurations and to
                                        check whether security best practices
                                        are being followed or not, try our
                                        Website Security Assessment and Scoring
                                        Tool. If you want to expand this
                                        assessment across your organization
                                        including third-party vendors, book a
                                        free demo today.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="banner-mini-card">
                        <div class="container">
                            <div class="mini-card-wrapper">
                                <div class="mini-card-inner">
                                    <div class="mini-card-title">
                                        <div class="card-heading">
                                            <p>
                                                MetaSecure Rating{' '}
                                                {this.state.urlLink !== ''
                                                    ? 'for '
                                                    : ''}{' '}
                                                {this.state.urlLink !== '' ? (
                                                    <div
                                                        style={{
                                                            marginTop: '10px',
                                                        }}
                                                    >
                                                        <a
                                                            href={
                                                                this.state
                                                                    .urlLink
                                                            }
                                                            target="_blank"
                                                        >
                                                            {this.state.urlLink}
                                                        </a>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </p>
                                        </div>
                                        <div class="emoji">
                                            <img
                                                src={
                                                    resultFinalMessage !==
                                                        'Final result'
                                                        && loading ?

                                                        (require('./dist/images/thinking-emoji.png')) : (
                                                            (postScanScore > 85)
                                                                ? require('./dist/images/smile-emoji.svg')
                                                                : (postScanScore > 55 &&
                                                                    postScanScore < 85)
                                                                    ? require('./dist/images/normal-emoji.svg')
                                                                    : (postScanScore < 55 &&
                                                                        postScanScore > 35)
                                                                        ? require('./dist/images/sad-emoji.svg')
                                                                        : (postScanScore < 35 &&
                                                                            postScanScore > 1)
                                                                            ? require('./dist/images/very-sad-emoji.svg')
                                                                            : ''
                                                        )}
                                                alt=""
                                                class="smile"
                                            />
                                        </div>
                                        <div class="comment">
                                            <p
                                                className={
                                                    postScanScore > 85
                                                        ? 'comment-para-verylow'
                                                        : postScanScore > 55 &&
                                                            postScanScore < 85
                                                            ? 'comment-para-low'
                                                            : postScanScore > 35 &&
                                                                postScanScore < 55
                                                                ? 'comment-para-moderate'
                                                                : postScanScore > 1 &&
                                                                    postScanScore < 35
                                                                    ? 'comment-para-medium'
                                                                    : postScanScore < 1
                                                                        ? 'comment-para-high'
                                                                        : 'comment-para-critical'
                                                }
                                            >
                                                {
                                                    resultFinalMessage !==
                                                        'Final result'
                                                        && loading ?
                                                        (

                                                            <svg
                                                                width="50"
                                                                height="50"
                                                                viewBox="0 0 120 30"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="#90CA2F"
                                                                aria-label="audio-loading"
                                                            >
                                                                <circle
                                                                    cx="15"
                                                                    cy="15"
                                                                    r="15"
                                                                >
                                                                    <animate
                                                                        attributeName="r"
                                                                        from="15"
                                                                        to="15"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="15;9;15"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                    <animate
                                                                        attributeName="fillOpacity"
                                                                        from="1"
                                                                        to="1"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="1;.5;1"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                </circle>
                                                                <circle
                                                                    cx="60"
                                                                    cy="15"
                                                                    r="9"
                                                                    attributeName="fillOpacity"
                                                                    from="1"
                                                                    to="0.3"
                                                                >
                                                                    <animate
                                                                        attributeName="r"
                                                                        from="9"
                                                                        to="9"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="9;15;9"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                    <animate
                                                                        attributeName="fillOpacity"
                                                                        from="0.5"
                                                                        to="0.5"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values=".5;1;.5"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                </circle>
                                                                <circle
                                                                    cx="105"
                                                                    cy="15"
                                                                    r="15"
                                                                >
                                                                    <animate
                                                                        attributeName="r"
                                                                        from="15"
                                                                        to="15"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="15;9;15"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                    <animate
                                                                        attributeName="fillOpacity"
                                                                        from="1"
                                                                        to="1"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="1;.5;1"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                </circle>
                                                            </svg>
                                                        ) : (
                                                            (postScanScore > 85) ?

                                                                'Low Risk'
                                                                : (postScanScore < 85 &&
                                                                    postScanScore > 55)
                                                                    ? 'Medium Risk'
                                                                    : (postScanScore < 55 &&
                                                                        postScanScore > 35)
                                                                        ? 'High Risk'
                                                                        : (postScanScore < 35 &&
                                                                            postScanScore > 1)
                                                                            ? 'Critical'
                                                                            : ' '
                                                        )}
                                            </p>
                                        </div>
                                    </div>

                                    <div class="grade-inner">
                                        <div>
                                            <div className="grade-side">
                                                <div
                                                    class={

                                                        resultFinalMessage !==
                                                            'Final result'
                                                            &&
                                                            loading
                                                            ?
                                                            (
                                                                ''
                                                            ) : (
                                                                (postScanScore > 85)
                                                                    ? 'grade verylow'
                                                                    : (postScanScore > 55 &&
                                                                        postScanScore < 85)
                                                                        ? 'grade low'
                                                                        : (postScanScore > 35 &&
                                                                            postScanScore < 55)
                                                                            ? 'grade moderate'
                                                                            : (postScanScore > 1 &&
                                                                                postScanScore < 35)
                                                                                ? 'grade medium'
                                                                                : (postScanScore < 1)
                                                                                    ? 'grade high'
                                                                                    : 'grade critical'
                                                            )
                                                    }
                                                >
                                                    <span
                                                        class={
                                                            resultFinalMessage !==
                                                                'Final result'
                                                                && loading
                                                                ?
                                                                (
                                                                    " "

                                                                ) : (
                                                                    (postScanScore > 85)
                                                                        ? 'grade-list verylow'
                                                                        : (postScanScore > 55 &&
                                                                            postScanScore < 85)
                                                                            ? 'grade-list low'
                                                                            : (postScanScore > 35 &&
                                                                                postScanScore < 55)
                                                                                ? 'grade-list moderate'
                                                                                : (postScanScore > 1 &&
                                                                                    postScanScore < 35)
                                                                                    ? 'grade-list medium'
                                                                                    : (postScanScore < 1)
                                                                                        ? 'grade-list high'
                                                                                        : 'grade-list critical'
                                                                )}
                                                    >
                                                        {
                                                            resultFinalMessage !==
                                                                'Final result'
                                                                && loading ?
                                                                (
                                                                    " "

                                                                ) : (
                                                                    (postScanScore > 85)
                                                                        ? 'A'
                                                                        : (postScanScore > 55 &&
                                                                            postScanScore < 85)
                                                                            ? 'B'
                                                                            : (postScanScore > 35 &&
                                                                                postScanScore < 55)
                                                                                ? 'C'
                                                                                : (postScanScore > 1 &&
                                                                                    postScanScore < 35)
                                                                                    ? 'D'
                                                                                    : 'C')}
                                                    </span>
                                                </div>
                                                <span
                                                    class={
                                                        resultFinalMessage !==
                                                            'Final result'
                                                            && loading ?
                                                            (
                                                                'grade-number critical'

                                                            ) : (
                                                                (postScanScore > 85)
                                                                    ? 'grade-number verylow'
                                                                    : (postScanScore > 55 &&
                                                                        postScanScore < 85)
                                                                        ? 'grade-number low'
                                                                        : (postScanScore > 35 &&
                                                                            postScanScore < 55)
                                                                            ? 'grade-number moderate'
                                                                            : (postScanScore > 1 &&
                                                                                postScanScore < 35)
                                                                                ? 'grade-number medium'
                                                                                : (postScanScore < 1)
                                                                                    ? 'grade-number high'
                                                                                    : 'grade-number critical'
                                                            )}

                                                >
                                                    {resultFinalMessage !==
                                                        'Final result' &&
                                                        loading ?
                                                        (
                                                            <svg
                                                                width="50"
                                                                height="50"
                                                                viewBox="0 0 120 30"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="#90CA2F"
                                                                aria-label="audio-loading"
                                                            >
                                                                <circle
                                                                    cx="15"
                                                                    cy="15"
                                                                    r="15"
                                                                >
                                                                    <animate
                                                                        attributeName="r"
                                                                        from="15"
                                                                        to="15"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="15;9;15"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                    <animate
                                                                        attributeName="fillOpacity"
                                                                        from="1"
                                                                        to="1"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="1;.5;1"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                </circle>
                                                                <circle
                                                                    cx="60"
                                                                    cy="15"
                                                                    r="9"
                                                                    attributeName="fillOpacity"
                                                                    from="1"
                                                                    to="0.3"
                                                                >
                                                                    <animate
                                                                        attributeName="r"
                                                                        from="9"
                                                                        to="9"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="9;15;9"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                    <animate
                                                                        attributeName="fillOpacity"
                                                                        from="0.5"
                                                                        to="0.5"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values=".5;1;.5"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                </circle>
                                                                <circle
                                                                    cx="105"
                                                                    cy="15"
                                                                    r="15"
                                                                >
                                                                    <animate
                                                                        attributeName="r"
                                                                        from="15"
                                                                        to="15"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="15;9;15"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                    <animate
                                                                        attributeName="fillOpacity"
                                                                        from="1"
                                                                        to="1"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="1;.5;1"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                </circle>
                                                            </svg>
                                                        ) : (
                                                            postScanScore
                                                        )}
                                                    /100
                                                </span>
                                            </div>
                                            <div class="mini-card-text">
                                                {resultFinalMessage !==
                                                    'Final result'
                                                    && loading
                                                    ?
                                                    (<p>
                                                        SCANNING IS IN PROGRESSING
                                                    </p>
                                                    ) : (
                                                        '')}
                                            </div>

                                        </div>
                                        {loading && (
                                        <div class="check-free-banner-button">
                                            <button
                                                class="banner-button"
                                                type="button" onClick={openCheckScoreDiscussModal}>
                                                Improve your score
                                            </button>
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div class="cards">
                        <div class="container">
                            <div class="cards-wrapper">
                                <div class="cards-inner">
                                    <div class="cards-inner-left">
                                        <h3>
                                            <img
                                                class="icon-image"
                                                src={require('./dist/images/check_free_score_page_icon.svg')}
                                                alt="MetaSecure Chcek Free Score Icon"
                                                height="34"
                                                width="34"
                                            />
                                            Website Security
                                        </h3>
                                    </div>
                                    <div class="cards-inner-right">
                                        <a
                                            href="javascript:void(0)"
                                            onClick={this.handleImpactClick.bind(
                                                this
                                            )}
                                        >
                                            Impact
                                            <img
                                                class="down-arrow-image"
                                                src={require('./dist/images/down_arrow.png')}
                                                alt="MetaSecure Down Arrow Icon"
                                            />
                                        </a>
                                    </div>
                                </div>

                                <div className="toggle_div show">
                                    {getWebsiteSecurity.length > 0 &&
                                        getWebsiteSecurity.map(
                                            (info, index) => (
                                                <div
                                                    class="cards-inner-item"
                                                    key={index}
                                                >
                                                    <div class="cards-inner-left-inner">
                                                        <a href={`${WEBSITE_URL}/vulnerability-details?id=${JSON.parse(info.result).id}`} target="_blank">
                                                            <h4>
                                                                {JSON.parse(
                                                                    info.result
                                                                ).heading
                                                                    ? JSON.parse(
                                                                        info.result
                                                                    ).heading
                                                                    : JSON.parse(
                                                                        info.result
                                                                    ).header}
                                                            </h4>
                                                        </a>
                                                        <p>
                                                            {JSON.parse(
                                                                info.result
                                                            ).description
                                                                ? JSON.parse(
                                                                    info.result
                                                                ).description
                                                                : JSON.parse(
                                                                    info.result
                                                                ).message}
                                                        </p>
                                                    </div>
                                                    <div class="cards-inner-right-inner">
                                                        <button
                                                            class={
                                                                JSON.parse(
                                                                    info.result
                                                                ).impact ===
                                                                    'HIGH'
                                                                    ? 'high-button'
                                                                    : JSON.parse(
                                                                        info.result
                                                                    )
                                                                        .impact ===
                                                                        'PASS'
                                                                        ? 'pass-button'
                                                                        : JSON.parse(
                                                                            info.result
                                                                        )
                                                                            .impact ===
                                                                            'MEDIUM'
                                                                            ? 'medium-button'
                                                                            : JSON.parse(
                                                                                info.result
                                                                            )
                                                                                .impact ===
                                                                                'INFORMATIONAL'
                                                                                ? 'info-button'
                                                                                : 'low-button'
                                                            }
                                                            type="button"
                                                        >
                                                            {
                                                                JSON.parse(
                                                                    info.result
                                                                ).impact
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        )}

                                    {websiteSecurityLoader !== false &&
                                        loading &&
                                        !this.props.postScanError && (
                                            <div
                                                style={{
                                                    width: '170%',
                                                    justifyContent: 'center',
                                                    textAlign: 'center',
                                                    color: 'red',
                                                }}
                                            >
                                                <DotWaveLoader />
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="cards">
                        <div class="container">
                            <div class="cards-wrapper">
                                <div class="cards-inner">
                                    <div class="cards-inner-left">
                                        <h3>
                                            <img
                                                class="icon-image"
                                                src={require('./dist/images/check_free_score_page_icon.svg')}
                                                alt="MetaSecure Check Free Score Icon"
                                                height="34"
                                                width="34"
                                            />
                                            Email Security
                                        </h3>
                                    </div>
                                    <div class="cards-inner-right">
                                        <a
                                            href="javascript:void(0)"
                                            onClick={this.handleImpactClick.bind(
                                                this
                                            )}
                                        >
                                            Impact
                                            <img
                                                class="down-arrow-image"
                                                src={require('./dist/images/down_arrow.png')}
                                                alt="MetaSecure Down Arrow Icon"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="toggle_div show">
                                    {getEmailSecurity.length > 0 &&
                                        getEmailSecurity.map((info, index) => (
                                            <div
                                                class="cards-inner-item"
                                                key={index}
                                            >
                                                <div class="cards-inner-left-inner">
                                                    <a href={`${WEBSITE_URL}/vulnerability-details?id=${JSON.parse(info.result).id}`} target="_blank">
                                                        <h4>
                                                            {JSON.parse(info.result)
                                                                .heading
                                                                ? JSON.parse(
                                                                    info.result
                                                                ).heading
                                                                : JSON.parse(
                                                                    info.result
                                                                ).header}
                                                        </h4>
                                                    </a>
                                                    <p>
                                                        {JSON.parse(info.result)
                                                            .description
                                                            ? JSON.parse(
                                                                info.result
                                                            ).description
                                                            : JSON.parse(
                                                                info.result
                                                            ).message}
                                                    </p>
                                                </div>
                                                <div class="cards-inner-right-inner">
                                                    <button
                                                        class={
                                                            JSON.parse(
                                                                info.result
                                                            ).impact === 'HIGH'
                                                                ? 'high-button'
                                                                : JSON.parse(
                                                                    info.result
                                                                ).impact ===
                                                                    'PASS'
                                                                    ? 'pass-button'
                                                                    : JSON.parse(
                                                                        info.result
                                                                    ).impact ===
                                                                        'MEDIUM'
                                                                        ? 'medium-button'
                                                                        : JSON.parse(
                                                                            info.result
                                                                        ).impact ===
                                                                            'INFORMATIONAL'
                                                                            ? 'info-button'
                                                                            : 'low-button'
                                                        }
                                                        type="button"
                                                    >
                                                        {
                                                            JSON.parse(
                                                                info.result
                                                            ).impact
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    {emailSecurityLoader !== false &&
                                        loading &&
                                        !this.props.postScanError && (
                                            <div
                                                style={{
                                                    width: '170%',
                                                    justifyContent: 'center',
                                                    textAlign: 'center',
                                                    color: 'red',
                                                }}
                                            >
                                                <DotWaveLoader />
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="cards">
                        <div class="container">
                            <div class="cards-wrapper">
                                <div class="cards-inner">
                                    <div class="cards-inner-left">
                                        <h3>
                                            <img
                                                class="icon-image"
                                                src={require('./dist/images/check_free_score_page_icon.svg')}
                                                alt="Metasecure Check Free Score Icon"
                                                height="34"
                                                width="34"
                                            />
                                            Network Security
                                        </h3>
                                    </div>
                                    <div class="cards-inner-right">
                                        <a
                                            href="javascript:void(0)"
                                            onClick={this.handleImpactClick.bind(
                                                this
                                            )}
                                        >
                                            Impact
                                            <img
                                                class="down-arrow-image"
                                                src={require('./dist/images/down_arrow.png')}
                                                alt="MetaSecure Down Arrow Icon"
                                            />
                                        </a>
                                    </div>
                                </div>

                                <div className="toggle_div show">
                                    {getNetworkSecurity.length > 0 &&
                                        getNetworkSecurity.map(
                                            (info, index) => (
                                                <div
                                                    class="cards-inner-item"
                                                    key={index}
                                                >

                                                    <div class="cards-inner-left-inner">
                                                        <a href={`${WEBSITE_URL}/vulnerability-details?id=${JSON.parse(info.result).id}`} target="_blank">
                                                            <h4>
                                                                {JSON.parse(
                                                                    info.result
                                                                ).heading
                                                                    ? JSON.parse(
                                                                        info.result
                                                                    ).heading
                                                                    : JSON.parse(
                                                                        info.result
                                                                    ).header}
                                                            </h4>
                                                        </a>
                                                        <p>
                                                            {JSON.parse(
                                                                info.result
                                                            ).description
                                                                ? JSON.parse(
                                                                    info.result
                                                                ).description
                                                                : JSON.parse(
                                                                    info.result
                                                                ).message}
                                                        </p>
                                                    </div>
                                                    <div class="cards-inner-right-inner">
                                                        <button
                                                            class={
                                                                JSON.parse(
                                                                    info.result
                                                                ).impact ===
                                                                    'HIGH'
                                                                    ? 'high-button'
                                                                    : JSON.parse(
                                                                        info.result
                                                                    )
                                                                        .impact ===
                                                                        'PASS'
                                                                        ? 'pass-button'
                                                                        : JSON.parse(
                                                                            info.result
                                                                        )
                                                                            .impact ===
                                                                            'MEDIUM'
                                                                            ? 'medium-button'
                                                                            : JSON.parse(
                                                                                info.result
                                                                            )
                                                                                .impact ===
                                                                                'INFORMATIONAL'
                                                                                ? 'info-button'
                                                                                : 'low-button'
                                                            }
                                                            type="button"
                                                        >
                                                            {
                                                                JSON.parse(
                                                                    info.result
                                                                ).impact
                                                            }

                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    {networkSecurityLoader !== false &&
                                        loading &&
                                        !this.props.postScanError && (
                                            <div
                                                style={{
                                                    width: '170%',
                                                    justifyContent: 'center',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <DotWaveLoader />
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cards">
                        <div class="container">
                            <div class="cards-wrapper">
                                <div class="cards-inner">
                                    <div class="cards-inner-left">
                                        <h3>
                                            <img
                                                class="icon-image"
                                                src={require('./dist/images/check_free_score_page_icon.svg')}
                                                alt="MetaSecure Check Free Score Icon"
                                                height="34"
                                                width="34"
                                            />
                                            HTTP Security Headers
                                        </h3>
                                    </div>
                                    <div class="cards-inner-right">
                                        <a
                                            href="javascript:void(0)"
                                            onClick={this.handleImpactClick.bind(
                                                this
                                            )}
                                        >
                                            Impact
                                            <img
                                                class="down-arrow-image"
                                                src={require('./dist/images/down_arrow.png')}
                                                alt="MetaSecure Down Arrow Icon"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="toggle_div show">

                                    {getHttpSecurity.length > 0 &&
                                        getHttpSecurity.map((info, index) => (
                                            <div
                                                class="cards-inner-item"
                                                key={index}
                                            >
                                                <div class="cards-inner-left-inner">
                                                    <a href={`${WEBSITE_URL}/vulnerability-details?id=${JSON.parse(info.result).id}`} target="_blank">
                                                        <h4>

                                                            {JSON.parse(info.result)
                                                                .heading
                                                                ? JSON.parse(
                                                                    info.result
                                                                ).heading
                                                                : JSON.parse(
                                                                    info.result
                                                                ).header}

                                                        </h4>
                                                    </a>
                                                    <p>
                                                        {JSON.parse(info.result)
                                                            .description
                                                            ? JSON.parse(
                                                                info.result
                                                            ).description
                                                            : JSON.parse(
                                                                info.result
                                                            ).message}
                                                    </p>
                                                </div>

                                                <div class="cards-inner-right-inner">
                                                    <button
                                                        class={
                                                            JSON.parse(
                                                                info.result
                                                            ).impact === 'HIGH'
                                                                ? 'high-button'
                                                                : JSON.parse(
                                                                    info.result
                                                                ).impact ===
                                                                    'PASS'
                                                                    ? 'pass-button'
                                                                    : JSON.parse(
                                                                        info.result
                                                                    ).impact ===
                                                                        'MEDIUM'
                                                                        ? 'medium-button'
                                                                        : JSON.parse(
                                                                            info.result
                                                                        ).impact ===
                                                                            'INFORMATIONAL'
                                                                            ? 'info-button'
                                                                            : 'low-button'
                                                        }
                                                        type="button"
                                                    >
                                                        {
                                                            JSON.parse(
                                                                info.result
                                                            ).impact
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    {httpSecurityLoader !== false &&
                                        loading &&
                                        !this.props.postScanError && (
                                            <div
                                                style={{
                                                    width: '170%',
                                                    justifyContent: 'center',
                                                    textAlign: 'center',
                                                    color: 'red',
                                                }}
                                            >
                                                <DotWaveLoader />
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* It will be taken according the future requirement 
                    <button class="dashboard-button" type="button">
                        <a href={WEBSITE_URL + '/coming-soon'}>
                            Go to Dashboard
                        </a>
                    </button> */}
                </main>
                <div className='checkscore-modal-discuss-wrapper'>
                    <div className='discuss-modal-wrapper-checkscore'>
                        <div class="dicuss-invite-modal-checkscore">
                            <div class="discuss-close-btn-wrapper-checkscore">
                                <button type="button" onClick={closeCheckScoreDiscussModal} class="close-btn">×</button>
                            </div>
                            <div class="modal_containercfs">
                                <div>
                                    <h2>
                                        Fill the form and the MetaSecure team will contact you to discuss your needs
                                    </h2>
                                    <form className='formparentcfs' onSubmit={this.handleSubmitCFS} noValidate>
                                        <div className='form-field flex-block'>
                                            <div>
                                                <label>First Name*</label>
                                                <input type="text" placeholder='First Name' id="fullNamecfs" name='fullNamecfs' onChange={this.handleChangeCFS} noValidate />
                                                {errors.fullNamecfs.length > 0 &&
                                                    <p className='errormessage'>{errors.fullNamecfs}</p>}
                                            </div>
                                            <div>
                                                <label>Last Name</label>
                                                <input type="text" placeholder='Last Name' id="lastnamecfs" name='lastnamecfs' onChange={this.handleChangeCFS} noValidate />
                                                {errors.lastnamecfs.length > 0 &&
                                                    <p className='errormessage'>{errors.lastnamecfs}</p>}
                                            </div>
                                        </div>
                                        <div className='form-field'>
                                            <div>
                                                <label>Email*</label>
                                                <input type="email" placeholder='Email' id='emailcfs' name='emailcfs' onChange={this.handleChangeCFS} noValidate />
                                                {errors.emailcfs.length > 0 &&
                                                    <p className='errormessage'>{errors.emailcfs}</p>}
                                            </div>
                                        </div>
                                        <div className='form-field flex-block'>
                                                    <div>
                                                        <label>Company Name*</label>
                                                        <input type="text" placeholder='Company Name' id="companynamecfs" name='companynamecfs' onChange={this.handleChange} noValidate />
                                                        {errors.companynamecfs.length > 0 &&
                                                            <p className='errormessage'>{errors.companyname}</p>}
                                                    </div>
                                                    <div>
                                                        <label>Job Title*</label>
                                                        <input type="text" placeholder='Job Title' id="jobtitlecfs" name='jobtitlecfs' onChange={this.handleChange} noValidate />
                                                        {errors.jobtitlecfs.length > 0 &&
                                                            <p className='errormessage'>{errors.jobtitlecfs}</p>}
                                                    </div>
                                                </div>
                                                <div className='form-field'>
                                                    <div>
                                                        <label>Phone Number</label>
                                                        <input type="text" placeholder='Phone Number' id='numbercfs' name='numbercfs' onChange={this.handleChange} noValidate />
                                                        {errors.numbercfs.length > 0 &&
                                                            <p className='errormessage'>{errors.numbercfs}</p>}
                                                    </div>
                                                </div>
                                                <div className='form-field'>
                                                    <div>
                                                        <label>Message</label>
                                                        <textarea placeholder='Message' rows='2' id="messagecfs" name='messagecfs' onChange={this.handleChange} noValidate />
                                                        {errors.messagecfs.length > 0 &&
                                                            <p className='errormessage'>{errors.messagecfs}</p>}
                                                    </div>
                                                </div>
                                        <input type="submit" value='submit' />
                                    </form>
                                </div>
                                <div class="modal_img">
                                    <img src={require('./dist/images/cfs-popup-image.svg')}  alt="Akirastack Modal Image" />
                                </div>
                            </div>
                            <div className='thank-you-message-cfs'>
                                <img src={require('./dist/images/cfs-popup-image.svg')}  alt="Akirastack Modal Image" />
                                <p>Thank you for submitting the form</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="checkscore-modal-wrapper" id="invite">
                    <div className="score-modal">
                        <div className="close-btn-wrapper">
                            <button type="button" onClick={this.closeInviteModal.bind(this)} className="close-btn">&times;</button>
                        </div>
                        <div className="modal_container-score">
                            <div className="website-score-form">
                                <div className="row justify-content-between">
                                    <div className="col-12 col-sm-6 col-lg-6 col-md-6 flex-center">
                                        <h3>Specify your details and Scan your website against vulnerabilities</h3>

                                        <form
                                            id="myForm"

                                            onSubmit={this.handleFormSubmit.bind(this)}
                                            noValidate={true}
                                        >
                                            <div className="form-field">
                                                <div className="inline-fields">
                                                    <div className="first-field">
                                                        <img src={require('./dist/images/person.svg')} alt="" />
                                                        <input
                                                            required
                                                            className="form-control"
                                                            type="text"
                                                            name="first_name"
                                                            id="first_name"
                                                            placeholder="First Name"
                                                            value={scanData.first_name}
                                                            onChange={(e) =>this.handleDataChange(e,'first name')}
                                                            pattern="[a-zA-Z]{2,32}" />
                                                        <p className="errors" id="fnameerror">
                                                            Please enter first name.
                                                        </p>
                                                    </div>

                                                    <div className="last-field">
                                                        <img src={require('./dist/images/person.svg')} alt="" />
                                                        <input
                                                            required
                                                            className="form-control"
                                                            type="text"
                                                            name="last_name"
                                                            id="last_name"
                                                            placeholder="Last Name"
                                                            value={scanData.last_name}
                                                            onChange={(e) =>this.handleDataChange(e,'last name')}
                                                            pattern="[a-zA-Z]{2,32}" />
                                                        <p className="errors">
                                                            Please enter last name.
                                                        </p>
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="form-field">
                                                <img src={require('./dist/images/mail.svg')} alt="" />
                                                <input
                                                    required
                                                    className="form-control"
                                                    type="text"
                                                    name="email"
                                                    id="signup-email"
                                                    placeholder="Email"
                                                    value={scanData.email} onChange={(e) =>this.handleDataChange(e,'email')}
                                                    pattern="[a-z0-9.]+@[a-z0-9.]+\.[a-z]{2,4}$" />
                                                <p className="errors" >
                                                    Please enter valid email
                                                </p>
                                            </div>
                                            <div className="form-field">
                                                <img src={require('./dist/images/website-url.svg')} alt="" />
                                                <input
                                                    required
                                                    className="form-control"
                                                    type="text"
                                                    name="url"
                                                    placeholder="https://www.example.com"
                                                    value={scanData.url}
                                                    onChange={(e) =>this.handleDataChange(e,'URL')}
                                                    pattern="(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-zA-Z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)" />
                                                <p className="errors"  >
                                                         Please enter valid URL
                                                    </p>
                                            </div>


                                            {this.props.postScanError && this.props.scanData.url === this.state.curUserUrl && this.state.curUserUrl !== '' && (
                                                <p class="errors-msg">
                                                    Website not found, URL is invalid
                                                </p>
                                            )}
                                            <a>
                                                <input type="submit" id="submit" value="Get Results" />
                                            </a>
                                            <span>
                                                {postScanLoading === true && (
                                                    <div
                                                        className="loader-container"
                                                        style={{
                                                            width: '170%',
                                                            justifyContent: 'flex-end',
                                                            color: 'rgb(64, 146, 222)',
                                                            position: 'relative',
                                                            paddingTop: '3%',
                                                            paddingLeft: '-18%',
                                                            display: 'flex',
                                                            alignItems: 'flex-start',
                                                            flexDirection: 'row-reverse',
                                                        }}
                                                    >
                                                        Fetching Score
                                                        <DotWaveLoader
                                                            style={{
                                                                margin: '0 !important',
                                                                width: '0 !important',
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </span>

                                        </form>

                                    </div>
                                    <div className="col-12 col-sm-5 col-lg-5 col-md-5">
                                        <div className='score-side-image-wrapper'>
                                            <div className="score-side-image">
                                                <img src={require('./dist/images/cfs-popup-image.svg')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                </div>
                <Footer />

            </>
        );
    }
}

function mapStateToProps(state) {
    const {
        scanData,
        scanId,
        loading,
        getUrl,
        postScanMessage,
        postScanError,
        postScanLoading,
        resultFinalMessage,
        emailSecurityLoader,
        httpSecurityLoader,
        networkSecurityLoader,
        websiteSecurityLoader,
        getScanLoading,
        getWebsiteSecurity,
        getEmailSecurity,
        getHttpSecurity,
        getNetworkSecurity,
        getScanError,
        id,
        postScanScore,
        getContactData,
        postContactLoading,
        postContactMessage,
        postContactError,
        status_code,
    } = state.checkScoreReducer;
    return {
        scanData,
        scanId,
        getUrl,
        loading,
        postScanMessage,
        postScanError,
        postScanLoading,
        resultFinalMessage,
        emailSecurityLoader,
        httpSecurityLoader,
        getHttpSecurity,
        networkSecurityLoader,
        websiteSecurityLoader,
        getScanLoading,
        getWebsiteSecurity,
        getEmailSecurity,
        getNetworkSecurity,
        getScanError,
        id,
        postScanScore,
        getContactData,
        postContactLoading,
        postContactMessage,
        postContactError,
        status_code,
    };
}

export default withRouter(connect(mapStateToProps)(demoComponent));
