import { securityApi } from '../../../common/ApiAction/index';
import { POST_API } from '../../../middleware/symbols';
import {
    CLEAR_MESSAGE,
    CLEAR_SCAN,
    POST_CONTACT_REQUEST,
    POST_CONTACT_SUCCESS,
    POST_SCAN_FAILURE,
    POST_SCAN_REQUEST,
    POST_SCAN_SUCCESS,
    UPDATE_SCAN,
    UPDATE_SCAN_LOADING,
    CLEAR_URL,
    GET_FREE_SCORE_SUCCESS,
    CLEAR_FINAL_MESSAGE,
    CLEAR_POSTSCAN_ERROR
 
} from './constant';

const SECURITY_API = securityApi();

export function postScan(body) {    
    return {

        [POST_API]: {
            endpoint: SECURITY_API + '/v1/scan',
            types: [POST_SCAN_REQUEST, POST_SCAN_SUCCESS, POST_SCAN_FAILURE],
            body,
        },
    };
} 

export function postContact(body) {
    return {
        [POST_API]: {
            endpoint: SECURITY_API + '/v1/notification',
            types: [
                POST_CONTACT_REQUEST,
                POST_CONTACT_SUCCESS,
                POST_SCAN_FAILURE,
            ],
            body,
        },
    };
}

export function clearScan() {
    return { type: CLEAR_SCAN };
}
export function clearPostScanError() {
    return { type: CLEAR_POSTSCAN_ERROR}
}
export function clearUrl() {
    return { type: CLEAR_URL };
}

export function clearMessage() {
    return { type: CLEAR_MESSAGE };
}

export function updateUrl(data) {
    return {
        type: UPDATE_SCAN,
        data,
    };
}
export function updateLoading(data) {
    return {
        type: UPDATE_SCAN_LOADING,
        data,
    };
}
export function clearFinalMessage() {
    return {
       type: CLEAR_FINAL_MESSAGE }
}
export function getFreeScore (data) {
  return {
    type:     
        GET_FREE_SCORE_SUCCESS,
    data,
  }
}

