import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { postOtp, removeOtp, updateScan } from './actions';
import './dist/style.css';
import Request_Demo_Page_Illustration_Object_Big_left from '../dist/Request Demo Page_Illustration-Object-Big-left.svg';
import noun_envelope from '../dist/noun_envelope_4125604.svg';
import Request_Demo_Page_Illustration_Object_small_right from "../dist/Request Demo Page_Illustration-Object-small-right.svg";
import Header from '../header/index';
import Footer from '../../checkFreeScore/Footer/index';


class Otp extends Component {

    componentDidMount() {
        this.props.dispatch(removeOtp())
        localStorage.getItem('loginName', this.props.name)
    }

    handleDataChange(e) {
        this.setState({ [e.target.name]: e.target.value });
        let name = e.target.name;
        let value = e.target.value;
        const newState = Object.assign(this.props.scanOtpData, {
            [name]: value,
        });
        this.props.dispatch(updateScan(newState));
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.postOtpError === false && nextProps.postOtpMessage === "Email verification done") {
            this.props.history.push("/layout");
            localStorage.setItem('loginName', nextProps.name)
            localStorage.setItem('loginEmail', nextProps.email)
            localStorage.setItem('token', nextProps.token)
        }
    }

    formSubmitHandler = (e) => {
        e.preventDefault()
        const email = localStorage.getItem('loginEmail');
        const data = {
            "verification_code": this.props.scanOtpData.otp,
            "email": email
        }
        this.props.dispatch(postOtp(data));
        if (this.props.postOtpError === false && this.props.postOtpMessage === "Email verification done") {
            localStorage.setItem('loginName', this.props.name)
            localStorage.setItem('loginEmail', this.props.email)
            localStorage.setItem('token', this.props.token)
        }
    }

    render() {
        const { scanOtpData, postOtpLoading, postOtpError } = this.props;
        return (
            <>
                <Header backgroundColor={"yellowHeader"} />
                <section>
                    <div class="form-background">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 col-sm-2 col-md-2 col-lg-2 image-leftside">
                                    <div class="form-inside-wrapper-imageSide">
                                        <div class="image-leftside-wrapper">
                                            <img src={Request_Demo_Page_Illustration_Object_Big_left} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-8 col-md-8 col-lg-8 otp-form">
                                    <div class="form-wrapper otp-form">
                                        <div class="form-heading">
                                            <h3>Have Trouble Logging in ?</h3>
                                            <p>Enter OTP</p>
                                            <p>We have sent you an OTP on the registered Email Address, please Enter the same</p>
                                        </div>
                                        <form onSubmit={this.formSubmitHandler.bind(this)}>
                                            <div class="form-field">
                                                <img src={noun_envelope} alt="" />
                                                <input type="number" name="otp" id="otp" placeholder="OTP"
                                                    value={scanOtpData.otp}
                                                    onChange={this.handleDataChange.bind(
                                                        this
                                                    )}
                                                />
                                            </div>
                                            <div>
                                                {!postOtpLoading ?

                                                    (<button type="submit"
                                                        id="submit"
                                                        formNoValidate={true}
                                                    >Sign Up</button>)
                                                    :
                                                    (<button>
                                                        <i className="fa fa-spin fa-spinner btn-icon"></i>
                                                        Please wait ..
                                                    </button>)
                                                }

                                            </div>
                                            {!postOtpLoading && postOtpError &&
                                                <div className="center-error form-error">
                                                    <p>Invalid Otp</p>
                                                </div>
                                            }

                                        </form>


                                    </div>
                                </div>
                                <div class="col-12 col-sm-2 col-md-2 col-lg-2 image-rightside">
                                    <div class="form-inside-wrapper-imageSide">
                                        <div class="image-rightside-wrapper">
                                            <img src={Request_Demo_Page_Illustration_Object_small_right} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

function mapStateToProps(state) {
    const { data, scanOtpData, postOtpLoading, postOtpMessage, postOtpError, name, email, token } = state.otpReducer;
    return { data, scanOtpData, postOtpLoading, postOtpMessage, postOtpError, name, email, token }
}

export default withRouter(connect(mapStateToProps)(Otp));