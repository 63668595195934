import {
  CLEAR_SITE_SCAN,
  CLEAR_SITE_FINAL_MESSAGE,
  CLEAR_SITE_MESSAGE,
  CLEAR_SITE_URL,
  GET_SITE_SCAN_SUCCESS,
  POST_SITE_SCAN_FAILURE,
  POST_SITE_SCAN_REQUEST,
  POST_SITE_SCAN_SUCCESS,
  UPDATE_SITE_SCAN,
  UPDATE_SITE_SCAN_LOADING,
  GET_SITE_FREE_SCORE_REQUEST,
  GET_SITE_FREE_SCORE_SUCCESS,
  GET_SITE_FREE_SCORE_FAILURE,
  CLEAR_URL_VALIDATION_ERROR
} from "./constant";

const initialState = {
  scanData: {
    url: "",
    first_name: "",
    last_name: "",
    email: "",
  },
  loading: false,
  getUrl: "",
  getContactData: {
    name: "",
    email: "",
    url: "",
    first_name: "",
    last_name: "",
  },
  contactData: {
    name: "",
    email: "",
    url: "",
    first_name: "",
    last_name: "",
  },
  high: 0,
  resultFinalMessage: "",
  emailSecurityLoader: false,
  httpSecurityLoader: false,
  networkSecurityLoader: false,
  websiteSecurityLoader: false,
  getWebsiteSecurity: [],
  getEmailSecurity: [],
  getHttpSecurity: [],
  highData: 0,
  lowData: 0,
  mediumData: 0,
  getNetworkSecurity: [],
  postScanMessage: "",
  postScanScore: 0,
  id: "",
  postScanError: false,
  postScanLoading: false,
  postContactMessage: "",
  postContactError: false,
  postContactLoading: false,
  scanId: "",
  getScanData: [],
  getScanError: false,
  getScanLoading: false,
  freeScore: {},
  getFreeScoreLoading: false,
  getFreeScoreError: "",
  isAuthenticated: localStorage.getItem('token') ? true : false,
};

export default function scoreReducer(state = initialState, action) {
  switch (action.type) {
    case POST_SITE_SCAN_REQUEST:
      localStorage.removeItem("scanId")
      return Object.assign({}, state, {
        postScanMessage: "",
        postScanError: false,
        postScanLoading: true,
      });
    case POST_SITE_SCAN_SUCCESS:

      return Object.assign({}, state, {

        postScanMessage: 'The information has been submitted. Please wait for the result.',
        postScanError: false,
        postScanLoading: false,
        scanId: action.response.data.data.uid,
        getUrl: action.response.data.data.url,
        getContactData: action.response.data.info,

      });

    case POST_SITE_SCAN_FAILURE:
      return Object.assign({}, state, {
        postScanMessage: 'Failed to submit the information.Please Recheck.',
        postScanError: true,
        postScanLoading: false,
      });
    case GET_SITE_FREE_SCORE_REQUEST:
      return Object.assign({}, state, {
        getScanError: false,
        emailSecurityLoader: state.emailSecurityLoader || "true",
        httpSecurityLoader: state.httpSecurityLoader || "true",
        networkSecurityLoader: state.networkSecurityLoader || "true",
        websiteSecurityLoader: state.websiteSecurityLoader || "true",
      });
    case GET_SITE_SCAN_SUCCESS:
      return Object.assign({}, state, {
        getScanLoading: false,
        getScanError: false,
        emailSecurityLoader: action.response.data.email_security_loader,
        httpSecurityLoader: action.response.data.http_security_loader,
        networkSecurityLoader: action.response.data.network_security_loader,
        websiteSecurityLoader: action.response.data.website_security_loader,
        getWebsiteSecurity: action.response.data.website_security,
        getEmailSecurity: action.response.data.email_security,
        getHttpSecurity: action.response.data.http_security,
        getNetworkSecurity: action.response.data.network_security,
        postScanScore: action.response.data.score,
        high: action.respose.data.high,
        id: action.response.data.id
      });
    case GET_SITE_FREE_SCORE_FAILURE:
      return Object.assign({}, state, {
        getScanLoading: false,
        getScanError: true,
        getScanData: {},
        emailSecurityLoader: "false",
        httpSecurityLoader: "false",
        networkSecurityLoader: "false",
        websiteSecurityLoader: "false",

      });
    //scan url
    case UPDATE_SITE_SCAN:
      return Object.assign({}, state, {
        scanData: action.data,
        contactData: action.data,
      });
    //Main score loading  
    case UPDATE_SITE_SCAN_LOADING:
      return Object.assign({}, state, {
        loading: action.data,
      });
    case GET_SITE_FREE_SCORE_SUCCESS:
      return Object.assign({}, state, {
        highData: action.data.high,
        lowData: action.data.low,
        mediumData: action.data.medium,
        resultFinalMessage: action.data.message,
        emailSecurityLoader: action.data.email_security_loader,
        httpSecurityLoader: action.data.http_security_loader,
        networkSecurityLoader: action.data.network_security_loader,
        websiteSecurityLoader: action.data.website_security_loader,
        getWebsiteSecurity: action.data.website_security,
        getEmailSecurity: action.data.email_security,
        getHttpSecurity: action.data.http_security,
        getNetworkSecurity: action.data.network_security,
        postScanScore: action.data.score,
        id: action.data.id,
        high: action.data.high
      })
    case CLEAR_SITE_MESSAGE:
      return Object.assign({}, state, {
        resultFinalMessage: '',
        getUrl: "",
        getScanData: [],
        getWebsiteSecurity: [],
        getEmailSecurity: [],
        getHttpSecurity: [],
        getNetworkSecurity: [],
        scanData: "",
        postScanMessage: "",
        postContactMessage: "",
      });
    case CLEAR_SITE_SCAN:
      return Object.assign({}, state, {
        postScanMessage: "",
        postContactMessage: "",
        postScanError: false,
        
      });
    case CLEAR_URL_VALIDATION_ERROR:
      return Object.assign({}, state, {
      scanData: {
        url: "",
      }
    });
    case CLEAR_SITE_FINAL_MESSAGE:
      return Object.assign({}, state, {
        resultFinalMessage: "",
        getWebsiteSecurity: "",
        getEmailSecurity: "",
        getHttpSecurity: "",
        getNetworkSecurity: "",
      });
    case CLEAR_SITE_URL:
      return Object.assign({}, state, {
        postScanMessage: "",
        postContactMessage: "",
        scanData: {
          url: "",
          first_name: "",
          last_name: "",
          email: "",
        },
        getUrl: "",

      });
    default:
      return state;
  }
}
