import './dist/style.css';
import './dist/delete-popup.css';
import './dist/rescan-popup.css';
import { NotificationManager } from 'react-notifications';
import React from "react";

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import HeaderDashboard from "../header";
import { websiteURL } from '../../../common/ApiAction';
import { updateLoading } from '../checkScore/action';
import { deleteProjectList, getIntegration, selectProject } from '../action';

const WEBSITE_URL = websiteURL()
class Projects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            workspace: ''
        }

        this.handleDetailReport = this.handleDetailReport.bind(this);
        this.handleDeleteProjectTabCloseWithApi=this.handleDeleteProjectTabCloseWithApi.bind(this)
    }

    componentDidMount() {
        document.addEventListener('click', this.handleOutsideClick)
    }
    handleScanAgain(e) {
        e.preventDefault();
        if (this.props.postScanError === false) {
            this.props.history.push('/layout/checkScore')
            this.props.dispatch(updateLoading(true));
        }
    }
    handleOutsideClick = (event) => {
        let dropdown = document.querySelector(".project-tab-selections-side");
        let isClickInside = dropdown?.contains(event.target);
        if (!isClickInside && document.querySelector(".project-tab-selections-side")?.classList.contains('porject-tab-heading-filters-popup-show')) {
            dropdown.classList.remove("porject-tab-heading-filters-popup-show");
        }
    };
    addProduct() {
        this.props.history.push('/layout/integration')
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick)
    }

    handleShowHamPopup(e) {
        let parent = e.target.closest('.project-tab-list-project-hamburger');
        parent.classList.toggle('is-activeHamburger');
    }
    handleBranchChange(e) {
        let parent = e.target.closest('.project-tab-list-outer');
        let div = parent.querySelector('.project-tab-hidden-branch-change');
        div.classList.toggle('project-tab-hidden-branch-change-show');
        parent.querySelector('.project-tab-list-project-hamburger').classList.remove('is-activeHamburger');

    }


    handleDetailReport(e, index) {
        if (this.props.postScanError === false) {
            this.props.dispatch(selectProject(e.request_info.uid))
            // this.checkFreeScoreSSE(this.props.scanId)
            this.props.history.push('/layout/checkScoreResult')
        }
    }

    async handleDeleteProjectTabCloseWithApi(e, info) {
        let parent = e.target.closest('.project-tab-list-outer');
        let div = parent.querySelector('.delete-project-list-popup-wrapper');
        div.classList.remove('delete-project-list-popup-wrapper-show');
        // this.setState({ workspace:e.target.value })
        let body = e.target.value;
        let id = info.request_info.uid;
        await this.props.dispatch(deleteProjectList(id,body));
        if (this.props.delete_error === false) {
            this.props.dispatch(getIntegration(localStorage.getItem('workspace_id')))
            NotificationManager.success('Project deleted successfully');
        }
        else {
            NotificationManager.error('Pleasse try again');
        }

    }
    handleDeleteProjectTab(e) {
        let parent = e.target.closest('.project-tab-list-outer');
        let div = parent.querySelector('.delete-project-list-popup-wrapper');
        div.classList.toggle('delete-project-list-popup-wrapper-show');
        parent.querySelector('.project-tab-list-project-hamburger').classList.remove('is-activeHamburger');
    }
    handleDeleteProjectTabClose(e) {
        let parent = e.target.closest('.project-tab-list-outer');
        let div = parent.querySelector('.delete-project-list-popup-wrapper');
        div.classList.remove('delete-project-list-popup-wrapper-show');
    }

    handleRescanProjectTab(e) {
        let parent = e.target.closest('.project-tab-list-outer');
        let div = parent.querySelector('.rescan-project-list-popup-wrapper');
        div.classList.toggle('rescan-project-list-popup-wrapper-show');
        parent.querySelector('.project-tab-list-project-hamburger').classList.remove('is-activeHamburger');
    }
    handleRescanProjectTabClose(e) {
        let parent = e.target.closest('.project-tab-list-outer');
        let div = parent.querySelector('.rescan-project-list-popup-wrapper');
        div.classList.remove('rescan-project-list-popup-wrapper-show');
    }
    handleChangeBranch(e) {
        let parent = e.target.closest('.project-tab-list-outer');
        let div = parent.querySelector('.project-tab-hidden-branch-change');
        div.classList.remove('project-tab-hidden-branch-change-show');
    }
    color(e) {
        let parent = e.target.closest('.project-tab-hidden-branch-change');
        let div = parent.querySelector('#branch-select');
        div.classList.add("colorchange");
    }

    render() {
        function showFilterPopup() {
            document.querySelector('.project-tab-selections-side').classList.toggle('porject-tab-heading-filters-popup-show');
            let parent = document.getElementsByClassName('project-tab-list-project-hamburger');
            for (let i = 0; i < parent.length; i++) {
                parent[i].classList.remove('is-activeHamburger');
            }
        }
        function deselcts() {
            let ele = document.getElementsByClassName('styled-checkbox');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = false;
            }
        }
        const {
            project_list,
            postScanLoading,
            delete_error
        } = this.props;
        return (
            <>
                <HeaderDashboard />
                <div className="layout-wrapper">

                    <div className="container extra-margin">
                        <div className='projects-wrapper'>
                            <div className='projects-tab-heading'>
                                <div>
                                    <h2>Projects</h2>
                                </div>
                                <div className='project-tab-selections-side'>
                                    <button
                                        type="button"
                                        onClick={this.addProduct.bind(this)}
                                    >
                                        Add Project</button>
                                    {/* <div className='project-heading-filters-side' onClick={showFilterPopup}>
                                        <p>         Filters</p>
                                        <img src={require('./dist/down_arrow.png')} width="35" alt="dropdown arrow" />
                                    </div> */}
                                    {/* <div className='porject-tab-heading-filters-popup'>
                                        <form>
                                            <div class="form-group">
                                                <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" />
                                                <label for="styled-checkbox-1">View All</label>
                                            </div>
                                            <div class="form-group">
                                                <input class="styled-checkbox" id="styled-checkbox-2" type="checkbox" value="value2" />
                                                <label for="styled-checkbox-2">Website</label>
                                            </div>
                                            <div class="form-group">
                                                <input class="styled-checkbox" id="styled-checkbox-3" type="checkbox" value="value3" />
                                                <label for="styled-checkbox-3">Docker</label>
                                            </div>
                                            <div class="form-group">
                                                <input class="styled-checkbox" id="styled-checkbox-4" type="checkbox" value="value4" />
                                                <label for="styled-checkbox-4">Github</label>
                                            </div>
                                            <div class="form-group">
                                                <input class="styled-checkbox" id="styled-checkbox-5" type="checkbox" value="value5" />
                                                <label for="styled-checkbox-5">Vulnerability High to low</label>
                                            </div>
                                            <div class="form-group">
                                                <input class="styled-checkbox" id="styled-checkbox-6" type="checkbox" value="value6" />
                                                <label for="styled-checkbox-6">Vulnerability low to high</label>
                                            </div>
                                        </form>
                                        <div className='filters-selection-option'>
                                            <button>Apply</button>
                                            <button onClick={deselcts}>Clear All</button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className='projects-tab-list-wrapper'>
                                {project_list.length > 0 &&
                                    project_list.map((info, index) => (
                                        <div className='project-tab-list-outer' key={index}>
                                            <div className='projects-tab-list'>
                                                <div className='project-tab-list-project-icon'>
                                                    <img src={require('./dist/website_scan.svg')} width="80" alt="project icon" />
                                                </div>
                                                <div className='project-tab-list-project-name' value={info.request_info.workspace}>
                                                    <h3>{info.request_info.project_name}</h3>
                                                    <p>{info.request_info.status}</p>
                                                </div>
                                                <div className='project-tab-list-project-scanning-points'>
                                                    <p><span className='color-critical'>{info.critical} </span> Critical</p>
                                                    <p><span className='color-high'>{info.high} </span> High</p>
                                                    <p><span className='color-medium'>{info.medium} </span> Medium</p>
                                                    <p><span className='color-low'>{info.low} </span> Low</p>
                                                </div>
                                                <div className='project-tab-list-project-hamburger'>
                                                    <div class="hamburger" id="hamburger-6"
                                                        onClick={this.handleShowHamPopup.bind(
                                                            this
                                                        )}>
                                                        <span class="line"></span>
                                                        <span class="line"></span>
                                                        <span class="line"></span>
                                                    </div>
                                                    <div className='project-tab-list-hamburger-popup'>
                                                        <ul>
                                                            <li
                                                                onClick={this.handleDeleteProjectTab.bind(
                                                                    this
                                                                )}>
                                                                <p>Delete</p>
                                                            </li>
                                                            <li
                                                                onClick={this.handleRescanProjectTab.bind(
                                                                    this
                                                                )}>
                                                                <p>Scan Again</p>
                                                            </li>
                                                            {/* <li
                                                        onClick={this.handleBranchChange.bind(
                                                            this 
                                                        )}>
                                                        <p>Branch Change</p>
                                                    </li> */}
                                                            <li onClick={() => this.handleDetailReport(
                                                                info, index
                                                            )}>
                                                                <p>Detailed Report</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='project-tab-hidden-branch-change'>
                                                <select name="branch" id="branch-select" onChange=
                                                    {this.color.bind(
                                                        this
                                                    )}>
                                                    <option value="" selected="true" disabled="disabled">Enter branch name or change tag name</option>
                                                    <option value="github-repo">Github Repo</option>
                                                    <option value="second-branch">Second Branch</option>
                                                </select>
                                                <button>Submit and scan again</button>
                                                <p
                                                    onClick={this.handleChangeBranch.bind(
                                                        this
                                                    )}>Cancel</p>
                                            </div>
                                            <div className='delete-project-list-popup-wrapper'>
                                                <div className='delete-project-popup-inside'>
                                                    <div className='delete-popup-close-button'>
                                                        <img src={require('./dist/close.png')} height="20" width="20" alt="close"
                                                            onClick={this.handleDeleteProjectTabClose.bind(
                                                                this
                                                            )} />
                                                    </div>
                                                    <div className='delete-popup-description-side' >
                                                        <img src={require('./dist/website_scan.svg')} height="100" width="100" alt="Project name" />
                                                        <p>Are you sure you want to delete ?</p>
                                                        <span>Delete project {info.request_info.project_name} | {info.request_info.status}</span>
                                                        <button value={info.request_info.workspace} onClick={(e) => this.handleDeleteProjectTabCloseWithApi(
                                                            e,info
                                                        )}>Confirm Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='rescan-project-list-popup-wrapper'>
                                                <div className='rescan-project-popup-inside'>
                                                    <div className='rescan-popup-close-button'>
                                                        <img src={require('./dist/close.png')} height="20" width="20" alt="close"
                                                            onClick={this.handleRescanProjectTabClose.bind(
                                                                this
                                                            )} />
                                                    </div>
                                                    <div className='rescan-popup-description-side'>
                                                        <img src={require('./dist/project-docker.svg')} height="100" width="100" alt="Project name" />
                                                        <p>{info.request_info.project_name} </p>
                                                        {postScanLoading === true && (
                                                            <div className='scanning-loader'>
                                                                <div class="spinner-7"></div>
                                                                Fetching Score
                                                            </div>)}
                                                        {/* <button>View Detailed Report</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}


                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
    const { scanWebsiteData } = state.websiteScanReducer;
    const { postScanError, scanId, postScanLoading } = state.scoreReducer;
    const { project_list, delete_error, delete_message } = state.getScoreReducer;
    return { delete_error, delete_message, scanWebsiteData, project_list, postScanLoading, postScanError, scanId };
}
export default withRouter(connect(mapStateToProps)(Projects));
