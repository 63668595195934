import './dist/style.css';
import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import HeaderDashboard from "../header";


class NoProject extends React.Component {

    render() {

        return (
            <>
                <HeaderDashboard />
                <div className="layout-wrapper">
                    <div className="container extra-margin">
                        <div className='no-project-wrapper'>
                            <div className='row justify-content-center'>
                                <div className='col-12 col-sm-4 col-md-6 col-lg-6'>
                                    <div className='no-project-imageside'>
                                        <img src={require('../../checkFreeScore/Footer/dist/cfs-popup-image.svg')}  alt="No Project"/>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6 col-md-6 col-lg-6'>
                                    <div className='no-project-text-side'>
                                        <h2>No projects stats to show. Go to the Integration and add a project.</h2>
                                        <button>Add project</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    const { scanWebsiteData } = state.websiteScanReducer;
    return { scanWebsiteData };
}
export default withRouter(connect(mapStateToProps)(NoProject));