import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import './dist/style.css';
import '../dist/background.css';
import { resetPasswordInvite, updateForgotPasswordForm, resetErrorMessage, resetPasswordData } from './actions'
import Request_Demo_Page_Illustration_Object_Big_left from '../dist/Request Demo Page_Illustration-Object-Big-left.svg';
import noun_envelope from '../dist/noun_envelope_4125604.svg';
import Request_Demo_Page_Illustration_Object_small_right from "../dist/Request Demo Page_Illustration-Object-small-right.svg";
import { baseApi } from '../../../common/ApiAction';
import Header from '../header/index';
import Footer from '../../checkFreeScore/Footer/index';

const BASE_API = baseApi();
class ForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.formRef = null;
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
        let name = e.target.name;
        let value = e.target.value;
        this.setState({ [name.name]: value });
        const newState = Object.assign(this.props.forgotPasswordData, {
            [name]: value,
        });
        this.props.dispatch(resetErrorMessage())
        this.props.dispatch(updateForgotPasswordForm(newState))
        if (e.target.checkValidity()) {
            e.target.classList.remove('has-error');
            e.target.nextElementSibling.classList.remove('has-error');
        } else {
            e.target.classList.add('has-error');
            e.target.nextElementSibling.classList.add('has-error');
        }
    }
    handleOnSubmit(e) {
        //if form is valid then submit the data, else show the validation message on the invalid field.
        e.preventDefault();
        if (e.target.checkValidity()) {
            this.props.dispatch(resetPasswordInvite(this.props.forgotPasswordData));
        } else {
            let allInvalidInput = document.querySelectorAll(".form-group .form-control:invalid");
            if (allInvalidInput.length > 0) {
                allInvalidInput[0].focus();
                for (let i = 0; i < allInvalidInput.length; i++) {
                    allInvalidInput[i].parentElement.parentElement.classList.add(
                        "has-error"
                    );
                }
            }
        }
        this.formRef.reset();
    }
    componentDidMount() {
        this.props.dispatch(resetPasswordData())
        if (this.props.isAuthenticated) {
            // this.props.history.push(`${BASE_API}/v1/layout`)
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.isAuthenticated) {
            if (nextProps.redirectLink !== '') {
                window.location.replace(nextProps.redirectLink);
            } else {
                //this.props.history.push(`${BASE_API}/v1/layout`)
            }
        }
    }


    render() {
        const { forgotPasswordData, forgotInviteSuccessMsg, forgotInviteErrorMsg } = this.props;
        return (
            <>
                <Header backgroundColor={"yellowHeader"} />
                <section>
                    <div class="form-background">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 col-sm-2 col-md-2 col-lg-2 image-leftside">
                                    <div class="form-inside-wrapper-imageSide">
                                        <div class="image-leftside-wrapper">
                                            <img src={Request_Demo_Page_Illustration_Object_Big_left} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-8 col-md-8 col-lg-8 forgotpassword-form">
                                    <div class="form-wrapper">
                                        <div class="form-heading">
                                            <h3>Have Trouble Logging in ?</h3>
                                            <p>Enter the email address associated with your email account, then click "Next". We will email you a link to reset your password.</p>
                                        </div>
                                        <form className="UserLoginForm" ref={(ref) => this.formRef = ref}
                                            noValidate={true} id="UserLoginForm" onSubmit={this.handleOnSubmit.bind(this)}>
                                            <div class="form-field">
                                                <img src={noun_envelope} alt="" />
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="login-email"
                                                    placeholder="Email"
                                                    pattern={"([A-Za-z0-9._%+-])+@([a-zA-Z0-9.-]{2,})\\.+[a-zA-Z]{2,}"}
                                                    value={forgotPasswordData.email}
                                                    onChange={this.handleChange.bind(this)}
                                                />
                                                <span
                                                    class="errors"
                                                    id="email-error"
                                                >
                                                    Please enter an email address
                                                </span>
                                            </div>
                                            <input type="submit" id="submit" value="Send Recovery Link"
                                            />
                                            {forgotInviteErrorMsg &&
                                                <div className="center-error form-error">
                                                    <p>{forgotInviteErrorMsg}</p>
                                                </div>
                                            }
                                            {forgotInviteSuccessMsg &&
                                                <div className="center-error form-error successMessage">
                                                    <p>{forgotInviteSuccessMsg}</p>
                                                </div>
                                            }

                                        </form>

                                        <div class="login-link">
                                            <NavLink to="/auth/login">Go back to login</NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-2 col-md-2 col-lg-2 image-rightside">
                                    <div class="form-inside-wrapper-imageSide">
                                        <div class="image-rightside-wrapper">
                                            <img src={Request_Demo_Page_Illustration_Object_small_right} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

function mapStateToProps(state) {
    const { data, forgotPasswordData, forgotInviteSuccessMsg, forgotInviteErrorMsg } = state.forgotPassReducer;
    return { data, forgotPasswordData, forgotInviteSuccessMsg, forgotInviteErrorMsg }
}

export default withRouter(connect(mapStateToProps)(ForgotPassword));