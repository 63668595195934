import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './dist/style.css';
import './dist/discuss-need-form.css';
import facebook from './dist/facebook.svg';
import linkedin from './dist/linkedin.svg';
import twitter from './dist/twitter.svg';
import { websiteURL } from '../../../common/ApiAction/index'
const WEBSITE_URL = websiteURL();

const validEmailRegex = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const validPhoneRegex = RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
const validNameRefex = RegExp(/^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{1,}|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{3,}\s{1}[a-zA-Z0-9]{1,})$/);

const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

function checkEmptyField() {
    let username = document.getElementById('fullName');
    let email = document.getElementById('email');
    let company = document.getElementById('companyname');
    let job = document.getElementById('jobtitle');
    let lastname = document.getElementById('lastname');

    if (username.value == '') {
        return false
    }
    else if (email.value == '') {
        return false
    }
    else if (company.value == '') {
        return false
    }
    else if (job.value == '') {
        return false
    }
    else if (lastname.value == '') {
        return false
    }
    else {
        return true;
    }
}

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: null,
            email: null,
            lastname: null,
            companyname: null,
            jobtitle: null,
            number: null,
            message: null,
            errors: {
                fullName: '',
                email: '',
                lastname: '',
                companyname: '',
                jobtitle: '',
                number: '',
                message: '',
            }
        };
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;

        switch (name) {
            case 'fullName':
                errors.fullName =
                validNameRefex.test(value)
                ? ''
                : 'First Name is not valid!';
                break;
            case 'lastname':
                errors.lastname =
                validNameRefex.test(value)
                ? ''
                : 'Last Name is not valid!';
                break;
            case 'email':
                errors.email =
                    validEmailRegex.test(value)
                        ? ''
                        : 'Email is not valid!';
                break;
            case 'companyname':
                errors.companyname =
                validNameRefex.test(value)
                ? ''
                : 'Company Name is not valid!';
                break;
            case 'jobtitle':
                errors.jobtitle =
                validNameRefex.test(value)
                ? ''
                : 'Job Title is not valid!';
                break;
            case 'number':
                errors.number =
                    validPhoneRegex.test(value)
                        ? ''
                        : 'Number is not valid!';
                break;
            case 'message':
                errors.message =
                validNameRefex.test(value)
                ? ''
                : 'Message is not valid!';
                break;
            default:
                break;
        }

        this.setState({ errors, [name]: value });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm(this.state.errors) && checkEmptyField()) {
            //hubspot API calling
            let username = document.getElementById('fullName').value;
            let lastname = document.getElementById('lastname').value;
            let email = document.getElementById('email').value;
            let message = document.getElementById('message').value;
            let company = document.getElementById('companyname').value;
            let job = document.getElementById('jobtitle').value;
            let number = document.getElementById('number').value;

            let formFields = [
                {
                    "name": "firstname",
                    "value": `${username}`
          
                },
                {
                    "name": "lastname",
                    "value": `${lastname}`
                },
                {
                    "name": "email",
                    "value": `${email}`
                },
                {
                    "name": "company",
                    "value": `${company}`
                },
                {
                    "name": "jobtitle",
                    "value": `${job}`
                },
                {
                    "name": "phone",
                    "value": `${number}`
                },
                {
                    "name": "discuss_your_needs",
                    "value": `${message}`
                }
            ]
            var xhr = new XMLHttpRequest();
            //Change the portal id and form Id for final integration.
            var url = 'https://api.hsforms.com/submissions/v3/integration/submit/24998068/e5a35194-da2a-41ba-be56-e9a976da7f2d';


            var data = {
                "fields": formFields,
                "legalConsentOptions": {
                    "consent": {
                        "consentToProcess": true,
                        "text": "I agree to allow Example Company to store and process my personal data.",
                        "communications": [
                            {
                                "value": true,
                                "subscriptionTypeId": 999,
                                "text": "I agree to receive marketing communications from Example Company."
                            }
                        ]
                    }
                },
                "context": {
                    "pageUri": "https://www.xenonstack.com/",
                    "pageName": "Xenonstack"
                },

            }
            var final_data = JSON.stringify(data);



            xhr.open('POST', url);
            xhr.setRequestHeader('Content-Type', 'application/json');

            //Need to remove or comment when integrated finally.
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4 && xhr.status == 200) {
                    document.querySelector('.modal_container-footer').style.display = "none";
                    document.querySelector('.thank-you-message').classList.add('thank-you-message-show');
                    document.querySelector('.dicuss-invite-modal').style.width = "unset";
                    // Returns a 200 response if the submission is successful.
                } else if (xhr.readyState == 4 && xhr.status == 400) {
                    alert(xhr.responseText); // Returns a 400 error the submission is rejected.          
                } else if (xhr.readyState == 4 && xhr.status == 403) {
                    alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.           
                } else if (xhr.readyState == 4 && xhr.status == 404) {
                    alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found     
                }
            }

            xhr.send(final_data);
        }
    }

    render() {
        const { errors } = this.state;

        function openDiscussModal() {
            document.querySelector(".discuss-modal-wrapper-footer").style.display = "grid";
        }
        function closeDiscussModal() {
            document.querySelector(".discuss-modal-wrapper-footer").style.display = "none";
            document.querySelector('.formparent').reset();
            document.querySelector('.modal_container-footer').style.display = "flex";
            document.querySelector('.thank-you-message').classList.remove('thank-you-message-show');
            document.querySelector('.dicuss-invite-modal').style.width = "90%";
        }

        return (
            <footer className="footer-wrapper">
                <div class="footer-items">
        <div class="container">
          <div class="footer_heading">
            <h2>Let's Discuss your Requirements.</h2>
            <p>Every Big Idea Starts with Small Step</p>
            <a href="https://www.xenonstack.com/contact-us/"
              ><button class="new-banner-button">
                <span>GET IN TOUCH</span>
              </button></a
            >
          </div>
          <div class="row">
            <div class="heading col-sm-12 col-md-12 col-12 col">
              <div class="main_list">
                <div class="sub_list">
                  <h3>Platform</h3>
                  <ul class="footer_ul">
                    <li class="list">
                      <a href="https://www.xenonstack.com/enterprise/cloud-native-security/">Cloud Native Security</a>
                    </li>
                    <li class="list">
                      <a href="https://www.xenonstack.com/enterprise/vulnerability-management/">Vulnerability Management</a>
                    </li>
                    <li class="list">
                      <a href="https://www.xenonstack.com/enterprise/compliance-management/">Compliance Management</a>
                    </li>
                  </ul>
                  <div class="Assessments-footer">
                    <h3>Assessments</h3>
                    <ul class="footer_ul">
                      <li class="list"><a href="https://www.xenonstack.com/readiness-assessment/cloud-security">Website Scanner</a></li>
                      <li class="list">
                        <a href="https://www.xenonstack.com/readiness-assessment/cloud-security">Cloud Security Assessment</a>
                      </li>
                      <li class="list">
                        <a href="https://www.xenonstack.com/readiness-assessment/cloud-security">Compliance Risk Assessment</a>
                      </li>
                      <li class="list">
                        <a href="https://www.xenonstack.com/readiness-assessment/data-protection/ ">Data Protection Impact Assessment</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="sub_list">
                  <h3>Solutions</h3>
                  <ul class="footer_ul">
                    <li class="list"><a href="https://www.xenonstack.com/enterprise/container-security/">Container Security</a></li>
                    <li class="list"><a href="https://www.xenonstack.com/technology-consulting/kubernetes-security/">Kubernetes Security</a></li>
                    <li class="list"><a href="https://www.xenonstack.com/enterprise/application-security/">Application Security</a></li>
                    <li class="list"><a href="https://www.xenonstack.com/enterprise/serverless-security/">Serverless Security</a></li>
                    <li class="list"><a href="https://www.xenonstack.com/enterprise/infrastructure-as-code-security/">IAC Security</a></li>
                  </ul>
                </div>
                <div class="sub_list">
                  <h3>Use Cases</h3>
                  <ul class="footer_ul">
                    <li class="list"><a href="https://www.xenonstack.com/enterprise/code-security/">Code Security</a></li>
                    <li class="list"><a href="https://www.xenonstack.com/enterprise/continuous-security/">Continuous Security</a></li>
                    <li class="list"><a href="https://www.xenonstack.com/services/devops/  ">Automate DevOps</a></li>
                    <li class="list"><a href="https://www.xenonstack.com/technology-consulting/aws-cloud-security/ ">AWS Cloud Security</a></li>
                    <li class="list"><a href="https://www.xenonstack.com/technology-consulting/azure-cloud-security/">Azure Security</a></li>
                    <li class="list"><a href="https://www.xenonstack.com/technology-consulting/google-cloud-security/">Google Cloud Security</a></li>
                    <li class="list"><a href="https://www.xenonstack.com/on-prem-ai-cluster/  ">On Premises Security</a></li>
                  </ul>
                </div>
                <div class="sub_list">
                  <h3>Company</h3>
                  <ul class="footer_ul">
                    <li class="list"><a href="https://www.xenonstack.com/about-us">About us</a></li>
                    <li class="list"><a href="https://www.xenonstack.com/blog">Blogs</a></li>
                    <li class="list"><a href="https://www.xenonstack.com/contact-us/">Contact us</a></li>
                    <li class="list"><a href="https://www.xenonstack.com/careers/">Careers</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row footer-end">
            <div class="col-sm-9 copyright">
              <p>Copyright © 2021 All rights reserved. | <a href="https://www.xenonstack.com/privacy-policy/" class="text-link">Privacy
                Policy</a> |<a href="https://www.xenonstack.com/privacy-policy/" class="text-link"> Terms of Service</a></p>
            </div>
            <div class="col-sm-3">
              <ul class="social-icons-ul">
                <li><a href="https://www.facebook.com/metasoftsecure" target="_blank"> <img src={facebook} alt="facebook" srcset="" aria-label="facebook"/></a></li>
                <li class="middle-asset"><a href="https://twitter.com/metasoftsecure" target="_blank"> <img src={twitter} alt="twitter" srcset="" aria-label="Twitter"/> </a>
                </li>
                <li><a href="https://www.linkedin.com/showcase/metasecure/" target="_blank"> <img src={linkedin} alt="linkedin" srcset="" aria-label="linkedin"/></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
            </footer>
        );
    }
}

export default withRouter(connect(null)(Footer));

