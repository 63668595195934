export  const GET_WORKSPACE_SCAN_SUCCESS = "GET_WORKSPACE_SCAN_SUCCESS";
export const GET_WORKSPACE_SCAN_REQUEST = "GET_WORKSPACE_SCAN_REQUEST"
export const GET_WORKSPACE_SCAN_FAILURE = "GET_WORKSPACE_SCAN_FAILURE"

export const POST_WORKSPACE_SCAN_SUCCESS = "POST_WORKSPACE_SCAN_SUCCESS"
export const POST_WORKSPACE_SCAN_REQUEST = "POST_WORKSPACE_SCAN_REQUEST"
export const POST_WORKSPACE_SCAN_FAILURE = "POST_WORKSPACE_SCAN_FAILURE"

export  const GET_INTEGRATIONS_SCAN_SUCCESS = "GET_INTEGRATIONS_SCAN_SUCCESS";
export const GET_INTEGRATIONS_SCAN_REQUEST = "GET_INTEGRATIONS_SCAN_REQUEST"
export const GET_INTEGRATIONS_SCAN_FAILURE = "GET_INTEGRATIONS_SCAN_FAILURE"

export const CLEAR_WORKSPCAE_DATA = "CLEAR_WORKSPCAE_DATA"
export const CHECK_INTEGRATIONS_SCAN_REQUEST = "CHECK_INTEGRATIONS_SCAN_REQUEST"
export const CHECK_INTEGRATIONS_SCAN_SUCCESS = "CHECK_INTEGRATIONS_SCAN_SUCCESS"
export const CHECK_INTEGRATIONS_SCAN_FAILURE = "CHECK_INTEGRATIONS_SCAN_FAILURE"

export const DELETE_PROJECT_RESULT_REQUEST = "DELETE_PROJECT_RESULT_REQUEST";
export const DELETE_PROJECT_RESULT_SUCCESS = "DELETE_PROJECT_RESULT_SUCCESS";
export const DELETE_PROJECT_RESULT_FAILURE = "DELETE_PROJECT_RESULT_FAILURE";

export const POST_INVITE_USER_REQUEST = "POST_INVITE_USER_REQUEST";
export const POST_INVITE_USER_SUCCESS = "POST_INVITE_USER_SUCCESS";
export const POST_INVITE_USER_FAILURE = "POST_INVITE_USER_FAILURE";

export const DELETE_WORKSPACE_REQUEST = "DELETE_WORKSPACE_REQUEST";
export const DELETE_WORKSPACE_SUCCESS = "DELETE_WORKSPACE_SUCCESS";
export const DELETE_WORKSPACE_FAILURE = "DELETE_WORKSPACE_FAILURE";

export const WORKSPACE_USER_LIST_REQUEST = "WORKSPACE_USER_LIST_REQUEST";
export const WORKSPACE_USER_LIST_SUCCESS = "WORKSPACE_USER_LIST_SUCCESS";
export const WORKSPACE_USER_LIST_FAILURE = "WORKSPACE_USER_LIST_FAILURE";


export const PUT_USER_ROLE_REQUEST = "PUT_USER_ROLE_REQUEST";
export const PUT_USER_ROLE_SUCCESS = "PUT_USER_ROLE_SUCCESS";
export const PUT_USER_ROLE_FAILURE = "PUT_USER_ROLE_FAILURE";

export const GET_SELECTED_PROJECT_DETAIL_REPORT = "GET_SELECTED_PROJECT_DETAIL_REPORT"