
export  const POST_SITE_SCAN_REQUEST = "POST_SITE_SCAN_REQUEST";
export  const POST_SITE_SCAN_SUCCESS = "POST_SITE_SCAN_SUCCESS";
export  const POST_SITE_SCAN_FAILURE = "POST_SITE_SCAN_FAILURE";

export  const POST_CONTACT_REQUEST = "POST_CONTACT_REQUEST";
export  const POST_CONTACT_SUCCESS = "POST_CONTACT_SUCCESS";
export  const POST_CONTACT_FAILURE = "POST_CONTACT_FAILURE";

export  const GET_SITE_SCAN_REQUEST = "GET_SITE_SCAN_REQUEST";
export  const GET_SITE_SCAN_SUCCESS = "GET_SITE_SCAN_SUCCESS";
export  const GET_SITE_SCAN_FAILURE = "GET_SITE_SCAN_FAILURE";

export  const CLEAR_SITE_SCAN = "CLEAR_SITE_SCAN";
export  const CLEAR_SITE_MESSAGE = "CLEAR_SITE_MESSAGE";
export  const CLEAR_SITE_URL = "CLEAR_SITE_URL";
export const CLEAR_SITE_FINAL_MESSAGE = "CLEAR_SITE_FINAL_MESSAGE";

export const UPDATE_SITE_SCAN="UPDATE_SCAN";
export const UPDATE_SITE_SCAN_LOADING= "UPDATE_SCAN_LOADING";

export const GET_SITE_FREE_SCORE_REQUEST = 'GET_SITE_FREE_SCORE_REQUEST';
export const GET_SITE_FREE_SCORE_SUCCESS = 'GET_SITE_FREE_SCORE_SUCCESS';
export const GET_SITE_FREE_SCORE_FAILURE = 'GET_SITE_FREE_SCORE_FAILURE';

export const CLEAR_URL_VALIDATION_ERROR = "CLEAR_URL_VALIDATION_ERROR";




