import {
    REMOVE_OTP,POST_OTP_REQUEST, POST_OTP_SUCCESS, POST_OTP_FAILURE, UPDATE_SCAN
} from './constants';

const initialState = {

    scanOtpData: {
        otp: '',
    },
    data: [],
    postOtpMessage: "",
    postOtpError: false,
    postOtpLoading: false,
    isAuthenticated: localStorage.getItem('token') ? true : false,
    name: '',
    email: '',
    token: ''

}

export default function otpReducer(state = initialState, action) {
    switch (action.type) {
        case POST_OTP_REQUEST:
            return Object.assign({}, state, {
                postOtpMessage: "",
                postOtpError: false,
                postOtpLoading: true,
            });
        case POST_OTP_SUCCESS:
            return Object.assign({}, state, {
                postOtpMessage: action.response.data.message,
                postOtpError: false,
                postOtpLoading: false,
                data: action.payload,
                isAuthenticated: true,
                name: action.response.data.name,
                email: action.response.data.email,
                token: action.response.data.token
            });
        case POST_OTP_FAILURE:
            return Object.assign({}, state, {
                postOtpMessage: action.response.data.message,
                postOtpError: action.response.data.error,
                postOtpLoading: false,
            });

        case UPDATE_SCAN:
            return Object.assign({}, state, {
                scanOtpData: action.data,
            });
        case REMOVE_OTP:
            return Object.assign({}, state, {
                scanOtpData: {
                    otp: '',
                },
                data: [],
                postOtpMessage: "",
            });
        default:
            return state;
    }
}