import { baseApi, securityApi } from "../../../common/ApiAction";
import { GET_API } from "../../../middleware/symbols";
import {
    check_GITHUB_SCAN_SUCCESS,check_GITHUB_SCAN_REQUEST,check_GITHUB_SCAN_FAILURE,
GET_GITHUB_SCAN_SUCCESS,
GET_GITHUB_SCAN_REQUEST,
GET_GITHUB_SCAN_FAILURE,
GIT_CLEAR_STATE
} from './constant';

const BASE_URL = baseApi();
const SECURITY_API = securityApi();

export function getGitScan() {
    return {
        [GET_API]: {
            endpoint: SECURITY_API + '/v1/git-repos',
            types: [GET_GITHUB_SCAN_SUCCESS,GET_GITHUB_SCAN_REQUEST,GET_GITHUB_SCAN_FAILURE],
            authenticated: true,
            
                
        }
    }
    
}
export function checkGitScan() {
    return {
        [GET_API]: {
            endpoint: BASE_URL + '/v1/checkIntegrations',
            types: [check_GITHUB_SCAN_SUCCESS,check_GITHUB_SCAN_REQUEST,check_GITHUB_SCAN_FAILURE],
            authenticated: true,
            
                
        }
    }
    
}
export function clearState() {
    return {
        type: GIT_CLEAR_STATE
    }
}