import { RESET_PASSWORD_INVITE_REQUEST, 
    RESET_PASSWORD_INVITE_SUCCESS, 
    RESET_PASSWORD_INVITE_FAILURE,RESET_ERROR_MESSAGE ,
    UPDATE_FORGOT_PASSWORD_DATA ,
    RESET_FORGOTPASSWORD_DATA
} from './constants'

const initialState = {
    forgotPasswordData:{
        "state": "forgot",
        "email": "",
    },
    isPageLoading: '',
    forgotInviteSuccessMsg: '',
    forgotInviteErrorMsg: ''
}

export default function forgotPassReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_FORGOT_PASSWORD_DATA:
            return Object.assign({}, state, {
                forgotPasswordData: action.data,
            });


        case RESET_PASSWORD_INVITE_REQUEST: 
            return Object.assign({}, state, {
                isPageLoading: true, 
                forgotInviteSuccessMsg: '', 
                forgotInviteErrorMsg: ''
            });
        case RESET_PASSWORD_INVITE_SUCCESS:
            return Object.assign({}, state, {
                isPageLoading: false,
                forgotInviteSuccessMsg: action.response.data.error === false ? action.response.data.message : '',
                forgotInviteErrorMsg: action.response.data.error === true ? action.response.data.message : ''
            });
        case RESET_PASSWORD_INVITE_FAILURE:
            return Object.assign({}, state, {
                isPageLoading: false, 
                forgotInviteSuccessMsg: '', 
                forgotInviteErrorMsg: action.response.data.message
            });
        case RESET_ERROR_MESSAGE:
            return Object.assign({}, state, {
                forgotInviteErrorMsg: ''
            });
        case RESET_FORGOTPASSWORD_DATA:
            return Object.assign({}, state ,{
                forgotPasswordData:{
                    "state": "forgot",
                    "email": "",
                },
                isPageLoading: '',
                forgotInviteSuccessMsg: '',
                forgotInviteErrorMsg: ''
            })
        default:
            return state;
    }
}