import config from "../../config";

export function securityApi() {
    return config.app.REACT_APP_SECURITY_URL;
}
export function baseApi() {
    return config.app.REACT_APP_BASE_URL;
}

export function websiteURL() {
    return config.app.WEBSITE_LINK;
}
export function workspaceURL() {
    return config.app.REACT_APP_WORKSPACE_URL;
}
export function hubspotURL() {
    return config.app.HUBSPOT_BASE_URL;
}
