import React from "react";
import './dist/integration.css'
import './dist/modal.css'
import '../../checkFreeScore/checkFreeScore/dist/CSS/style.css';
import '../../checkFreeScore/checkFreeScore/dist/CSS/modal.css'
import '../../checkFreeScore/checkFreeScore/dist/CSS/formdetail.css'
import '../../checkFreeScore/checkFreeScore/dist/CSS/checkscoremodal.css'
import {
    clearFinalMessage,
    clearScan,
    getFreeScore,
    postScan,
    updateLoading,
    updateUrl,
    clearUrlError
} from '../checkScore/action';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import urlImage from './dist/form/url.svg';
import integration1 from './dist/Integration_01 illustartion Docker.svg';
import integration2 from './dist/Integration_02 illustartion Quay.svg';
import integration3 from './dist/Integration_03 illustartion GCR.svg';
import integration4 from './dist/Integration_04 illustartion ECR.svg';
import integration5 from './dist/Integration_05 illustartion Github.svg';
import integration6 from './dist/Integration_06 illustartion Dependency Scan.svg';
import integration7 from './dist/Integration_07 illustartion Check Free Score.svg';
import { baseApi, securityApi, websiteURL } from "../../../common/ApiAction";
import HeaderDashboard from "../header";
import { DotWaveLoader } from "react-inline-loaders";
import { checkGitScan } from "../githubChoose/action";
const BASE_API = baseApi();
let sse;
let nameTarget;
const WEBSITE_URL = websiteURL();
const SECURITY_API = securityApi();
class Integration extends React.Component {
    state = {
        urlLink: '',
    };

    checkFreeScoreSSE(id) {
        let parseData;
        sse = new EventSource(`${SECURITY_API}/v1/result/${id}`);
        sse.onmessage = (e) => {
            parseData = JSON.parse(e.data);
            this.props.dispatch(getFreeScore(parseData));
        };
        sse.onerror = () => {
            sse.close();
        };
        return () => {
            sse.close();
        };
    }
    componentDidMount() {
        const search = this.props.location.search;
        var link = new URLSearchParams(search).get('url');
        if (link) {
            let body = {
                url: link,

            };
            let workspaceId = localStorage.getItem('workspace_id')
            this.props.dispatch(postScan(body,workspaceId));

            this.setState({ urlLink: link });
            let checkedUrl = this.props.scanData;
            if (!/^(?:f|ht)tps?\:\/\//.test(this.props.scanData)) {
                checkedUrl = 'https://' + this.props.scanData;
            }
            const newState = Object.assign(this.props.scanData, {
                ['url']: checkedUrl,
            });
            this.props.dispatch(updateUrl(newState));
            this.props.dispatch(clearScan());
            if (localStorage.getItem('scanId')) {
                this.checkFreeScoreSSE(localStorage.getItem('scanId'));
            }
            this.props.dispatch(updateLoading(false));
        }
    }
    //Modal data change
    handleDataChange(e) {
        this.setState({ [e.target.name]: e.target.value });
        let name = e.target.name;
        let value = e.target.value;
        const newState = Object.assign(this.props.scanData, {
            [name]: value,
        });
        this.props.dispatch(updateUrl(newState));
        this.props.dispatch(clearScan());
        if (e.target.checkValidity()) {
            e.target.classList.remove('has-error');
            e.target.nextElementSibling.classList.remove('has-error');
        } else {
            e.target.classList.add('has-error');
            e.target.nextElementSibling.classList.add('has-error');
        }
    }
    //Close modal
    closeInviteModal() {
        let elementone = document.querySelectorAll('.has-error');
        [...elementone].forEach((i) => i.classList.remove('has-error'))
        if (nameTarget && nameTarget.classList) {
            nameTarget.classList.remove('has-error');
            nameTarget.nextElementSibling.classList.remove('has-error');
        }
        document.getElementById('urlForm-modal-i').classList.remove('urlForm-modal-show');
        this.props.dispatch(clearScan());
        this.props.dispatch(clearUrlError());
    }

    //Submit data
    async handleFormSubmit(event) {
        event.preventDefault();
        let checkedUrl = this.props.scanData.url;
        if (event.target.checkValidity()) {
            //check if http or https present or not
            if (!/^(?:f|ht)tps?\:\/\//.test(this.props.scanData.url)) {
                checkedUrl = 'https://' + this.props.scanData.url;
            }
            let body = {
                url: checkedUrl,
            };

            let workspaceId = localStorage.getItem('workspace_id')
            await this.props.dispatch(postScan(body,workspaceId));
            if (localStorage.getItem('scanId') && sse) {
                if (this.props.postScanError === false) {
                    sse.close();
                }
            }
            if (this.props.scanId) {
                localStorage.setItem('scanId', this.props.scanId);
            }
            if (this.props.postScanError === false) {
                this.props.history.push('/layout/checkScore')

                this.props.dispatch(clearFinalMessage());
                this.props.dispatch(updateLoading(true));
            }

        } else {

            event.target.parentElement.parentElement.classList.add('has-error');
            const invalidInputs = document.querySelectorAll('input:invalid');
            invalidInputs[0].focus();
            for (let i = 0; i < invalidInputs.length; i++) {
                invalidInputs[i].nextElementSibling.classList.add('has-error');
            }
        }

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.check_error === true) {

            window.location.replace(`${BASE_API}/v1/github/login?token=${localStorage.getItem('token')}&redirect=${window.location.origin}/layout/githubchoose`)
        } else
            if (nextProps.check_error === false) {

                this.props.history.push('/layout/githubchoose');
            }

        if (nextProps.postScanMessage !== '') {
            if (nextProps.postScanError === true) {
                //Do nothing   
            } else {
                this.setState({ urlLink: nextProps.getUrl });
                this.props.dispatch(clearScan());
                if (nextProps.scanId) {
                    if (this.props.postScanError === false && sse) {
                        sse.close();
                    }

                    this.checkFreeScoreSSE(nextProps.scanId);
                }
            }
        }
    }
    gitResult = () => {
        this.props.dispatch(checkGitScan())
    }

    render() {
        const {
            postScanLoading,
            scanData,
        } = this.props

        const handleUrlPopup = () => {
            document.getElementById('urlForm-modal-i').classList.toggle('urlForm-modal-show');
        }
        return (
            <>
                <HeaderDashboard />
                <div className="layout-wrapper">

                    <div className="container extra-margin">
                        <div className="image-section-wrapper">
                            <h2>Container Image Scan</h2>
                            <div className="row no-gutters image-section-wrapper-row">
                                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                                    <div className="box-wrapper">
                                        <div className="box-wrapper-inside">
                                            <div className="center">
                                                <img src={integration1} alt="demo" />
                                                <p>Docker Hub</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                                    <div className="box-wrapper">
                                        <div className="box-wrapper-inside">
                                            <div className="center">
                                                <img src={integration2} alt="demo" />
                                                <p>Quay</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                                    <div className="box-wrapper">
                                        <div className="box-wrapper-inside">
                                            <div className="center">
                                                <img src={integration3} alt="demo" />
                                                <p>GCR</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                                    <div className="box-wrapper">
                                        <div className="box-wrapper-inside">
                                            <div className="center">
                                                <img src={integration4} alt="demo" />
                                                <p>ECR</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="image-section-wrapper">
                            <h2>Codebase Scan</h2>
                            <div className="row no-gutters image-section-wrapper-row">
                                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                                    <div className="box-wrapper">
                                        <div className="box-wrapper-inside">

                                            <div className="center" onClick={this.gitResult.bind(this)} style={{ cursor: "pointer" }}>
                                                {/* <Route path="/gitresult" component={Githubchoose}/> */}

                                                <img src={integration5} alt="demo" />
                                                <p>GitHub</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                                    <div className="box-wrapper">
                                        <div className="box-wrapper-inside">

                                            <div className="center">
                                                <img src={integration6} alt="demo" />
                                                <p>Dependency Scan</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="image-section-wrapper">
                            <h2>Website Scan</h2>
                            <div className="row no-gutters image-section-wrapper-row">
                                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                                    <div className="box-wrapper">
                                        <div className="box-wrapper-inside">

                                            <div className="center" onClick={() => handleUrlPopup()} style={{ cursor: "pointer" }}>
                                                <img src={integration7} alt="demo" />
                                                <p>Check Free Score</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="urlForm-modal" id="urlForm-modal-i">
                                <div className="url-form-wrapper">
                                    <div className="row no-gutters url-form-wrapper-row row-reverse">
                                        <div className="col-12 col-sm-7 col-md-7 col-lg-6">
                                            <div className="url-form-inside">
                                                <h3>Enter URL to check Security Score</h3>
                                                <form
                                                    id="myForm"
                                                    className="modal_form"
                                                    onSubmit={this.handleFormSubmit.bind(this)}
                                                    noValidate={true}
                                                    autocomplete="off"
                                                >
                                                    <div className="form-field">
                                                        <img src={urlImage} alt="" />
                                                        <input
                                                            required
                                                            type="text"
                                                            name="url"
                                                            id="url"
                                                            placeholder="https://www.example.com"
                                                            value={scanData.url}
                                                            onChange={this.handleDataChange.bind(
                                                                this
                                                            )}
                                                            pattern="(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)"
                                                        />
                                                        <p class="errors">
                                                            Please provide valid url
                                                        </p>
                                                    </div>
                                                    {this.props.postScanError && (
                                                        <p class="errors-msg">
                                                            Website not found, URL is invalid
                                                        </p>
                                                    )}
                                                    {/*  MIGHT NEED IN FUTURE */}

                                                    {/* <div className="form-field">
                                                        <img src={projectType} alt="" />
                                                        <select>
                                                            <option value="0">PROJECT TYPE:</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                        </select>
                                                        <img src={downArrow} alt="" />
                                                        <p id="password-error" className="error"></p>
                                                    </div> */}
                                                    <a>
                                                        <input type="submit" id="submit" value="Get Results" className="get-result" />
                                                    </a>
                                                    <span>
                                                        {postScanLoading === true && (
                                                            <div
                                                                className="loader-container"
                                                                style={{
                                                                    width: '170%',
                                                                    justifyContent: 'flex-end',
                                                                    color: 'rgb(64, 146, 222)',
                                                                    position: 'relative',
                                                                    paddingTop: '3%',
                                                                    paddingLeft: '-18%',
                                                                    display: 'flex',
                                                                    alignItems: 'flex-start',
                                                                    flexDirection: 'row-reverse',
                                                                }}
                                                            >
                                                                Fetching Score
                                                                <DotWaveLoader
                                                                    style={{
                                                                        margin: '0 !important',
                                                                        width: '0 !important',
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    </span>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-5 col-md-5 col-lg-5 url-form-imageside">
                                        <img src="https://f.hubspotusercontent-eu1.net/hubfs/24902366/03-10-2021%20-%20Updated%20%20PopUp-01.svg" alt="Akirastack Modal Image" />
                                        </div>
                                    </div>
                                    <div className="modal-close" onClick={this.closeInviteModal.bind(this)}>&times;</div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    const {
        scanData,
        scanId,
        loading,
        getUrl,
        postScanMessage,
        postScanError,
        postScanLoading,
        resultFinalMessage,
        emailSecurityLoader,
        httpSecurityLoader,
        networkSecurityLoader,
        websiteSecurityLoader,
        getScanLoading,
        getWebsiteSecurity,
        getEmailSecurity,
        getHttpSecurity,
        getNetworkSecurity,
        getScanError,
        high,
        id,
        postScanScore,
        getContactData,
        postContactLoading,
        postContactMessage,
        postContactError,
        status_code,
    } = state.scoreReducer;
    const {
        git_repo_error,
        repo_count,
        repo_data,
        check_error
    } = state.gitScoreReducer;
    return {
        scanData,
        scanId,
        loading,
        getUrl,
        postScanMessage,
        postScanError,
        postScanLoading,
        resultFinalMessage,
        emailSecurityLoader,
        httpSecurityLoader,
        high,
        networkSecurityLoader,
        websiteSecurityLoader,
        getScanLoading,
        getWebsiteSecurity,
        getEmailSecurity,
        getHttpSecurity,
        getNetworkSecurity,
        getScanError,
        id,
        postScanScore,
        getContactData,
        postContactLoading,
        postContactMessage,
        postContactError,
        status_code,
        git_repo_error,
        repo_count,
        repo_data,
        check_error
    };



}

export default withRouter(connect(mapStateToProps)(Integration));
