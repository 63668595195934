import React, { Component } from 'react';
import './dist/style.css';
import { postSignUp, removeScan, updateScan } from './actions';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import Request_Demo_Page_Illustration_Object_Big_left from '../dist/Request Demo Page_Illustration-Object-Big-left.svg';
import google_g_2015 from '../dist/google-g-2015.svg';
import Octicons_mark_github from '../dist/Octicons-mark-github.svg';
import Request_Demo_Page_Illustration_Object_small_right from '../dist/Request Demo Page_Illustration-Object-small-right.svg';
import noun_Eye from '../dist/noun_Eye_497740.svg';
import noun_person from '../dist/noun_person_990839.svg';
import noun_envelope from '../dist/noun_envelope_4125604.svg';
import { baseApi } from "../../../common/ApiAction/index";
import Header from '../header/index';
import Footer from '../../checkFreeScore/Footer/index';

const BASE_API = baseApi();
class SignUp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
        };
    }

    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
    }
    componentDidMount() {
        this.props.dispatch(removeScan())
        let search = this.props.location.search;
        let link = new URLSearchParams(search).get('token')
        if (link !== '' ) {
            localStorage.setItem('token', link)
        }

        if (this.props.isAuthenticated) {
            //this.props.history.push('/layout')
        }
        let urlName = new URLSearchParams(search).get('name')
        let urlEmail = new URLSearchParams(search).get('email')
        if (urlName) {
            localStorage.setItem('loginName', urlName)
            localStorage.setItem('loginEmail', urlEmail)
        }

    }
    checkEmptyField() {
        let username = document.getElementById('firstname');
        let userlastname = document.getElementById('lastname');
        let userlogin = document.getElementById('login-password');
        let email = document.getElementById('signup-email');
        let userconfirmlogin = document.getElementById('conpassword');

        if (username.value == '') {
            return false
        }
        else if (userlastname.value == '') {
            return false
        }
        else if (userlogin.value == '') {
            return false
        }
        else if (email.value == '') {
            return false
        }
        else if (userconfirmlogin.value == '') {
            return false
        }
        else {
            return true;
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.postSignUpMessage !== '' && this.checkEmptyField()) {
            if (nextProps.postSignUpError === false) {
                if (nextProps.scanData.confirmpassword === nextProps.scanData.password) {
                    nextProps.history.push('/auth/otp')
                }
            }


        }
        let search = this.props.location.search;
        let link = new URLSearchParams(search).get('token')
        if (link) {
            localStorage.setItem('token', new URLSearchParams(search).get('token'))
            this.props.history.push('/layout')
  
        }
        else if (nextProps.isAuthenticated) {
            // this.props.history.push('/layout')
        }
        else {
            //DO NOTHING
        }
    }
    handleDataChange(e) {
        this.props.dispatch(removeScan())
        this.setState({ [e.target.name]: e.target.value });
        let name = e.target.name;
        let value = e.target.value;
        this.setState({ [name.name]: value });
        const newState = Object.assign(this.props.scanData, {
            [name]: value,
        });
        this.props.dispatch(updateScan(newState));

        if (e.target.checkValidity()) {
            e.target.classList.remove('has-error');
            e.target.nextElementSibling.classList.remove('has-error');
        } else {
            e.target.classList.add('has-error');
            e.target.nextElementSibling.classList.add('has-error');
        }
        if (name === 'confirmpassword') {
            if (
                this.props.scanData.password ===
                this.props.scanData.confirmpassword
            ) {
                e.target.classList.remove('has-error');
                e.target.nextElementSibling.classList.remove('has-error');
            } else {
                e.target.nextElementSibling.classList.add('has-error');
            }
        }
    }
    formSubmitHandler = (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {
            if (this.props.scanData.confirmpassword === this.props.scanData.password) {
                if (this.props.postSignUpError === false) {
                    this.props.dispatch(postSignUp(this.props.scanData));
                    localStorage.setItem('loginEmail', this.props.scanData.email)
                }
            }

            e.target.classList.remove('has-error');
            e.target.nextElementSibling.classList.remove('has-error');

        } else {
            e.target.parentElement.parentElement.classList.add('has-error');
            const invalidInputs = document.querySelectorAll('input:invalid');
            invalidInputs[0].focus();
            for (let i = 0; i < invalidInputs.length; i++) {
                invalidInputs[i].nextElementSibling.classList.add('has-error');
            }
        }
    };
    loginFunctionWithGoogle(e) {
        e.preventDefault();
        window.location.replace(`${BASE_API}/v1/google/login?redirect=${window.location.href}`)
    }

    loginFunctionWithGithub(e) {
        e.preventDefault();
        window.location.replace(`${BASE_API}/v1/github/login?redirect=${window.location.href}`)
    }
    render() {

        const { scanData, postSignUpLoading, postSignUpError, postSignUpMessage } = this.props;
        return (
            <>
            <Header backgroundColor={"yellowHeader"}/>

                <div class="form-background">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-2 col-md-2 col-lg-2 image-leftside">
                                <div class="form-inside-wrapper-imageSide">
                                    <div class="image-leftside-wrapper">
                                        <img
                                            src={
                                                Request_Demo_Page_Illustration_Object_Big_left
                                            }
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-8 col-md-8 col-lg-8 signup-form">
                                <div class="form-wrapper">
                                    <div class="form-heading">
                                        <p>Signup for your Account</p>
                                    </div>
                                    <form
                                        name="UserSignupForm"
                                        onSubmit={this.formSubmitHandler.bind(
                                            this
                                        )}
                                        id="UserSignupForm"
                                    >
                                        <div class="form-field">
                                            <div class="inline-fields">
                                                <div class="first-field">
                                                    <img
                                                        src={noun_person}
                                                        alt=""
                                                    />
                                                    <input
                                                        required
                                                        type="text"
                                                        name="firstname"
                                                        id="firstname"
                                                        placeholder="First Name"
                                                        value={
                                                            scanData.firstname
                                                        }
                                                        onChange={this.handleDataChange.bind(
                                                            this
                                                        )}
                                                        pattern={
                                                            '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                        }
                                                    />
                                                    <span
                                                        class="errors"
                                                        id="username-error"
                                                    >
                                                        First Name is not
                                                        Valid
                                                    </span>
                                                </div>
                                                <div class="last-field">
                                                    <img
                                                        src={noun_person}
                                                        alt=""
                                                    />
                                                    <input
                                                        required
                                                        type="text"
                                                        name="lastname"
                                                        id="lastname"
                                                        placeholder="Last Name"
                                                        value={
                                                            scanData.lastname
                                                        }
                                                        onChange={this.handleDataChange.bind(
                                                            this
                                                        )}
                                                        pattern={
                                                            '([a-zA-Z].+\\s)*[a-zA-Z]+$'
                                                        }
                                                    />
                                                    <span
                                                        class="errors"
                                                        id="username-error"
                                                    >
                                                        Last Name is not
                                                        Valid
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-field">
                                            <img
                                                src={noun_envelope}
                                                alt=""
                                            />
                                            <input
                                                required
                                                type="text"
                                                name="email"
                                                id="signup-email"
                                                placeholder="Email"
                                                value={scanData.email}
                                                onChange={this.handleDataChange.bind(
                                                    this
                                                )}
                                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            />
                                            <span
                                                class="errors"
                                                id="email-error"
                                            >
                                                Please enter an email address
                                            </span>
                                        </div>
                                        <div class="form-field">

                                            <div class="inline-fields">

                                                <div class="first-field no-icon">
                                                    <input
                                                        required
                                                        name="password"
                                                        id="login-password"
                                                        placeholder="Password"
                                                        type={this.state.hidden ? "password" : "text"}
                                                        value={
                                                            scanData.password
                                                        }
                                                        onChange={this.handleDataChange.bind(
                                                            this
                                                        )}
                                                        pattern={
                                                            '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
                                                        }
                                                    />


                                                    <span
                                                        class="errors"
                                                        id="password-error"
                                                    >
                                                        <div className="input-error" style={{ color: "#f03030" }}>Enter your
                                                            password (<span
                                                                style={{ color: `${scanData.password.length >= 8 ? "green" : "#f03030"}` }}>Min 8 char.</span>, <span
                                                                    style={{ color: `${scanData.password.match("(?=.*?[A-Z])") ? "#279627" : "#f03030"}` }}>at least one uppercase letter</span>, <span
                                                                        style={{ color: `${scanData.password.match("(?=.*?[a-z])") ? "#279627" : "#f03030"}` }}>one lowercase letter</span>, <span
                                                                            style={{ color: `${scanData.password.match("(?=.*?[0-9])") ? "#279627" : "#f03030"}` }}>one number, and  </span>
                                                            <span
                                                                style={{ color: `${scanData.password.match("(?=.*?[#?!@$%^&*-])") ? "#279627" : "#f03030"}` }}>one special character required</span>).
                                                        </div>

                                                    </span>
                                                    <img
                                                        src={noun_Eye}
                                                        alt=""
                                                        id="toggleeye"
                                                        onClick={this.toggleShow.bind(this)}
                                                    />

                                                </div>
                                                <div class="last-field no-icon">
                                                    <input
                                                        required
                                                        type="password"
                                                        name="confirmpassword"
                                                        id="conpassword"
                                                        placeholder="Confirm Password"
                                                        value={
                                                            scanData.confirmpassword
                                                        }
                                                        onChange={this.handleDataChange.bind(
                                                            this
                                                        )}
                                                    />
                                                    <span
                                                        class="errors"
                                                        id="password-error"
                                                    >
                                                        Password doesn't
                                                        match
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {!postSignUpLoading ?

                                                (<button type="submit"
                                                    id="submit"
                                                    formNoValidate={true}
                                                >Sign Up</button>)
                                                :
                                                (<button>
                                                    <i className="fa fa-spin fa-spinner btn-icon"></i>
                                                    Please wait ..
                                                </button>)
                                            }
                                        </div>
                                        {!postSignUpLoading && postSignUpError &&
                                            <div className="center-error form-error">
                                                <p>{postSignUpMessage}</p>
                                            </div>

                                        }
                                    </form>
                                    <div class="or-text">
                                        <p>OR</p>
                                    </div>
                                    <div class="loginbutton">
                                        <button
                                            onClick={this.loginFunctionWithGoogle.bind(this)}>

                                            <img
                                                src={google_g_2015}
                                                alt=""
                                            />
                                            Continue with Google
                                        </button>
                                    </div>
                                    <div class="loginbutton">
                                        <button
                                            onClick={this.loginFunctionWithGithub.bind(this)}>

                                            <img
                                                src={Octicons_mark_github}
                                                alt=""
                                            />
                                            Continue With Github
                                        </button>
                                    </div>
                                    <div class="login-link">
                                        <p> Already have an MetaSecure
                                            Account ?
                                            <NavLink to="/auth/login">
                                                { } Login
                                            </NavLink></p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-2 col-md-2 col-lg-2 image-rightside">
                                <div class="form-inside-wrapper-imageSide">
                                    <div class="image-rightside-wrapper">
                                        <img
                                            src={
                                                Request_Demo_Page_Illustration_Object_small_right
                                            }
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />    
            </>
        );
    }
}

function mapStateToProps(state) {
    const { data, scanData, postSignUpLoading, postSignUpError, postSignUpMessage } = state.signUpReducer;
    return { data, scanData, postSignUpLoading, postSignUpError, postSignUpMessage };

}

export default withRouter(connect(mapStateToProps)(SignUp));
