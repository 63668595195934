import React, { Component } from "react";
import HeaderDashboard from "../header";
import { withRouter } from 'react-router-dom';
import './dist/style.css';
import './dist/loaderStyle.css'
import { connect } from 'react-redux';

import { DotWaveLoader } from 'react-inline-loaders';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { securityApi, websiteURL } from "../../../common/ApiAction";

let sse;
const WEBSITE_URL = websiteURL();
const SECURITY_API = securityApi();
let nameTarget;
class CheckScore extends Component {
    state = {
        urlLink: '',
    };
    //Toggle div
    handleImpactClick(e) {
        let parent = e.target.closest('.cards-wrapper');
        let div = parent.querySelector('.toggle_div');
        div.classList.toggle('show');
        parent.querySelector('.down-arrow-image').classList.toggle('up-arrow');

    }
    componentDidMount() {
        if(this.props.getUrl === '') {
            this.props.history.push('/layout')
        }
    }

    handleScanAccordion(e) {
        let parent = e.target.closest('.scan-details-cards-accordion');
        let div = parent.querySelector('.scan-details-accordion');
        div.classList.toggle('scan-details-cards-accordion-show');
    }
    render() {
        const {
            getWebsiteSecurity,
            getEmailSecurity,
            getHttpSecurity,
            getNetworkSecurity,
            postScanScore,
            loading,
            resultFinalMessage,
            emailSecurityLoader,
            httpSecurityLoader,
            networkSecurityLoader,
            websiteSecurityLoader,
        } = this.props;
        return (
            <>
                <HeaderDashboard />
                <div className="layout-wrapper">
                    <div className="container extra-margin">
                        <div className="cfs-card-wrapper">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                                    <div className="cfs-akirastack-rating-heading">
                                        <div class="mini-card-title">
                                            <div class="card-heading">
                                                <p> 
                                                MetaSecure Rating{' '}
                                                {this.props.getUrl !== ''
                                                    ? 'for '
                                                    : ''}{' '}
                                                {this.props.getUrl !== '' ? (
                                                    <div
                                                        style={{
                                                            marginTop: '10px',
                                                        }}
                                                    >
                                                        <a
                                                            href={
                                                                this.props.getUrl
                                                            }
                                                            target="_blank"
                                                        >
                                                            {this.props.getUrl}
                                                        </a>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                </p>
                                            </div>
                                            <div class="emoji">
                                            <img
                                                src={
                                                    resultFinalMessage !==
                                                        'Final result'
                                                        && loading ?

                                                        (require('./dist/images/thinking-emoji.png')) : (
                                                            (postScanScore > 85)
                                                                ? require('./dist/images/smile-emoji.svg')
                                                                : (postScanScore > 55 &&
                                                                    postScanScore < 85)
                                                                    ? require('./dist/images/normal-emoji.svg')
                                                                    : (postScanScore < 55 &&
                                                                        postScanScore > 35)
                                                                        ? require('./dist/images/sad-emoji.svg')
                                                                        : (postScanScore < 35 &&
                                                                            postScanScore > 1)
                                                                            ? require('./dist/images/very-sad-emoji.svg')
                                                                            : ''
                                                        )}
                                                alt=""
                                                class="smile"
                                            />                                            </div>
                                            <div class="comment">
                                            <p
                                                className={
                                                    postScanScore > 85
                                                        ? 'comment-para-verylow'
                                                        : postScanScore > 55 &&
                                                            postScanScore < 85
                                                            ? 'comment-para-low'
                                                            : postScanScore > 35 &&
                                                                postScanScore < 55
                                                                ? 'comment-para-moderate'
                                                                : postScanScore > 1 &&
                                                                    postScanScore < 35
                                                                    ? 'comment-para-medium'
                                                                    : postScanScore < 1
                                                                        ? 'comment-para-high'
                                                                        : 'comment-para-critical'
                                                }
                                            >
                                                                                                {
                                                    resultFinalMessage !==
                                                        'Final result'
                                                        && loading ?
                                                        (

                                                            <svg
                                                                width="50"
                                                                height="50"
                                                                viewBox="0 0 120 30"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="#90CA2F"
                                                                aria-label="audio-loading"
                                                            >
                                                                <circle
                                                                    cx="15"
                                                                    cy="15"
                                                                    r="15"
                                                                >
                                                                    <animate
                                                                        attributeName="r"
                                                                        from="15"
                                                                        to="15"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="15;9;15"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                    <animate
                                                                        attributeName="fillOpacity"
                                                                        from="1"
                                                                        to="1"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="1;.5;1"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                </circle>
                                                                <circle
                                                                    cx="60"
                                                                    cy="15"
                                                                    r="9"
                                                                    attributeName="fillOpacity"
                                                                    from="1"
                                                                    to="0.3"
                                                                >
                                                                    <animate
                                                                        attributeName="r"
                                                                        from="9"
                                                                        to="9"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="9;15;9"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                    <animate
                                                                        attributeName="fillOpacity"
                                                                        from="0.5"
                                                                        to="0.5"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values=".5;1;.5"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                </circle>
                                                                <circle
                                                                    cx="105"
                                                                    cy="15"
                                                                    r="15"
                                                                >
                                                                    <animate
                                                                        attributeName="r"
                                                                        from="15"
                                                                        to="15"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="15;9;15"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                    <animate
                                                                        attributeName="fillOpacity"
                                                                        from="1"
                                                                        to="1"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="1;.5;1"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                </circle>
                                                            </svg>
                                                        ) : (
                                                            (postScanScore > 85) ?

                                                                'Low Risk'
                                                                : (postScanScore < 85 &&
                                                                    postScanScore > 55)
                                                                    ? 'Medium Risk'
                                                                    : (postScanScore < 55 &&
                                                                        postScanScore > 35)
                                                                        ? 'High Risk'
                                                                        : (postScanScore < 35 &&
                                                                            postScanScore > 1)
                                                                            ? 'Critical'
                                                                                : ' '
                                                        )}
                                            </p>
                                            </div>
                                        </div>
                                        <div class="grade-inner">
                                            <div>
                                                <div class="grade-side">
                                                <div
                                                    class={

                                                        resultFinalMessage !==
                                                            'Final result'
                                                            &&
                                                            loading
                                                            ?
                                                            (
                                                                ''
                                                            ) : (
                                                                (postScanScore > 85)
                                                                    ? 'grade verylow'
                                                                    : (postScanScore > 55 &&
                                                                        postScanScore < 85)
                                                                        ? 'grade low'
                                                                        : (postScanScore > 35 &&
                                                                            postScanScore < 55)
                                                                            ? 'grade moderate'
                                                                            : (postScanScore > 1 &&
                                                                                postScanScore < 35)
                                                                                ? 'grade medium'
                                                                                : (postScanScore < 1)
                                                                                    ? 'grade high'
                                                                                    : 'grade critical'
                                                            )
                                                    }
                                                >
                                                    <span
                                                        class={
                                                            resultFinalMessage !==
                                                                'Final result'
                                                                && loading
                                                                ?
                                                                (
                                                                    " "

                                                                ) : (
                                                                    (postScanScore > 85)
                                                                        ? 'grade-list verylow'
                                                                        : (postScanScore > 55 &&
                                                                            postScanScore < 85)
                                                                            ? 'grade-list low'
                                                                            : (postScanScore > 35 &&
                                                                                postScanScore < 55)
                                                                                ? 'grade-list moderate'
                                                                                : (postScanScore > 1 &&
                                                                                    postScanScore < 35)
                                                                                    ? 'grade-list medium'
                                                                                    : (postScanScore < 1)
                                                                                        ? 'grade-list high'
                                                                                        : 'grade-list critical'
                                                                )}
                                                    >
                                                        {
                                                            resultFinalMessage !==
                                                                'Final result'
                                                                && loading ?
                                                                (
                                                                    " "

                                                                ) : (
                                                                    (postScanScore > 85)
                                                                        ? 'A'
                                                                        : (postScanScore > 55 &&
                                                                            postScanScore < 85)
                                                                            ? 'B'
                                                                            : (postScanScore > 35 &&
                                                                                postScanScore < 55)
                                                                                ? 'C'
                                                                                : (postScanScore > 1 &&
                                                                                    postScanScore < 35)
                                                                                    ? 'D'
                                                                                        : 'C')}
                                                    </span>
                                                </div>
                                                <span
                                                    class={
                                                        resultFinalMessage !==
                                                            'Final result'
                                                            && loading ?
                                                            (
                                                                'grade-number critical'

                                                            ) : (
                                                                (postScanScore > 85)
                                                                    ? 'grade-number verylow'
                                                                    : (postScanScore > 55 &&
                                                                        postScanScore < 85)
                                                                        ? 'grade-number low'
                                                                        : (postScanScore > 35 &&
                                                                            postScanScore < 55)
                                                                            ? 'grade-number moderate'
                                                                            : (postScanScore > 1 &&
                                                                                postScanScore < 35)
                                                                                ? 'grade-number medium'
                                                                                : (postScanScore < 1)
                                                                                    ? 'grade-number high'
                                                                                    : 'grade-number critical'
                                                            )}

                                                >
                                                    {resultFinalMessage !==
                                                        'Final result' &&
                                                        loading ?
                                                        (
                                                            <svg
                                                                width="50"
                                                                height="50"
                                                                viewBox="0 0 120 30"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="#90CA2F"
                                                                aria-label="audio-loading"
                                                            >
                                                                <circle
                                                                    cx="15"
                                                                    cy="15"
                                                                    r="15"
                                                                >
                                                                    <animate
                                                                        attributeName="r"
                                                                        from="15"
                                                                        to="15"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="15;9;15"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                    <animate
                                                                        attributeName="fillOpacity"
                                                                        from="1"
                                                                        to="1"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="1;.5;1"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                </circle>
                                                                <circle
                                                                    cx="60"
                                                                    cy="15"
                                                                    r="9"
                                                                    attributeName="fillOpacity"
                                                                    from="1"
                                                                    to="0.3"
                                                                >
                                                                    <animate
                                                                        attributeName="r"
                                                                        from="9"
                                                                        to="9"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="9;15;9"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                    <animate
                                                                        attributeName="fillOpacity"
                                                                        from="0.5"
                                                                        to="0.5"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values=".5;1;.5"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                </circle>
                                                                <circle
                                                                    cx="105"
                                                                    cy="15"
                                                                    r="15"
                                                                >
                                                                    <animate
                                                                        attributeName="r"
                                                                        from="15"
                                                                        to="15"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="15;9;15"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                    <animate
                                                                        attributeName="fillOpacity"
                                                                        from="1"
                                                                        to="1"
                                                                        begin="0s"
                                                                        dur="0.8s"
                                                                        values="1;.5;1"
                                                                        calcMode="linear"
                                                                        repeatCount="indefinite"
                                                                    ></animate>
                                                                </circle>
                                                            </svg>
                                                        ) : (
                                                            postScanScore
                                                        )}
                                                    /100
                                                </span>
                                                </div>
                                                <div class="mini-card-text">
                                                {resultFinalMessage !==
                                                    'Final result'
                                                    && loading
                                                    ?
                                                    (<p>
                                                        SCAN IN PROGRESS
                                                    </p>
                                                    ) : (
                                                        '')}
                                            </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                    <div className="scanned-stats-cfs-card-wrapper">
                                        <div className="scanned-cfs-heading">
                                            <p>Scanned stats</p>
                                        </div>
                                        {
                                                    resultFinalMessage !==
                                                    'Final result' ? (  <div class="scanning-loader-2">
                                                    <div class="spinner-8"></div>
                                                  </div> ) : ( <div className="scanned-stats-cfs-list">
                                            <div>
                                                <p>High</p>
                                                <span className="color-high">{
                                                    resultFinalMessage !==
                                                    'Final result' ? (
                                                        <div class="scanning-loader-2">
                                                        <div class="spinner-8"></div>
                                                      </div>
                                                    ) : (
                                                        
                                                        this.props.highData
                                                    )
                                                }</span>
                                            </div>
                                            <div>
                                                <p>Medium</p>
                                                <span className="color-medium">{resultFinalMessage !==
                                                    'Final result' ? (
                                                        <DotWaveLoader/>
                                                    ) : (
                                                        
                                                        this.props.mediumData
                                                    )}</span>
                                            </div>
                                            <div>
                                                <p>Low</p>
                                                <span className="color-low">{resultFinalMessage !==
                                                    'Final result' ? (
                                                        <DotWaveLoader/>
                                                    ) : (
                                                        
                                                        this.props.lowData
                                                    )}</span>
                                            </div>
                                        </div>)}
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="scan-deatils-cards-accordion-wrapper">
                            <div className="scan-deatils-card-heading">
                                <p>Scan Details</p>
                            </div>
                            <div className="scan-details-cards-accordion">
                                <div className="scan-details-accordion scan-details-cards-accordion-show">
                                    <div className="scan-details-list-wrapper">
                                        <div className="scan-details-list-heading">
                                            <h2>Website Security</h2>
                                            <div className="scan-list-impact-icon"
                                                onClick={this.handleScanAccordion.bind(
                                                    this
                                                )}
                                            >
                                                <p>Impact</p>
                                                <img src={require('./dist/images/down_arrow.png')} width="30" alt="dropdown arrow" />
                                            </div>
                                        </div>
                                        <div class="scan-deatils-list-description">
                                        {getWebsiteSecurity.length > 0 &&
                                        getWebsiteSecurity.map(
                                            (info, index) => (
                                            <div class="cards-inner-item">
                                                <div class="cards-inner-left-inner"
                                                    key={index}
                                                >
                                                    <a href={`${WEBSITE_URL}/vulnerability-details?id=${JSON.parse(info.result).id}`} target="_blank">
                                                    <h4>
                                                            {JSON.parse(
                                                                info.result
                                                            ).heading
                                                                ? JSON.parse(
                                                                    info.result
                                                                ).heading
                                                                : JSON.parse(
                                                                    info.result
                                                                ).header}
                                                        </h4>
                                                    </a>
                                                    <p>
                                                            {JSON.parse(
                                                                info.result
                                                            ).description
                                                                ? JSON.parse(
                                                                    info.result
                                                                ).description
                                                                : JSON.parse(
                                                                    info.result
                                                                ).message}
                                                        </p>
                                                </div>
                                                <div class="cards-inner-right-inner"><button
                                                            class={
                                                                JSON.parse(
                                                                    info.result
                                                                ).impact ===
                                                                    'HIGH'
                                                                    ? 'high-button'
                                                                    : JSON.parse(
                                                                        info.result
                                                                    )
                                                                        .impact ===
                                                                        'PASS'
                                                                        ? 'pass-button'
                                                                        : JSON.parse(
                                                                            info.result
                                                                        )
                                                                            .impact ===
                                                                            'MEDIUM'
                                                                            ? 'medium-button'
                                                                            : JSON.parse(
                                                                                info.result
                                                                            )
                                                                                .impact ===
                                                                                'INFORMATIONAL'
                                                                                ? 'info-button'
                                                                                : 'low-button'
                                                            }
                                                            type="button"
                                                        >
                                                            {
                                                                JSON.parse(
                                                                    info.result
                                                                ).impact
                                                            }
                                                        </button>
                                                        </div>
                                            </div> 
                                            )
                                             )}
                                            {websiteSecurityLoader !== false &&
                                        loading &&
                                        !this.props.postScanError && (
                                            <div
                                                style={{
                                                    width: '170%',
                                                    justifyContent: 'center',
                                                    textAlign: 'center',
                                                    color: 'red',
                                                }}
                                            >
                                                <DotWaveLoader />
                                            </div>
                                        )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="scan-details-cards-accordion">
                                <div className="scan-details-accordion scan-details-cards-accordion-show">
                                    <div className="scan-details-list-wrapper">
                                        <div className="scan-details-list-heading">
                                            <h2>Email Security</h2>
                                            <div className="scan-list-impact-icon"
                                                onClick={this.handleScanAccordion.bind(
                                                    this
                                                )}
                                            >
                                                <p>Impact</p>
                                                <img src={require('./dist/images/down_arrow.png')} width="30" alt="dropdown arrow" />
                                            </div>
                                        </div>
                                        <div class="scan-deatils-list-description">
                                        {getEmailSecurity.length > 0 &&
                                        getEmailSecurity.map((info, index) => (
                                            <div class="cards-inner-item"
                                            key={index}
                                            >
                                                <div class="cards-inner-left-inner">
                                                <a href ={`${WEBSITE_URL}/vulnerability-details?id=${JSON.parse(info.result).id}`} target="_blank">
                                                    <h4>
                                                        {JSON.parse(info.result)
                                                            .heading
                                                            ? JSON.parse(
                                                                info.result
                                                            ).heading
                                                            : JSON.parse(
                                                                info.result
                                                            ).header}
                                                    </h4>
                                                    </a>
                                                    <p>
                                                        {JSON.parse(info.result)
                                                            .description
                                                            ? JSON.parse(
                                                                info.result
                                                            ).description
                                                            : JSON.parse(
                                                                info.result
                                                            ).message}
                                                    </p>
                                                </div>
                                                <div class="cards-inner-right-inner">  <button
                                                        class={
                                                            JSON.parse(
                                                                info.result
                                                            ).impact === 'HIGH'
                                                                ? 'high-button'
                                                                : JSON.parse(
                                                                    info.result
                                                                ).impact ===
                                                                    'PASS'
                                                                    ? 'pass-button'
                                                                    : JSON.parse(
                                                                        info.result
                                                                    ).impact ===
                                                                        'MEDIUM'
                                                                        ? 'medium-button'
                                                                        : JSON.parse(
                                                                            info.result
                                                                        ).impact ===
                                                                            'INFORMATIONAL'
                                                                            ? 'info-button'
                                                                            : 'low-button'
                                                        }
                                                        type="button"
                                                    >
                                                        {
                                                            JSON.parse(
                                                                info.result
                                                            ).impact
                                                        }
                                                    </button></div>
                                            </div> 
                                        )
                                        )} 
                                        {emailSecurityLoader !== false &&
                                        loading &&
                                        !this.props.postScanError && (
                                            <div
                                                style={{
                                                    width: '170%',
                                                    justifyContent: 'center',
                                                    textAlign: 'center',
                                                    color: 'red',
                                                }}
                                            >
                                                <DotWaveLoader />
                                            </div>
                                        )}
                                         
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="scan-details-cards-accordion">
                                <div className="scan-details-accordion scan-details-cards-accordion-show">
                                    <div className="scan-details-list-wrapper">
                                        <div className="scan-details-list-heading">
                                            <h2>Network Security</h2>
                                            <div className="scan-list-impact-icon"
                                                onClick={this.handleScanAccordion.bind(
                                                    this
                                                )}
                                            >
                                                <p>Impact</p>
                                                <img src={require('./dist/images/down_arrow.png')} width="30" alt="dropdown arrow" />
                                            </div>
                                        </div>
                                        <div class="scan-deatils-list-description">
                                        {getNetworkSecurity.length > 0 &&
                                        getNetworkSecurity.map(
                                            (info, index) => (
                                            <div class="cards-inner-item"
                                            key={index}
                                            >
                                                <div class="cards-inner-left-inner">
                                                <a href ={`${WEBSITE_URL}/vulnerability-details?id=${JSON.parse(info.result).id}`} target="_blank">
                                                        <h4>
                                                            {JSON.parse(
                                                                info.result
                                                            ).heading
                                                                ? JSON.parse(
                                                                    info.result
                                                                ).heading
                                                                : JSON.parse(
                                                                    info.result
                                                                ).header}
                                                        </h4>
                                                        </a>
                                                        <p>
                                                            {JSON.parse(
                                                                info.result
                                                            ).description
                                                                ? JSON.parse(
                                                                    info.result
                                                                ).description
                                                                : JSON.parse(
                                                                    info.result
                                                                ).message}
                                                        </p>
                                                </div>
                                                <div class="cards-inner-right-inner"><button
                                                            class={
                                                                JSON.parse(
                                                                    info.result
                                                                ).impact ===
                                                                    'HIGH'
                                                                    ? 'high-button'
                                                                    : JSON.parse(
                                                                        info.result
                                                                    )
                                                                        .impact ===
                                                                        'PASS'
                                                                        ? 'pass-button'
                                                                        : JSON.parse(
                                                                            info.result
                                                                        )
                                                                            .impact ===
                                                                            'MEDIUM'
                                                                            ? 'medium-button'
                                                                            : JSON.parse(
                                                                                info.result
                                                                            )
                                                                                .impact ===
                                                                                'INFORMATIONAL'
                                                                                ? 'info-button'
                                                                                : 'low-button'
                                                            }
                                                            type="button"
                                                        >
                                                            {
                                                                JSON.parse(
                                                                    info.result
                                                                ).impact
                                                            }

                                                        </button></div>
                                            </div>
                                            )
                                        )}
                                         {networkSecurityLoader !== false &&
                                        loading &&
                                        !this.props.postScanError && (
                                            <div
                                                style={{
                                                    width: '170%',
                                                    justifyContent: 'center',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <DotWaveLoader />
                                            </div>
                                        )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="scan-details-cards-accordion">
                                <div className="scan-details-accordion scan-details-cards-accordion-show">
                                    <div className="scan-details-list-wrapper">
                                        <div className="scan-details-list-heading">
                                            <h2>HTTP Security Headers</h2>
                                            <div className="scan-list-impact-icon"
                                                onClick={this.handleScanAccordion.bind(
                                                    this
                                                )}
                                            >
                                                <p>Impact</p>
                                                <img src={require('./dist/images/down_arrow.png')} width="30" alt="dropdown arrow" />
                                            </div>
                                        </div>
                                        <div class="scan-deatils-list-description">
                                        {getHttpSecurity.length > 0 &&
                                        getHttpSecurity.map((info, index) => (
                                            <div class="cards-inner-item"
                                            key={index}
                                            >
                                                <div class="cards-inner-left-inner" >
                                                <a href ={`${WEBSITE_URL}/vulnerability-details?id=${JSON.parse(info.result).id}`} target="_blank">
                                               <h4>
                                                  
                                                   {JSON.parse(info.result)
                                                       .heading
                                                       ? JSON.parse(
                                                           info.result
                                                       ).heading
                                                       : JSON.parse(
                                                           info.result
                                                       ).header}
                                                       
                                               </h4>
                                               </a>
                                               <p>
                                                   {JSON.parse(info.result)
                                                       .description
                                                       ? JSON.parse(
                                                           info.result
                                                       ).description
                                                       : JSON.parse(
                                                           info.result
                                                       ).message}
                                               </p>
                                                </div>
                                                <div class="cards-inner-right-inner"> <button
                                                        class={
                                                            JSON.parse(
                                                                info.result
                                                            ).impact === 'HIGH'
                                                                ? 'high-button'
                                                                : JSON.parse(
                                                                    info.result
                                                                ).impact ===
                                                                    'PASS'
                                                                    ? 'pass-button'
                                                                    : JSON.parse(
                                                                        info.result
                                                                    ).impact ===
                                                                        'MEDIUM'
                                                                        ? 'medium-button'
                                                                        : JSON.parse(
                                                                            info.result
                                                                        ).impact ===
                                                                            'INFORMATIONAL'
                                                                            ? 'info-button'
                                                                            : 'low-button'
                                                        }
                                                        type="button"
                                                    >
                                                        {
                                                            JSON.parse(
                                                                info.result
                                                            ).impact
                                                        }
                                                    </button></div>
                                            </div> 
                                        )
                                        )}
                                         {httpSecurityLoader !== false &&
                                        loading &&
                                        !this.props.postScanError && (
                                            <div
                                                style={{
                                                    width: '170%',
                                                    justifyContent: 'center',
                                                    textAlign: 'center',
                                                    color: 'red',
                                                }}
                                            >
                                                <DotWaveLoader />
                                            </div>
                                        )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        
                    </div>
                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
    const {
        scanData,
        scanId,
        high,
        loading,
        getUrl,
        postScanMessage,
        postScanError,
        postScanLoading,
        resultFinalMessage,
        emailSecurityLoader,
        httpSecurityLoader,
        networkSecurityLoader,
        websiteSecurityLoader,
        getScanLoading,
        getWebsiteSecurity,
        getEmailSecurity,
        getHttpSecurity,
        getNetworkSecurity,
        getScanError,
        id,
        postScanScore,
        getContactData,
        postContactLoading,
        postContactMessage,
        postContactError,
        status_code,
        highData,
        lowData,
        mediumData,
    } = state.scoreReducer;
    return {
        scanData,
        highData,
        lowData,
        mediumData,
        scanId,
        loading,
        high,
        getUrl,
        postScanMessage,
        postScanError,
        postScanLoading,
        resultFinalMessage,
        emailSecurityLoader,
        httpSecurityLoader,
        networkSecurityLoader,
        websiteSecurityLoader,
        getScanLoading,
        getWebsiteSecurity,
        getEmailSecurity,
        getHttpSecurity,
        getNetworkSecurity,
        getScanError,
        id,
        postScanScore,
        getContactData,
        postContactLoading,
        postContactMessage,
        postContactError,
        status_code,
    }
}
export default withRouter(connect(mapStateToProps)(CheckScore));