import './dist/style.css';
import './dist/invite-user-popup.css';
import './dist/email.css';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import React, { Component } from "react";

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUserInvite, getWorkspaceUserList, getIntegration,userRoleManagement, workspaceDelete } from '../action';
import HeaderDashboard from "../header";


class WorkspaceSetting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emails: [],
            roleAssigned: ''
        };
        this.deleteWorkspace = this.deleteWorkspace.bind(this)
        this.handleSelectRole = this.handleSelectRole.bind(this)
    }

    componentDidMount() {
       // document.addEventListener('DOMContentLoaded', this.handleColorChange);
        this.props.dispatch(getWorkspaceUserList(localStorage.getItem('workspace_id')))
    }

    componentWillUnmount() {
     //   document.removeEventListener('DOMContentLoaded', this.handleColorChange)
    }

    handleRoleDropdown(e) {
        let allUserList = document.querySelectorAll('.role-field-dropdown');
        let parent = e.target.closest('.role-field-dropdown');
        for (let i = 0; i < allUserList.length; i++) {
            if (parent !== allUserList[i]) {
                allUserList[i].classList.remove('is-activeRoleDropdown');
            }
        }
        parent.classList.toggle('is-activeRoleDropdown');
    }
    async handleSelectRole(e, info) {

        let roleParent = e.target.closest('li');
        let id = localStorage.getItem('workspace_id');
        let email = info.user_email
        let parentUl = e.target.closest('.workspace-setting-list-role-field');
        let roleValue = roleParent.querySelector('p').innerHTML;
        let body = {
            "user_email": email,
            "user_role": roleValue
        }
        parentUl.querySelector('.role-textfield').innerHTML = roleValue;
        parentUl.querySelector('.role-field-dropdown').classList.remove('is-activeRoleDropdown');
        await this.props.dispatch(userRoleManagement(body, id, email))
        if (this.props.role_change_error === false && this.props.role_change_error_message !== '') {
            NotificationManager.success(`${this.props.role_change_error_message}`);
        }
        else {
            NotificationManager.error(`${this.props.role_change_error_message}`);
        }
    }

    handleInviteUser(e) {
        document.querySelector('.invite-user-popup-wrapper').classList.toggle('invite-user-popup-wrapper-show');
    }

    async deleteWorkspace(info) {
        let id = localStorage.getItem('workspace_id')
        let email = info.user_email
        await this.props.dispatch(workspaceDelete(id, email))
        if (this.props.delete_user_error_message === "Member deleted successfully") {
            this.props.dispatch(getWorkspaceUserList(localStorage.getItem('workspace_id')))
            NotificationManager.success(`${this.props.delete_user_error_message}`);
        }
        else {
            NotificationManager.error(`${this.props.delete_user_error_message}`);
        }
    }

    handleChange = e => {
        this.setState(
            { roleAssigned: e.target.value });
    }

    closeInviteUser(e) {
        document.querySelector('.invite-user-popup-wrapper').classList.remove('invite-user-popup-wrapper-show');
    }

    handleKeyPress = (event) => {
        const button = document.getElementById('invite-user')
        if (event.key === 'Enter') {

            let input = document.getElementById('input-selecionar');
            const newEmails = input.value.split(',');
            newEmails.forEach(email => {
                const cleanEmail = email.replace(/[\"\']/g, "");
                if (this.validateEmail(cleanEmail)) {
                    document.querySelector('.email-errors').innerText = ''
                    this.setState(state => {
                        const list = state.emails.push(cleanEmail);
                        return {
                            list
                        };
                    });
                    input.value = "";
                }
                else {
                    document.querySelector('.email-errors').innerText = 'Please enter a valid email'
                }
            })
        }
    }

   async sendInvite() {
        const button = document.querySelector('button')
        const userCredentials = [];
        this.state.emails.forEach(email => {
            userCredentials.push({ user_email: email, user_role: this.state.roleAssigned })
        })

        if (this.state.emails.toString() !== '') {
            let id = localStorage.getItem('workspace_id');
          await this.props.dispatch(getUserInvite(userCredentials, id))
        }
        if (this.props.invite_user_error !== true) {
            this.closeInviteUser()
           await this.props.dispatch(getWorkspaceUserList(localStorage.getItem('workspace_id')))
            NotificationManager.success('New member invited');
        }
        else {
            NotificationManager.error('User already exist');
        }
    }

    validateEmail(email) {
        var re = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        return re.test(email);
    }

    onRemoveUser = id => {
        this.setState({
            emails: this.state.emails.filter(function (person) {
                return person !== id;
            })
        });
    };
// Not working for mapping
    // handleColorChange = (event) => {
    //     let Allname = document.querySelectorAll('.workspace-name-field-icon');
    //     for (let i = 0; i < Allname.length; i++) {
    //         var randomColor = Math.floor(Math.random() * 16777215).toString(16);
    //         Allname[i].style.backgroundColor = '#' + randomColor;
    //     }
    // };


    render() {
        const { workspace_list } = this.props;
        return (
            <>
                <HeaderDashboard />
                <div className="layout-wrapper" >
                    <div className="container extra-margin" >
                        <div className='workspace-setting-wrapper' >
                            <div className='workspace-setting-invite-new-user'>
                                <button
                                data-testid = {'invite-user-popup'}
                                    onClick={this.handleInviteUser.bind(
                                        this
                                    )}>Invite new user</button>
                                <div className="invite-user-popup-wrapper">
                                    <div className="invite-user-popup-inside">
                                        <div>
                                            <div className="close-invite-popup" >
                                                <img src={require('./dist/close.png')} width="30" height="30" data-testid = {'close-popup'}
                                                    onClick={this.closeInviteUser.bind(
                                                        this
                                                    )} />
                                            </div>
                                            <div className='invite-user-wrapper-div'>
                                                <div className='invite-user-wrapper-inside-heading'>
                                                    <h3>Invite People to your Workspace</h3>
                                                    <p>Work Together on Projects and Explore the Collaborative feature of Metasecure</p>
                                                </div>
                                                <div className='select-user-type-radios'>
                                                    <h4>Select Type of user to Invite</h4>
                                                    <form>
                                                        <div>
                                                            <input type="radio" id="admin" name="radio-group" value="admin" onChange={this.handleChange.bind(this)} />
                                                            <label htmlFor="admin">Admin</label>
                                                        </div>
                                                        <div>
                                                            <input type="radio" id="user" name="radio-group" value="user" onChange={this.handleChange.bind(this)} />
                                                            <label htmlFor="user">Users</label>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className='enter-emails-section'>
                                                    <h4>Enter Emails</h4>
                                                    <div className="email-picker" data-testid="remove-user">
                                                        <div id="selecionados" data-testid="email-list">
                                                            {this.state.emails.map((email,index) => (
                                                                <div className="selecionado" id={`emailSelection${index}`} key={email} value={email}>
                                                                    {email}
                                                                    <div className="excluir" onClick={() => this.onRemoveUser(email)} >
                                                                        <i className="fa fa-times"></i></div>
                                                                </div>
                                                            ))}
                                                            <input  id="input-selecionar" type="email" required className='input-selection' data-testid="email-input"
                                                                onKeyPress={this.handleKeyPress} autoFocus placeholder='Enter emails' />
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <p className="email-errors" style={{ color: "red" }}></p>
                                                    </div>
                                                </div>
                                                <button disabled={this.state.emails.length == 0} className='send-invite-button' id="invite-user" onClick={this.sendInvite.bind(this)}>
                                                    Invite {this.state.emails.length} People
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='workspace-setting-user-list-wrapper'>
                                <div className='workspace-setting-list-heading'>
                                    <div className='workspace-setting-list-name'>
                                        <p>Name</p>
                                    </div>
                                    <div className='workspace-setting-list-email'>
                                        <p>Email</p>
                                    </div>
                                    <div className='workspace-setting-list-role'>
                                        <p>Role</p>
                                    </div>
                                    <div className='workspace-setting-list-delete'>
                                        <p>Action</p>
                                    </div>
                                </div>
                                {workspace_list.map((info, index) => (
                                    <div className='workspace-setting-list-heading-deatils' key={index}>
                                        <div className='workspace-setting-list-name-field'>
                                            <div className='workspace-name-field-icon'>
                                                <p>{info.user_Name !== '' ?
                                                    (info.user_Name[0]) : (
                                                        info.user_email[0]
                                                    )}</p>
                                            </div>
                                            <div className='workspace-setting-list-name-field'>
                                                <p>{info.user_Name !== '' ?
                                                    (info.user_Name) : (
                                                        info.user_email.split('@')[0]
                                                    )}</p>
                                            </div>
                                        </div>
                                        <div className='workspace-setting-list-email-field'>
                                            <p className='workspace-hidden-heading'>Email</p> 
                                            <p>{info.user_email}</p>
                                        </div>
                                        <div className='workspace-setting-list-role-field' key={index+"key-pair"} >
                                            <div className='role-field-dropdown' key={index+"data-id"} >
                                                <p className='workspace-hidden-heading' data-testid={`role-field-dropdown${index}`}>Role</p>
                                                <div className="role-field-dropdown-selection-wrapper" data-testid={`selection-wrapper${index}`} key={index+"index_value"}
                                                    onClick={this.handleRoleDropdown.bind(
                                                        this
                                                    )}>
                                                    <p className="role-textfield" id="textfield-value" key={index}>{info.user_role}</p>
                                                    <img src={require('./dist/down_arrow.png')} width="30" height="30" />
                                                </div>
                                                <div className="role-field-dropdown-popup" id="dropdown-popup" key={index}>
                                                    <ul>
                                                        <li>
                                                            <div id="role-field-dropdown-select" key={index} onClick={(e) => this.handleSelectRole(e, info)}>
                                                                <p>Admin</p>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div datatest-id={"select-role"} onClick={(e) => this.handleSelectRole(e, info)}>
                                                                <p>User</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='workspace-setting-list-delete-field' onClick={() => this.deleteWorkspace(info)}>
                                            <div className="container-delete" >
                                                <button className="delete" >
                                                    <div className="top"></div>
                                                    <div className="bottom"></div>
                                                </button>
                                            </div>
                                            <button className='workspace-hidden-heading'>
                                                Delete User
                                            </button>
                                        </div>
                                    </div>

                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
    const { scanWebsiteData } = state.websiteScanReducer;
    const { workspace_list, role_change_error, role_change_error_message, invite_user_error, invite_user_message,delete_user_error, delete_user_error_message } = state.getScoreReducer;
    return { scanWebsiteData, workspace_list, role_change_error, role_change_error_message, invite_user_error, invite_user_message, delete_user_error, delete_user_error_message };
}
export default withRouter(connect(mapStateToProps)(WorkspaceSetting));
