import { baseApi } from '../../../common/ApiAction/index';
import { POST_API } from '../../../middleware/symbols';
import {
    REMOVE_OTP, POST_OTP_REQUEST, POST_OTP_SUCCESS, POST_OTP_FAILURE, UPDATE_SCAN
} from './constants';

const BASE_API = baseApi();

export function postOtp(data) {
    return {
        [POST_API]: {
            endpoint: BASE_API + '/v1/verifymail',
            types: [POST_OTP_REQUEST, POST_OTP_SUCCESS, POST_OTP_FAILURE],
            body: data,
            authenticated: true,
        },
    };
}

export function updateScan(data) {
    return {
        type: UPDATE_SCAN,
        data,
    };
}
export function removeOtp() {
    return {
        type: REMOVE_OTP,
    };
}