import React from "react";
import './dist/dashboard.css';
import './dist/filters-modal.css'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import total_vulnerabilties from './dist/total_vulnerabilties.svg';
import total_projects from './dist/total_project.svg';
import small_icon from './dist/small_icon.svg';
import small_icon2 from './dist/small_icon2.svg';
import small_icon3 from './dist/small_icon3.svg';
import small_icon4 from './dist/small_icon4.svg';
import project1 from './dist/project_1.svg';
import rightArrow from './dist/right-arrow.svg';
import HeaderDashboard from "../header";
import moment from "moment"
import { selectProject } from "../action";
const ReactHighcharts = require('react-highcharts');
require('highcharts-3d')(ReactHighcharts.Highcharts);


class Dashboard extends React.Component {
constructor(props) {
    super(props);
    this.handleDetailReport = this.handleDetailReport.bind(this)
}
handleDetailReport(e, index) { 

            if (this.props.postScanError === false) {
            this.props.dispatch(selectProject(e.request_info.uid))
                // this.checkFreeScoreSSE(this.props.scanId)
                this.props.history.push('/layout/checkScoreResult')
            }
        }
    render() {
        // handleFilterOptionClick needed for future integration
        const handleFilterOptionClick = () => {
            document.getElementById('filters-arrow').classList.toggle('filters-arrow-show');
            document.getElementById('filters-modal').classList.toggle('filters-modal-show');

        }
        // make it dynamic



        // value fields will change consdier : type & options3d
        const options = {
            chart: {
                type: 'pie',
                width: 300,
                height: 410,
                options3d: {
                    enabled: true,
                    alpha: 35,
                    beta: 0,
                    animation: true
                }
            },
            title: {
                text: ''
            },
            legend: {
                itemMarginTop: 5,
                itemMarginBottom: 10,
                align: 'center'

            },
            xAxis: {
                minPadding: 0,
                maxPadding: 0
            },
            align: 'center',
            yAxis: {
                minPadding: 0,
                maxPadding: 0
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    },
                    allowPointSelect: true,
                    cursor: 'pointer',
                    depth: 70,
                    showInLegend: true,
                    dataLabels: {
                        enabled: false,
                    },
                    colors: [
                        '#FF9C20',
                        '#F5DF4D',
                        '#FC4217',
                        '#1AA78C'
                    ]
                }
            },
            series: [{
                type: 'pie',
                name: 'Risk',
                data: [
                    ['High', this.props.high],
                    {
                        name: 'Medium',
                        y: this.props.medium,
                        sliced: true,
                        selected: true
                    },
                    ['Critical', this.props.critical],
                    ['Low', this.props.low],
                ]
            }],
        };
        const {
            vulnerabilities_count,
            project_count,
            project_list,
        } = this.props;
        return (
            <>

                <HeaderDashboard />
                <div className="layout-wrapper">
                    <div className="container extra-margin">
                        <div className="Scanned-stats-and-Vulnerabilities-section">
                            <div className="row">
                                <div className="col-12 col-sm-5 col-md-6 col-lg-6">
                                    <h2>Scanned Stats</h2>
                                    <div className="scanned-stats-cards-wrapper">
                                        <div className="scanned-stats-cards-left">
                                            <div id="chartsContainer">
                                                <ReactHighcharts config={options}></ReactHighcharts>
                                            </div>
                                        </div>
                                        <div className="scanned-stats-cards-right">
                                            <div className="scanned-stats-cards-right-cards scanned-stats-cards-style-common">
                                                <img src={total_vulnerabilties} alt="total-vulnerabilities" />
                                                <div>
                                                    <h3>{vulnerabilities_count}</h3>
                                                    <p>Total Vulnerabilities</p>
                                                </div>
                                            </div>
                                            <div className="scanned-stats-cards-right-cards scanned-stats-cards-style-common">
                                                <img src={total_projects} alt="total-vulnerabilities" />
                                                <div>
                                                    <h3>{project_count}</h3>
                                                    <p>Total Projects</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-7 col-md-6 col-lg-6">
                                    <div className="Vulnerabilities-wraper">
                                        <h2>Top Vulnerabilities</h2>
                                        <div className="Vulnerabilities-section-wrapper">
                                            <div className="Vulnerabilities-list-wrapper">
                                                <div className="Vulnerabilities-list">
                                                    <div className="Vulnerabilities-list-image">
                                                        <img src={small_icon} alt="Vulnerabilities type" />
                                                    </div>
                                                    <div className="Vulnerabilities-name">
                                                        <p>Command Injection</p>
                                                    </div>
                                                    <div className="Vulnerabilities-list-details">
                                                        <p>CVE-2020-2343</p>
                                                    </div>
                                                    <div className="Vulnerabilities-list-details-second">
                                                        <p>CWE-400</p>
                                                    </div>
                                                </div>
                                                <div className="Vulnerabilities-list">
                                                    <div className="Vulnerabilities-list-image">
                                                        <img src={small_icon2} alt="Vulnerabilities type" />
                                                    </div>
                                                    <div className="Vulnerabilities-name">
                                                        <p>Command Injection</p>
                                                    </div>
                                                    <div className="Vulnerabilities-list-details">
                                                        <p>CVE-2020-2343</p>
                                                    </div>
                                                    <div className="Vulnerabilities-list-details-second">
                                                        <p>CWE-400</p>
                                                    </div>
                                                </div>
                                                <div className="Vulnerabilities-list">
                                                    <div className="Vulnerabilities-list-image">
                                                        <img src={small_icon3} alt="Vulnerabilities type" />
                                                    </div>
                                                    <div className="Vulnerabilities-name">
                                                        <p>Command Injection</p>
                                                    </div>
                                                    <div className="Vulnerabilities-list-details">
                                                        <p>CVE-2020-2343</p>
                                                    </div>
                                                    <div className="Vulnerabilities-list-details-second">
                                                        <p>CWE-400</p>
                                                    </div>
                                                </div>
                                                <div className="Vulnerabilities-list">
                                                    <div className="Vulnerabilities-list-image">
                                                        <img src={small_icon4} alt="Vulnerabilities type" />
                                                    </div>
                                                    <div className="Vulnerabilities-name">
                                                        <p>Command Injection</p>
                                                    </div>
                                                    <div className="Vulnerabilities-list-details">
                                                        <p>CVE-2020-2343</p>
                                                    </div>
                                                    <div className="Vulnerabilities-list-details-second">
                                                        <p>CWE-400</p>
                                                    </div>
                                                </div>
                                                <div className="Vulnerabilities-list">
                                                    <div className="Vulnerabilities-list-image">
                                                        <img src={small_icon} alt="Vulnerabilities type" />
                                                    </div>
                                                    <div className="Vulnerabilities-name">
                                                        <p>Command Injection</p>
                                                    </div>
                                                    <div className="Vulnerabilities-list-details">
                                                        <p>CVE-2020-2343</p>
                                                    </div>
                                                    <div className="Vulnerabilities-list-details-second">
                                                        <p>CWE-400</p>
                                                    </div>
                                                </div>
                                                <div className="Vulnerabilities-list">
                                                    <div className="Vulnerabilities-list-image">
                                                        <img src={small_icon} alt="Vulnerabilities type" />
                                                    </div>
                                                    <div className="Vulnerabilities-name">
                                                        <p>Command Injection</p>
                                                    </div>
                                                    <div className="Vulnerabilities-list-details">
                                                        <p>CVE-2020-2343</p>
                                                    </div>
                                                    <div className="Vulnerabilities-list-details-second">
                                                        <p>CWE-400</p>
                                                    </div>
                                                </div>
                                                <div className="Vulnerabilities-list">
                                                    <div className="Vulnerabilities-list-image">
                                                        <img src={small_icon} alt="Vulnerabilities type" />
                                                    </div>
                                                    <div className="Vulnerabilities-name">
                                                        <p>Command Injection</p>
                                                    </div>
                                                    <div className="Vulnerabilities-list-details">
                                                        <p>CVE-2020-2343</p>
                                                    </div>
                                                    <div className="Vulnerabilities-list-details-second">
                                                        <p>CWE-400</p>
                                                    </div>
                                                </div>
                                                <div className="Vulnerabilities-list">
                                                    <div className="Vulnerabilities-list-image">
                                                        <img src={small_icon} alt="Vulnerabilities type" />
                                                    </div>
                                                    <div className="Vulnerabilities-name">
                                                        <p>Command Injection</p>
                                                    </div>
                                                    <div className="Vulnerabilities-list-details">
                                                        <p>CVE-2020-2343</p>
                                                    </div>
                                                    <div className="Vulnerabilities-list-details-second">
                                                        <p>CWE-400</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="scanned-projects-section">

                            <div className="scanned-heading-with-filter">
                                <h2>Scanned Projects</h2>
                                {/* <div className="scanned-projects-filters">
                                    <p>Filters</p>
                                    <div className="filters-arrow" id="filters-arrow" onClick={() => handleFilterOptionClick()} >
                                        <img src={downArrow} alt="down arrow" />
                                    </div>
                                </div> */}
                                <div className="filters-modal" id="filters-modal">
                                    <form>
                                        <div className="form-field">
                                            <label for="View All">View All</label>
                                            <input type="radio" id="View All" name="filters" />
                                        </div>

                                        <div className="form-field">
                                            <label for="Website">Website</label>
                                            <input type="radio" id="Website" name="filters" />

                                        </div>
                                        <div className="form-field">
                                            <label for="Docker">Docker</label>
                                            <input type="radio" id="Docker" name="filters" />

                                        </div>
                                        <div className="form-field">
                                            <label for="Github">Github</label>
                                            <input type="radio" id="Github" name="filters" />

                                        </div>
                                        <div className="form-field">
                                            <label for="Vulnerability High to low">Vulnerability High to low</label>
                                            <input type="radio" id="Vulnerability High to low" name="filters" />

                                        </div>
                                        <div className="form-field">
                                            <label for="Vulnerability Low to high">Vulnerability Low to high</label>
                                            <input type="radio" id="Vulnerability Low to high" name="filters" />

                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="row no-gutters scanned-projects-row">
                                {project_list.length > 0 &&
                                    project_list.map((info,index) => (
                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                            <div className="scanned-projects-cards-outside">

                                                <div className="scanned-projects-cards-inside">
                                                    <div className="scanned-projects-cards-heading">

                                                        {
                                                            info.request_info.status === "WebsiteScan"
                                                                ? <img src={project1} alt="projects-details" />
                                                                :
                                                                <img src={total_projects} alt="projects-details" />

                                                        }
                                                        <h3>{info.request_info.project_name}</h3>
                                                        <p>{info.request_info.status}</p>
                                                        <div className="time-last-scanned">
                                                            <p>{moment(info.request_info.timestamp * 1000).format("h:mm a MMM DD, YYYY")}</p>
                                                        </div>
                                                    </div>
                                                    <div className="scanned-projects-category-list">
                                                        <div className="list-items">
                                                            <p>Critical</p>
                                                            <p>{info.critical}</p>
                                                        </div>
                                                        <div className="list-items">
                                                            <p>High</p>
                                                            <p>{info.high}</p>
                                                        </div>
                                                        <div className="list-items">
                                                            <p>Medium</p>
                                                            <p>{info.medium}</p>
                                                        </div>
                                                        <div className="list-items">
                                                            <p>Low</p>
                                                            <p>{info.low}</p>
                                                        </div>
                                                        <button onClick={() => this.handleDetailReport(
                                                                    info, index
                                                                )}>View Detailed Report
                                                            <img src={rightArrow} alt="projects-details" />
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    ))}

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );


    }
}

function mapStateToProps(state) {
    const {
        //getscanscore,
        scanId,
        vulnerabilities_count,
        critical,
        high,
        medium,
        low,
        project_count,
        project_list,
        token,
        scanID
    } = state.getScoreReducer;
    const { postScanError} = state.scoreReducer;

    return {
        // getscanscore,
        scanId,
        vulnerabilities_count,
        critical,
        token,
        high,
        medium,
        low,
        project_count,
        project_list,
        scanID,
        postScanError

    };
}

export default withRouter(connect(mapStateToProps)(Dashboard));
