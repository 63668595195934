import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    UPDATE_SCAN,
    UPDATE_LOGIN_FORM_DATA,
    CLEAR_DATA,
    REFRESH_TOKEN_REQUEST, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILURE
} from "./constants";
import { baseApi } from "../../../common/ApiAction/index";
import { GET_API } from "../../../middleware/symbols";

const BASE_URL = baseApi();

export function updateLoginForm(data) {
    return { type: UPDATE_LOGIN_FORM_DATA, data }
}

function requestLogin(creds) {
    return {
        type: LOGIN_REQUEST,
        creds,
    };
}
export function refreshToken() {

    return {
        [GET_API]: {
            endpoint: BASE_URL + '/v1/refresh_token',
            types: [REFRESH_TOKEN_REQUEST, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILURE],
            authenticated: true
        }
    }
}
export function receiveLogin(user) {
    return {
        type: LOGIN_SUCCESS,
        user,
    };
}

export function loginError(data) {
    return {
        type: LOGIN_FAILURE,
        data,
    };
}
export function clearData(data) {
    return {
        type: CLEAR_DATA,
        data,
    };
}

export function login(creds) {

    let config = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(creds),
    };
    return (dispatch) => {
        // We dispatch requestLogin to kickoff the call to the API
        dispatch(requestLogin(creds));
        fetch(BASE_URL + "/v1/login", config)
            .then(function (response) {
                return response.json();
            })
            .then(
                function (user) {
                    if (user.error === true) {
                        dispatch(loginError(user));
                    } else {
                        localStorage.setItem("token", user.token);
                        localStorage.setItem('loginName', user.name)
                        localStorage.setItem('loginEmail', user.email)
                        dispatch(receiveLogin(user));

                    }
                },
                function (error) {
                    dispatch(
                        loginError({ error: true, message: "Internal Server Error" })
                    );
                }
            );
    };
}



export function updateScan(data) {
    return {
        type: UPDATE_SCAN,
        data,
    };
}


