import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { websiteURL } from '../../../common/ApiAction/index'
import './dist/style.css';
import './dist/header-dropdown.css';
import './dist/background.css'
import HamBurger from './dist/hamburger.svg';
import DownArrow from './dist/drop-down.svg';
import { clearUrl } from '../checkFreeScore/actions';
import header_Cloud_Native_Security from './dist/header-Cloud Native Security.svg'
import header_compliance_management from './dist/header-Compiance Management.svg'
import header_vulnerability_management from './dist/header-Vulnerability Management.svg'
import container_security from './dist/container-security.svg'
import solution_serverless_security from './dist/solution-serverless-security.svg'
import kubernetes from'./dist/kubernetes.svg'
import application_security from './dist/application-security.svg'
import iac_security from './dist/iac-security.svg'
import code_security from './dist/code-security.svg'
import devsecops from './dist/devsecops.svg'
import observability from'./dist/observability.svg'
import machine_learnng_security from './dist/machine-learning-security.svg'
import dependency_scanning from './dist/dependency-scanning.svg'
import automate_devops from './dist/automate-devops.svg'
import continuous_security from './dist/use-case-continous-security.svg'
import aws_cloud_security from './dist/use-case-aws-cloud-security (1).svg'
import google_cloud_security from './dist/use-case-aws-cloud-security.svg'
import on_premises from './dist/use-case-on-premises.svg'
import azure_security from './dist/use-case-azure.svg'
import multi_cloud from './dist/use-case-multi-cloud.svg'
import metasecure_observability_cloud from './dist/metasecure-observability-cloud-siem.svg'
import metasecure_observability_managed from './dist/metasecure-observability-managed-soc.svg'
import metasecure_observability_threat from './dist/metasecure-observability-threat-intelligence.svg'
import drop_down from './dist/drop-down.svg'

const WEBSITE_URL = websiteURL();
class Header extends Component {  
  constructor(props){
    super(props);
    this.state= {
      mainHeaderAccordion : [
        {name: 'Platform', isOpen: false},
        {name: 'Solutions', isOpen: false},
        {name: 'Use Case', isOpen: false},
        {name: 'Observability', isOpen: false}
      ]
  }
  }

  
  openInviteModal() {
    this.props.dispatch(clearUrl());
    document.querySelector('#invite').style.display = 'grid';
  }
  showMenu() {
    const els = document.querySelectorAll('.nav-li');
    els.forEach((el, i) => {
      if (i > 0) {

        if (el.style.display === '' || el.style.display === 'none') {
          el.style.display = 'flex';
          document.querySelector('.header').style.display = "block";
          let element = document.getElementById("headerblock");
          element.classList.add("whiteheader");
        } else {
          el.style.display = '';
          document.querySelector('.header').style.display = "";
          let element = document.getElementById("headerblock");
          element.classList.remove("whiteheader");
        }
      }
    });
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      var els = document.querySelectorAll('.nav-li');
      if (window.innerWidth < 1023) {
        els.forEach((el, i) => i > 0 && (el.style.display = 'none'));
      } else {
        els.forEach((el) => (el.style.display = 'flex'));
      }
    });

    window.addEventListener('scroll', (event) => {
      this.addRemoveClass();
    })
  }

  // // header dropdown accordian 

  showDropdown(DropdownElement) {
    const selectedAccordianName = DropdownElement.split('-')[1].replaceAll(" ", "").toLowerCase();
    let isSelectedAcordionOpen;

    let curAccordionState = this.state.mainHeaderAccordion.map(curAccordion => {
      if(curAccordion.name.replaceAll(" ", "").toLocaleLowerCase() === selectedAccordianName) {
        curAccordion.isOpen = !curAccordion.isOpen;
        isSelectedAcordionOpen = curAccordion.isOpen;
      }
      return curAccordion;
    });
 
    this.setState({mainHeaderAccordion: curAccordionState})
   
    if (isSelectedAcordionOpen) {
      let arrowElement = document.querySelectorAll(`#${DropdownElement} a`);
      let arrowImage = arrowElement[0].firstElementChild;
      arrowImage.classList.add('open-dropdown');

      var element = document.getElementById(DropdownElement).children[1];
      element.classList.add("nav-megamenuShow");
    }
    else {
      let arrowElement = document.querySelectorAll(`#${DropdownElement} a`);
      let arrowImage = arrowElement[0].firstElementChild;
      arrowImage.classList.remove('open-dropdown');

      var element1 = document.getElementById(DropdownElement).children[1];
      element1.classList.remove("nav-megamenuShow");
    }
  }
  showDropdownShadow() {
    let element = document.getElementById("headerblock");
    element.classList.add("headershadow-dropdown");
  }
  hideDropdown() {
    let element = document.getElementById("headerblock");
    element.classList.remove("headershadow-dropdown");
  }

  // // header dropdown accordian 


  // // Header Dropdown and mobile view=======================================================

  // // ===================background color and position fixed for header=======================
  addRemoveClass() {
    let element = document.getElementById("headerblock");
    if (window.pageYOffset >= 10) {
      if (element !== null) {
        element.classList.add("headershadow");
      }
    } else {
      if (element !== null) {
        element.classList.remove("headershadow");
      }
    }
  }

  render() {
    return (
      <header class="header-wrapper" id="headerblock">
        <div class="container header-container">
          <div class="mobile-header-block">
            <div class="mobile-header-inner">
              <a href="https://www.metasecure.ai/">
                <img src="https://f.hubspotusercontent-eu1.net/hubfs/24902366/Home%20Page%20Rough%20Design_004_047%20Logo%20for%20ligheter%20Surface.svg" alt="" id="uncolorLogo" width="200" height="50" />
              </a>
              <div class="mobile-right">
                <div class="mobile-search"></div>
                <div class="menu-icon"> <img src={HamBurger} class="xenonstack-hamburger"
                  alt="Xenonstack Hamburger" onClick={this.showMenu.bind(this)} /> </div>
              </div>
            </div>
          </div>
          <nav class="header">
            <ul class="nav-ul">
              <li class="nav-li logo-li">
                <a href="https://www.metasecure.ai/">
                  <img src="https://f.hubspotusercontent-eu1.net/hubfs/24902366/Home%20Page%20Rough%20Design_004_047%20Logo%20for%20ligheter%20Surface.svg" alt="" id="uncolorLogo" width="200" height="50" />

                </a>
                <div class="menu-icon">
                  <i class="fa fa-bars" onClick={this.showMenu.bind(this)}></i>
                </div>
              </li>
              <li class="nav-li platform-li item" onClick={() => this.showDropdown('nav-platform')} id="nav-platform" onMouseEnter={this.showDropdownShadow.bind(this)} onMouseLeave={this.hideDropdown.bind(this)}>
                <a href="#" class="arrow">Platform
                  <img src={DownArrow} alt="" />
                </a>
                <div class="nav-megamenu" id="nav-megamenu">
                  <div class="nav-megamenu-inner">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="nav-megamenu-right">
                          <div class="container">
                          <div class="row grid3">
                            <div
                              class="col-lg-4 col-md-4 col-sm-4 col-12 padd-30"
                            ><a href="https://www.xenonstack.com/enterprise/cloud-native-security/">
                              <div class="service-block">
                                  <img
                                    alt="platform-dropdown"
                                    src={header_Cloud_Native_Security}
                                  />
                                  <h4>Cloud Native Security</h4>
                                  <p>
                                    Respond to cyber threats, and protect your
                                    cloud-native applications with zero-trust
                                    and defense-in-depth.
                                  </p>
                              </div>
                                </a>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-sm-4 col-12 padd-30"
                            >
                            <a href="https://www.xenonstack.com/enterprise/vulnerability-management/">
                              <div class="service-block">
                                  <img
                                    alt="platform-dropdown"
                                    src={header_vulnerability_management}
                                  />
                                  <h4>Vulnerability Management</h4>
                                  <p>
                                    Assess your enterprise applications, whether
                                    they are deployed in the cloud or
                                    on-premises or vulnerabilities.
                                  </p>
                              </div>
                                </a>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-sm-4 col-12 padd-30"
                            >
                            <a href="https://www.xenonstack.com/enterprise/compliance-management/">
                              <div class="service-block">
                                  <img
                                    alt="platform-dropdown"
                                    src={header_compliance_management}
                                  />
                                  <h4>Compliance Management</h4>
                                  <p>
                                    Continuous compliance by enforcing and
                                    auditing infrastructure and applications
                                    against compliance requirements.
                                  </p>
                              </div>
                                </a>
                            </div>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mobile-nav">
                  <a href="https://www.xenonstack.com/enterprise/cloud-native-security/">Cloud Native Security</a>
                  <a href="https://www.xenonstack.com/enterprise/vulnerability-management/"
                    >Vulnerability Management</a
                  >
                  <a href="https://www.xenonstack.com/enterprise/compliance-management/">Compliance Management</a>
                  </div>
                </div>
              </li>
              <li class="nav-li item" onClick={() => this.showDropdown('nav-solutions')} onMouseEnter={this.showDropdownShadow.bind(this)} onMouseLeave={this.hideDropdown.bind(this)}
                id="nav-solutions">
                <a href="#" class="arrow">Solutions
                  <img src={DownArrow} />
                </a>
                <div class="nav-megamenu" id="nav-megamenu">
                  <div class="nav-megamenu-inner">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="nav-megamenu-right">
                          <div class="container">
                          <div
                        class="row no-gutters solutions-dropdown-listside-row"
                      >
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                          <a href="https://www.xenonstack.com/enterprise/container-security/">
                            <div class="solutions-dropdown-listside">
                              <img
                                src={container_security}
                                loading="lazy"
                                class=""
                                alt="XenonStack GitOps with Continuous Delivery Image"
                              />
                              <div
                                class="xenonstack-heading-inner-details-text"
                              >
                                <h4>Container Security</h4>
                                <p>
                                  Get complete container protection with
                                  Metasecure
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                          <a href="https://www.xenonstack.com/enterprise/serverless-security/">
                            <div class="solutions-dropdown-listside">
                              <img
                                src={solution_serverless_security}
                                loading="lazy"
                                class=""
                                alt="XenonStack GitOps with Continuous Delivery Image"
                              />
                              <div
                                class="xenonstack-heading-inner-details-text"
                              >
                                <h4>Serverless Security</h4>
                                <p>
                                  Secure your serverless functions by
                                  Metasecure's runtime protection.
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                          <a href="https://www.xenonstack.com/technology-consulting/kubernetes-security/">
                            <div class="solutions-dropdown-listside">
                              <img
                                src={kubernetes}
                                loading="lazy"
                                class=""
                                alt="XenonStack GitOps with Continuous Delivery Image"
                              />
                              <div
                                class="xenonstack-heading-inner-details-text"
                              >
                                <h4>Kubernetes Security</h4>
                                <p>
                                  Secure your clusters with Metasecure's
                                  end-to-end Kubernetes Security.
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                          <a href="https://www.xenonstack.com/enterprise/application-security/">
                            <div class="solutions-dropdown-listside">
                              <img
                                src={application_security}
                                loading="lazy"
                                class=""
                                alt="XenonStack GitOps with Continuous Delivery Image"
                              />
                              <div
                                class="xenonstack-heading-inner-details-text"
                              >
                                <h4>Application Security</h4>
                                <p>
                                  Secure your applications with Metasecure's
                                  security solutions and enforced security
                                  procedures.
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                          <a href="https://www.xenonstack.com/enterprise/infrastructure-as-code-security/">
                            <div class="solutions-dropdown-listside">
                              <img
                                src={iac_security}
                                loading="lazy"
                                class=""
                                alt="XenonStack GitOps with Continuous Delivery Image"
                              />
                              <div
                                class="xenonstack-heading-inner-details-text"
                              >
                                <h4>IAC Security</h4>
                                <p>
                                  Write secure configurations, identify
                                  misconfigurations and security issues with
                                  Metasecure IAC.
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                          <a href="https://www.xenonstack.com/enterprise/code-security/">
                            <div class="solutions-dropdown-listside">
                              <img
                                src={code_security}
                                loading="lazy"
                                alt="XenonStack GitOps with Continuous Delivery Image"
                              />
                              <div
                                class="xenonstack-heading-inner-details-text"
                              >
                                <h4>Code Security</h4>
                                <p>
                                  Secure your code at every stage with automatic
                                  scanning and remediate vulnerabilities.
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                          <a href="https://www.xenonstack.com/enterprise/devsecops/">
                            <div class="solutions-dropdown-listside">
                              <img
                                src={devsecops}
                                loading="lazy"
                                class=""
                                alt="XenonStack GitOps with Continuous Delivery Image"
                              />
                              <div
                                class="xenonstack-heading-inner-details-text"
                              >
                                <h4>DevSecOps</h4>
                                <p>
                                  Metasecure encourages security adoption across
                                  the software development lifecycle.
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                          <a href="https://www.xenonstack.com/insights/what-is-observability">
                            <div class="solutions-dropdown-listside">
                              <img
                                src={observability}
                                loading="lazy"
                                class=""
                                alt="XenonStack GitOps with Continuous Delivery Image"
                              />
                              <div
                                class="xenonstack-heading-inner-details-text"
                              >
                                <h4>Security Observability</h4>
                                <p>
                                  Add intelligence to your monitoring solutions
                                  and determine the true root cause with
                                  Metasecure.
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                          <a href="https://www.xenonstack.com/blog/machine-learning-security">
                            <div class="solutions-dropdown-listside">
                              <img
                                src={machine_learnng_security}
                                loading="lazy"
                                alt="XenonStack GitOps with Continuous Delivery Image"
                              />
                              <div
                                class="xenonstack-heading-inner-details-text"
                              >
                                <h4>Machine Learning Security</h4>
                                <p>
                                  Detect Malware through analyzing data and
                                  finding patterns with Metasecure.
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mobile-nav">
                  <a href="https://www.xenonstack.com/enterprise/container-security/">Container Security</a>
                  <a href="https://www.xenonstack.com/enterprise/serverless-security/">Serverless Security</a>
                  <a href="https://www.xenonstack.com/technology-consulting/kubernetes-security/">Kubernetes Security</a>
                  <a href="https://www.xenonstack.com/enterprise/application-security/">Application Security</a>
                  <a href="https://www.xenonstack.com/enterprise/infrastructure-as-code-security/">IAC Security</a>
                  <a href="https://www.xenonstack.com/enterprise/code-security/">Code Security</a>
                  <a href="https://www.xenonstack.com/enterprise/devsecops/">DevSecOps</a>
                  <a href="https://www.xenonstack.com/insights/what-is-observability">Security Observability</a>
                  <a href="https://www.xenonstack.com/blog/machine-learning-security">Machine Learning Security</a>
                  </div>
                </div>
              </li>
              <li class="nav-li item" onClick={() => this.showDropdown('nav-usecase')} onMouseEnter={this.showDropdownShadow.bind(this)} onMouseLeave={this.hideDropdown.bind(this)} id="nav-usecase">
                <a href="#" class="arrow">Use Cases
                  <img alt="down-arrow" src={DownArrow}/>
                </a>
                <div class="nav-megamenu" id="nav-megamenu">
                  <div class="nav-megamenu-inner">
                    <div class="row usecase-dropdown-row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="nav-megamenu-right">
                          <div class="container">
                            <div class="row grid3">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-12 padd-30">
                                    <a href="https://www.xenonstack.com/enterprise/dependency-scanning/">
                                {/* <a href={WEBSITE_URL + '/coming-soon'}> */}
                                  <div class="usecase-dropdown">
                                    <div>
                                      <img alt="use-case-dependency-scanning" src={dependency_scanning} />
                                    </div>
                                    <div>
                                    <h4>Dependency Scanning</h4>
                                    </div>
                                  </div>
                                  </a>
                              </div>
                              <div class="col-lg-4 col-md-4 col-sm-4 col-12 padd-30">
                                    <a href="https://www.xenonstack.com/services/devops/ ">
                                {/* <a href={WEBSITE_URL + '/coming-soon'}> */}
                                  <div class="usecase-dropdown">
                                    <div>
                                      <img alt="use-case-automate-devops" src={automate_devops} />
                                    </div>
                                    <div>
                                    <h4>Automate DevOps</h4>
                                    </div>
                                  </div>
                                  </a>
                              </div>
                              <div class="col-lg-4 col-md-4 col-sm-4 col-12 padd-30">
                                  <a href="https://www.xenonstack.com/enterprise/continuous-security/">
                                {/* <a href={WEBSITE_URL + '/coming-soon'}> */}
                                  <div class="usecase-dropdown">
                                    <div>
                                      <img alt="use-case-continous-security" src={continuous_security} />
                                    </div>
                                    <div>
                                    <h4>Continuous Security</h4>
                                </div>
                                  </div>
                                  </a>
                              </div>
                              <div class="col-lg-4 col-md-4 col-sm-4 col-12 padd-30">
                                  <a href="https://www.xenonstack.com/technology-consulting/aws-cloud-security/">
                                {/* <a href={WEBSITE_URL + '/coming-soon'}> */}
                                  <div class="usecase-dropdown">
                                    <div>
                                      <img alt="use-case-aws-cloud-security" src={aws_cloud_security}/>
                                    </div>
                                    <div>
                                    <h4>AWS Cloud Security</h4>
                                </div>
                              </div>
                                  </a>
                              </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 col-12 padd-30">
                                  <a href="https://www.xenonstack.com/technology-consulting/google-cloud-security/ ">
                              {/* <a href={WEBSITE_URL + '/coming-soon'}> */}
                                <div class="usecase-dropdown">
                                  <div>
                                    <img alt="use-case-google-cloud-security" src={google_cloud_security} />
                                  </div>
                                  <div>
                                    <h4>Google Cloud Security</h4>
                                </div>
                                </div>
                                  </a>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 col-12 padd-30">
                                  <a href="https://www.xenonstack.com/on-prem-ai-cluster/ ">
                              {/* <a href={WEBSITE_URL + '/coming-soon'}> */}
                                <div class="usecase-dropdown">
                                  <div>
                                    <img alt="use-case-on-premises" src={on_premises} />
                                  </div>
                                  <div>
                                    <h4>On-Premises Security</h4>
                                </div>
                                </div>
                                  </a>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 col-12 padd-30">
                                  <a href="https://www.xenonstack.com/technology-consulting/azure-cloud-security/ ">
                              {/* <a href={WEBSITE_URL + '/coming-soon'}> */}
                                <div class="usecase-dropdown">
                                  <div>
                                    <img alt="use-case-azure" src={azure_security} />
                                  </div>
                                  <div>
                                    <h4>Azure Security</h4>
                                </div>
                                </div>
                                  </a>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 col-12 padd-30">
                                  <a href="https://www.xenonstack.com/insights/multi-cloud-security-best-practices/ ">
                              {/* <a href={WEBSITE_URL + '/coming-soon'}> */}
                                <div class="usecase-dropdown">
                                  <div>
                                    <img alt="use-case-multi-cloud" src={multi_cloud} />
                                  </div>
                                  <div>
                                    <h4>Multi Cloud Security</h4>
                                </div>
                                </div>
                                  </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
                  <div class="mobile-nav">
                  <a href="https://www.xenonstack.com/enterprise/dependency-scanning/"
                    >Dependency Scanning</a
                  >
                  <a href="https://www.xenonstack.com/services/devops/ ">Automate DevOps</a>
                  <a href="https://www.xenonstack.com/enterprise/continuous-security/">Continuous Security</a>
                  <a href="https://www.xenonstack.com/technology-consulting/aws-cloud-security/">AWS Cloud Security</a>
                  <a href="https://www.xenonstack.com/technology-consulting/google-cloud-security/ ">Google Cloud Security</a>
                  <a href="https://www.xenonstack.com/on-prem-ai-cluster/ ">On-Premises Security</a>
                  <a href="https://www.xenonstack.com/technology-consulting/azure-cloud-security/">Azure Security</a>
                  <a href="https://www.xenonstack.com/insights/multi-cloud-security-best-practices/ ">Multi Cloud Security</a>
                </div>
                </div>
              </li>
              <li class="nav-li item" onClick={() => this.showDropdown('nav-observability')} onMouseEnter={this.showDropdownShadow.bind(this)} onMouseLeave={this.hideDropdown.bind(this)}
                id="nav-observability">
              <a href="#" class="arrow"
                >Observability
                <img
                  alt="down-arrow"
                width="12"
                  src={DownArrow}
                />
              </a>
              <div class="nav-megamenu" id="nav-megamenu">
                <div class="nav-megamenu-inner">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="nav-megamenu-right">
                        <div class="container">
                          <div class="row grid3">
                            <div
                              class="col-lg-4 col-md-4 col-sm-4 col-12 padd-30"
                            >
                            <a href="https://www.xenonstack.com/cloud-siem-managed/ ">
                              <div class="service-block">
                                  <img
                                    alt="platform-dropdown"
                                    src={metasecure_observability_cloud}
                                  />
                                  <h4>Cloud SIEM</h4>
                                  <p>
                                    Real-time security alerts with
                                    next-generation detection, analytics, and
                                    response.
                                  </p>
                              </div>
                                </a>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-sm-4 col-12 padd-30"
                            >
                            <a href="https://www.xenonstack.com/managed-services/secops/ ">
                              <div class="service-block">
                                  <img
                                    alt="platform-dropdown"
                                    src={metasecure_observability_managed}
                                  />
                                  <h4>Managed SOC</h4>
                                  <p>
                                    Detect, analyze and respond to cyber
                                    security incidents and threats across the
                                    cloud.
                                  </p>
                              </div>
                                </a>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-sm-4 col-12 padd-30"
                            >
                            <a href="https://www.xenonstack.com/use-cases/threat-analytics ">
                              <div class="service-block">
                                  <img
                                    alt="platform-dropdown"
                                    src={metasecure_observability_threat}
                                  />
                                  <h4>Threat Intelligence</h4>
                                  <p>
                                    Fast and robust threat detection and
                                    response.
                                  </p>
                              </div>
                                </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mobile-nav">
                    <a href={WEBSITE_URL + "/container-security"}>Container Security</a>
                    <a href={WEBSITE_URL + "/kubernetes-security"}>Kubernetes Security</a>
                    <a href={WEBSITE_URL + "/application-security"}>Application Security</a>
                    <a href={WEBSITE_URL + "/serverless-security"}>Serverless Security</a>
                    <a href={WEBSITE_URL + '/coming-soon'}>Code Security</a>
                    <a href={WEBSITE_URL + '/coming-soon'}>IAC Security</a>
                  </div>
                </div>
                <div class="mobile-nav">
                  <a href="https://www.xenonstack.com/cloud-siem-managed/">Cloud SIEM</a>
                  <a href="https://www.xenonstack.com/managed-services/secops/ ">Managed SOC</a>
                  <a href="https://www.xenonstack.com/use-cases/threat-analytics ">Threat Intelligence</a>
                </div>
                <div class="mobile-nav">
                  <a href="https://www.xenonstack.com/cloud-siem-managed/">Cloud SIEM</a>
                  <a href="https://www.xenonstack.com/managed-services/secops/ ">Managed SOC</a>
                  <a href="https://www.xenonstack.com/use-cases/threat-analytics ">Threat Intelligence</a>
                </div>
              </div>
              </li>
              {/* <li class="nav-li button-item">
                                <button class="nav-button">
                                    <a href={WEBSITE_URL + '/coming-soon'}
                                    >Login/SignUp</a>

                                </button>
                            </li> */}
              <li class="nav-li button-item">
                <button
                  class="nav-button"
                  type="button"
                  onClick={this.openInviteModal.bind(this)}
                >
                  Get Assessment
                </button>
              </li>

            </ul>
          </nav>
        </div>
      </header>

    );
  }
}
function mapStateToProps(state) {
  const {
    scanData,
  } = state.checkScoreReducer;

  return {
    scanData,
  };
}
export default withRouter(connect(mapStateToProps)(Header));
