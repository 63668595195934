import React, { Component } from 'react';
import './dist/style.css';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { resetData, resetPassword } from './actions';
import Request_Demo_Page_Illustration_Object_Big_left from '../dist/Request Demo Page_Illustration-Object-Big-left.svg';
import Request_Demo_Page_Illustration_Object_small_right from "../dist/Request Demo Page_Illustration-Object-small-right.svg";
import noun_Eye from "../dist/noun_Eye_497740.svg";
import Header from '../header/index';
import Footer from '../../checkFreeScore/Footer/index';

class ResetPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hidden: true,
        };
    }
    componentDidMount() {
        this.props.dispatch(resetData());
    }

    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.forgotPasswordSuccessMsg === "Password reset successfully." && nextProps.resetPasswordData.password !== '' && nextProps.resetPasswordData.confirmpassword !== ''){
            if ( nextProps.resetPasswordData.password === nextProps.resetPasswordData.confirmpassword) {
                nextProps.history.push('/auth')
            }
        }
        else {
            //DO NOTHING
        }
    }
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
        let name = e.target.name;
        let value = e.target.value;
        this.setState({ [name.name]: value });
        if (e.target.checkValidity()) {
            e.target.classList.remove('has-error');
            e.target.nextElementSibling.classList.remove('has-error');
        } else {
            e.target.classList.add('has-error');
            e.target.nextElementSibling.classList.add('has-error');
        }
        if (name === 'confirmpassword') {
            if (
                this.props.resetPasswordData.password ===
                this.props.resetPasswordData.confirmpassword
            ) {
                e.target.classList.remove('has-error');
                e.target.nextElementSibling.classList.remove('has-error');
            } else {
                e.target.nextElementSibling.classList.add('has-error');
            }
        } 
    }

    handleOnSubmit(e) {
        //if invitation form is valid then submit the data, else show the validation message on the invalid field.
        e.preventDefault()
        if (this.props.resetPasswordData.password === '') {
            document.getElementById('login-password').classList.add('has-error')
        }
        if (this.props.resetPasswordData.confirmpassword === '') {
            document.getElementById('conpassword').classList.add('has-error')
        }
        if(this.props.resetPasswordData.password === this.props.resetPasswordData.confirmpassword ){
            if (e.target.checkValidity()) {
                const token = new URL(window.location.href).searchParams.get('token')
               if (new URL(window.location.href).searchParams.get('token')) {
             
                localStorage.setItem('token', token)
               }
               
                let formData = {
                    'password': this.props.resetPasswordData.password,
                    'token': token,
                    'state': 'reset'
                }
                this.props.dispatch(resetPassword(formData))            
            }
        }

        
    }

 

    // WILL BE NEEDED IN FUTURE

    // componentDidMount(){
    //     if(url.searchParams.get("token")==null){
    //       this.props.history.push('/auth')
    //     }
    //   }

    render() {
        const { resetPasswordData,forgotPasswordSuccessMsg,forgotPasswordError } = this.props;
        return (
            <>
             <Header backgroundColor={"yellowHeader"}/>
                <div class="form-background">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-2 col-md-2 col-lg-2 image-leftside">
                                <div class="form-inside-wrapper-imageSide">
                                    <div class="image-leftside-wrapper">
                                        <img src={Request_Demo_Page_Illustration_Object_Big_left} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-8 col-md-8 col-lg-8 resetpassword-form">
                                <div class="form-wrapper">
                                    <div class="form-heading">
                                        <p>Create new password</p>
                                    </div>
                                    <form action="" className="ResetPasswordForm"
                                        noValidate={true} id="ResetPasswordForm"
                                        onSubmit={this.handleOnSubmit.bind(this)}>
                                        <div class="form-field">
                                            <div class="inline-fields">
                                                <div class="first-field no-icon">
                                                    <input
                                                        required
                                                        name="password"
                                                        id="login-password"
                                                        type={this.state.hidden ? "password" : "text"}
                                                        placeholder="Password"
                                                        value={resetPasswordData.password}
                                                        onChange={this.handleChange.bind(this)}
                                                        pattern={
                                                            '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
                                                        }
                                                    />
                                                     <span
                                                        class="errors"
                                                        id="password-error"
                                                    >
                                                        <div className="input-error" style={{ color: "unset" }}>Enter your
                                                            password (<span
                                                                style={{ color: `${resetPasswordData.password.length >= 8 ? "green" : "#f03030"}` }}>Min 8 char.</span>, <span
                                                                    style={{ color: `${resetPasswordData.password.match("(?=.*?[A-Z])") ? "#279627" : "#f03030"}` }}>at least one uppercase letter</span>, <span
                                                                        style={{ color: `${resetPasswordData.password.match("(?=.*?[a-z])") ? "#279627" : "#f03030"}` }}>one lowercase letter</span>, <span
                                                                            style={{ color: `${resetPasswordData.password.match("(?=.*?[0-9])") ? "#279627" : "#f03030"}` }}>one number , and </span>
                                                            <span
                                                                style={{ color: `${resetPasswordData.password.match("(?=.*?[#?!@$%^&*-])") ? "#279627" : "#f03030"}` }}>one special character required</span>).
                                                        </div>

                                                    </span>
                                                    <img src={noun_Eye} alt="" id="toggleeye" onClick={this.toggleShow.bind(this)} />
                                                   
                                                </div>
                                                <div class="last-field no-icon">
                                                    <input
                                                        required
                                                        type="password"
                                                        name="confirmpassword"
                                                        id="conpassword"
                                                        placeholder="Confirm Password"
                                                        value={
                                                            resetPasswordData.confirmpassword
                                                        }
                                                        onChange={this.handleChange.bind(this)} />
                                                    <span
                                                        class="errors"
                                                        id="password-error"
                                                    >
                                                        Password doesn't
                                                        match
                                                    </span>

                                                </div>
                                            </div>
                                        </div>
                                        <input type="submit" id="submit" value="Create new password"
                                        />
                                        {forgotPasswordError === true &&
                                            <div className="center-error form-error">
                                                <p>{forgotPasswordSuccessMsg}</p>
                                            </div>
                                            
                                        }
                                    </form>
                                    <br />
                                    <div class="login-link">
                                        <NavLink to="/auth/login">Go back to login</NavLink>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-2 col-md-2 col-lg-2 image-rightside">
                                <div class="form-inside-wrapper-imageSide">
                                    <div class="image-rightside-wrapper">
                                        <img src={Request_Demo_Page_Illustration_Object_small_right} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

function mapStateToProps(state) {
    const { resetPasswordData ,forgotPasswordSuccessMsg,forgotPasswordError} = state.resetPassReducer;
    return { resetPasswordData ,forgotPasswordSuccessMsg,forgotPasswordError}
}

export default withRouter(connect(mapStateToProps)(ResetPassword));
