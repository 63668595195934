import React, { Component } from 'react';
import './dist/style.css';
import { updateLoginForm, login, clearData } from './actions';
import { connect } from 'react-redux'
import { withRouter, NavLink } from 'react-router-dom';
import Request_Demo_Page_Illustration_Object_Big_left from '../dist/Request Demo Page_Illustration-Object-Big-left.svg';
import noun_envelope from '../dist/noun_envelope_4125604.svg';
import noun_password from '../dist/noun_password_2730497.svg'
import google_g_2015 from '../dist/google-g-2015.svg';
import Octicons_mark_github from "../dist/Octicons-mark-github.svg";
import Request_Demo_Page_Illustration_Object_small_right from "../dist/Request Demo Page_Illustration-Object-small-right.svg";
import noun_Eye from "../dist/noun_Eye_497740.svg";
import { baseApi } from "../../../common/ApiAction/index";
import Header from '../header/index';
import Footer from '../../checkFreeScore/Footer/index';
const BASE_API = baseApi();
class LoginForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
        };
    }

    toggleShow() {
        this.setState({ hidden: !this.state.hidden });
    }

    componentDidMount() {
        let search = this.props.location.search;
        let link = new URLSearchParams(search).get('token')
        if (link !== '' ) {
            // null value is setted below
            localStorage.setItem('token',link)               
        }
        let type = new URLSearchParams(search).get('type')
        if (type !== '' ) {
            // null value is setted below
            localStorage.setItem('token',link)               
        }


        if (this.props.isAuthenticated) {
            //this.props.history.push('/layout')
            this.props.dispatch(clearData())
        }
        // might be needed in future
        // if (new URL(window.location.href).searchParams.get('token')) {
        //     const token = new URL(window.location.href).searchParams.get('token')
        //     this.props.history.push('/layout/dashboard')

        // }
        let urlName = new URLSearchParams(search).get('name')
        let urlEmail = new URLSearchParams(search).get('email')
        if (urlName) {
            localStorage.setItem('loginName', urlName)
            localStorage.setItem('loginEmail', urlEmail)
        }

    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.postLoginError && nextProps.postLoginMessage === 'Login Successful') {
            localStorage.setItem('token',nextProps.loginToken)
            this.props.history.push('/layout')
            
        }
        let search = this.props.location.search;
        let link = new URLSearchParams(search).get('token')
        let type = new URLSearchParams(search).get('type')
        if (link) {
            localStorage.setItem('token', new URLSearchParams(search).get('token'))
            this.props.history.push('/layout/githubchoose')
  
        }
        if (type) {
            localStorage.setItem('token', new URLSearchParams(search).get('token'))
            this.props.history.push('/layout/integration')
        }
        else if (nextProps.isAuthenticated) {
            // this.props.history.push('/layout')
        }
        else {
            //DO NOTHING
        }
    }

    handleDataChange(e) {
        this.setState({ [e.target.name]: e.target.value });
        let name = e.target.name;
        let value = e.target.value;
        this.setState({ [name.name]: value });
        const newState = Object.assign(this.props.scanLoginData, {
            [name]: value,
        });
        this.props.dispatch(updateLoginForm(newState));

        if (e.target.checkValidity()) {
            e.target.classList.remove('has-error');
            e.target.nextElementSibling.classList.remove('has-error');
        } else {
            e.target.classList.add('has-error');
            e.target.nextElementSibling.classList.add('has-error');
        }
    }

    formSubmitHandler = (e) => {
        e.preventDefault()
        if (e.target.checkValidity()) {
            this.props.dispatch(login(this.props.scanLoginData))
        } else {
            e.target.parentElement.parentElement.classList.add('has-error');
            const invalidInputs = document.querySelectorAll('input:invalid');
            invalidInputs[0].focus();
            for (let i = 0; i < invalidInputs.length; i++) {
                invalidInputs[i].nextElementSibling.classList.add('has-error');
            }
        }

    }

    loginFunctionWithGoogle(e) {
        e.preventDefault();
        window.location.replace(`${BASE_API}/v1/google/login?redirect=${window.location.href}`)
    }

    loginFunctionWithGithub(e) {
        e.preventDefault();
        window.location.replace(`${BASE_API}/v1/github/login?redirect=${window.location.href}`)
    }

    render() {

        const { scanLoginData, postLoginLoading, postLoginError } = this.props;
        return (
            <>
                <Header backgroundColor={"yellowHeader"} />
                <section>
                    <div className="form-background">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-2 col-md-2 col-lg-2 image-leftside">
                                    <div className="form-inside-wrapper-imageSide">
                                        <div className="image-leftside-wrapper">
                                            <img src={Request_Demo_Page_Illustration_Object_Big_left} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-8 col-md-8 col-lg-8 login-form">
                                    <div className="form-wrapper">
                                        <div className="form-heading">
                                            <p>Login for your Account</p>
                                        </div>
                                        <form className="UserLoginForm"
                                            id="UserSignupForm"
                                            noValidate={true}
                                            onSubmit={this.formSubmitHandler.bind(this)}>
                                            <div className="form-field">
                                                <img src={noun_envelope} alt="" />
                                                <input required type="email" name="email" id="login-email" placeholder="Email"
                                                    value={scanLoginData.email}
                                                    onChange={this.handleDataChange.bind(
                                                        this
                                                    )}
                                                    pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                                />
                                                <span className="errors" id="email-error">
                                                    Please enter a valid email
                                                </span>

                                            </div>
                                            <div className="form-field">
                                                <div className="no-icon">
                                                    <img src={noun_password} alt="" />
                                                    <img src={noun_Eye} alt="" id="toggleeye" onClick={this.toggleShow.bind(this)} />
                                                    <input required
                                                        name="password"
                                                        id="login-password"
                                                        type={this.state.hidden ? "password" : "text"}
                                                        placeholder="Enter Password"
                                                        value={scanLoginData.password}
                                                        onChange={this.handleDataChange.bind(
                                                            this
                                                        )}
                                                    // pattern={"^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{1,}$"}
                                                    />

                                                    <span className="errors" id="password-error">
                                                        Please enter your password
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                {!postLoginLoading ?

                                                    (<button type="submit"
                                                        id="submit"
                                                        noValidate={true}
                                                    >Login</button>)
                                                    :
                                                    (<button>
                                                        <i className="fa fa-spin fa-spinner btn-icon"></i>
                                                        Please wait ..
                                                    </button>)
                                                }
                                            </div>
                                            {!postLoginLoading && postLoginError &&
                                                <div className="center-error form-error">
                                                    <p>{postLoginError}</p>
                                                </div>
                                            }

                                        </form>

                                        <div className="or-text">
                                            <p>OR</p>
                                        </div>
                                        <div className="loginbutton">
                                            <button onClick={this.loginFunctionWithGoogle.bind(this)}>
                                                <img src={google_g_2015} alt="" />
                                                Continue with Google</button>
                                        </div>
                                        <div className="loginbutton1">
                                            <button onClick={this.loginFunctionWithGithub.bind(this)}>
                                                <img src={Octicons_mark_github} alt="" />
                                                Continue With Github</button>
                                        </div>
                                        <div className="login-link">
                                            <NavLink to="/auth/forgotPassword">Forgot password ?</NavLink><span> | </span><NavLink to="/auth/signUp">Create Account</NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-2 col-md-2 col-lg-2 image-rightside">
                                    <div className="form-inside-wrapper-imageSide">
                                        <div className="image-rightside-wrapper">
                                            <img src={Request_Demo_Page_Illustration_Object_small_right} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

function mapStateToProps(state) {
    const { data, scanLoginData, isAuthenticated, postLoginLoading, postLoginError, postLoginMessage, loginEmail, loginName , loginToken} = state.loginReducer;
    return { data, scanLoginData, isAuthenticated, postLoginLoading, postLoginError, postLoginMessage, loginEmail, loginName , loginToken}
}

export default withRouter(connect(mapStateToProps)(LoginForm));
