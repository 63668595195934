import {
    LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, UPDATE_LOGIN_FORM_DATA, CLEAR_DATA
} from './constants';

const initialState = {

    scanLoginData: {
        password: "",
        email: "",
    },
    data: [],
    loginName: '',
    loginEmail: '',
    postLoginMessage: "",
    postLoginError: false,
    postLoginLoading: false,
    loginToken: '',
    isAuthenticated: localStorage.getItem('token') ? true : false,

}

export default function loginReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_REQUEST:
            return Object.assign({}, state, {
                postLoginMessage: "",
                postLoginError: false,
                postLoginLoading: true,
            });
        case LOGIN_SUCCESS:
            return Object.assign({}, state, {
                postLoginMessage: 'Login Successful',
                postLoginError: false,
                postLoginLoading: false,
                loginName: action.user.name,
                loginEmail: action.user.email,
                data: action.payload,
                loginToken: action.user.token,
                isAuthenticated: true,
               // name: action.data.name,
            });
        case LOGIN_FAILURE:
            return Object.assign({}, state, {
                postLoginMessage: 'Login failed',
                postLoginError: action.data.message,
                postLoginLoading: false,
                isAuthenticated: false,

            });

        case UPDATE_LOGIN_FORM_DATA:
            return Object.assign({}, state, {
                scanLoginData: action.data
            });
        case CLEAR_DATA:
            return Object.assign({}, state, {
                scanLoginData: {
                    password: "",
                    email: "",
                },
            })

        default:
            return state;
    }
}