export  const LOGIN_REQUEST = "LOGIN_REQUEST";
export  const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export  const LOGIN_FAILURE = "LOGIN_FAILURE";

export const UPDATE_SCAN="UPDATE_SCAN";

export const CLEAR_STATE="CLEAR_STATE";

export const DEFAULT_LOGIN_STATE="DEFAULT_LOGIN_STATE";

export const UPDATE_LOGIN_FORM_DATA="UPDATE_LOGIN_FORM_DATA";
export const CLEAR_DATA="CLEAR_DATA";
export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST"
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS"
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE"